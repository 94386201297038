import { action, computed, observable, toJS } from "mobx";
import PaginatedListStore from "../../../../stores/PaginatedListStore";
import api from "../../../../modules/API";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { Errors } from "../../../../modules/Errors";

type OrderFilterSelectType = keyof typeof api.OrderFilterSortOrder;

export default class OrderTableStore extends PaginatedListStore<api.Order> {
	private authStore: AuthStore;
	private uiStore: UIStore;

	@observable public _orderSelected: OrderFilterSelectType = "asc";

	@observable private _sellerName: string | null = null;
	@observable private _buyerName: string | null = null;

	@observable public _NumberOrders: number = 0;

	@observable public fetchFilterLoading: boolean = false;

	@computed
	public get NumberOrders() {
		return this._NumberOrders;
	}

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
		this.getOrdersLength();
	}

	get orderSelected() {
		return this._orderSelected;
	}

	set orderSelected(value: OrderFilterSelectType) {
		this._orderSelected = value ? value : "asc";
	}

	get buyerName(): string | null {
		return this._buyerName;
	}

	set buyerName(value: string | null) {
		this._buyerName = value || null;
	}

	set sellerName(value: string | null) {
		this._sellerName = value || null;
	}

	get sellerName(): string | null {
		return this.sellerName;
	}

	@action
	public handleFetchFilter = async () => this.fetchItemsWithFilter();

	@action
	public clearFilter = () => {
		this._buyerName = null;
		this._sellerName = null;
		this._orderSelected = "asc";
		this.fetchPage(this.page);
	};

	@action
	private async fetchItemsWithFilter(): Promise<void> {
		if (this.fetchFilterLoading) {
			this.uiStore.showSnackbar("Sua pesquisa anda está carregando", "warning");
			return;
		}

		if (!this._sellerName && !this._buyerName) {
			return this.fetchPage(0);
		}

		const currentSeller = this.items.find(
			(item) => item.seller.name === this._sellerName,
		);

		const currentBuyer = this.items.find(
			(item) => item.buyer.name === this._buyerName,
		);

		try {
			this.fetchFilterLoading = true;

			const response = await api.getOrders(this.page, {
				buyerId: currentBuyer ? currentBuyer.buyer.id : this._buyerName,
				sellerId: currentSeller ? currentSeller.seller.id : this._sellerName,
				filterSortOrder: api.OrderFilterSortOrder[this._orderSelected],
			});

			this.items = response;

			console.log(toJS(this.items));
		} catch (e) {
			this.uiStore.showSnackbar(Errors.handleError(e));
		} finally {
			this.fetchFilterLoading = false;
		}
	}

	@action
	protected async getDataItemsPerPage(page: number): Promise<api.Order[]> {
		return await api.getOrders(page, {
			buyerId: null,
			sellerId: null,
			filterSortOrder: api.OrderFilterSortOrder[this._orderSelected],
		});
	}

	@computed
	get orderFilterSelectOptions() {
		return [
			{ name: "Ascendente", value: "asc" },
			{ name: "Descendente", value: "desc" },
		];
	}

	protected getOrdersLength = async () => {
		return (this._NumberOrders = await api.getTotalOrdersAmount());
	};
}
