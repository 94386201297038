import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from "@material-ui/core";
import Select, { IFields } from "../../../../components/Select";
import { observer } from "mobx-react-lite";

import { OrderFilterSortOrder } from "@startapp/laira-admin-api";
import OrderTableStore from "./store";

interface IFilterModalProps {
	inputRefBuyer: React.RefObject<HTMLInputElement>;
	inputRefSell: React.RefObject<HTMLInputElement>;
	tableStore: OrderTableStore;

	clearFilterInputs: () => void;
}

export interface IExportProps {
	handleOpen: () => void;
	handleClose: () => void;
	isOpen: boolean;
}

const FilterModal: React.ForwardRefRenderFunction<
	IExportProps,
	IFilterModalProps
> = ({ inputRefBuyer, inputRefSell, tableStore, clearFilterInputs }, ref) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleClose = () => setIsOpen(false);

	const handleOpen = () => setIsOpen(true);

	useImperativeHandle(ref, () => ({ handleClose, handleOpen, isOpen }), [
		handleClose,
		handleOpen,
		isOpen,
	]);

	return (
		<Dialog
			maxWidth="md"
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">Filtrar pedidos</DialogTitle>
			<DialogContent style={{ height: "180px" }}>
				<Box display="flex" flexDirection="column">
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						flexWrap="warp"
					>
						<TextField
							label="Comprador"
							variant="standard"
							placeholder="Procurar por nome"
							onChange={(e) => (tableStore.buyerName = e.target.value)}
							inputRef={inputRefBuyer}
						/>
						<TextField
							inputRef={inputRefSell}
							label="Vendedor"
							variant="standard"
							placeholder="Procurar por nome"
							onChange={(e) => (tableStore.sellerName = e.target.value)}
						/>
					</Box>
					<Select
						style={{ marginTop: "20px" }}
						value={tableStore.orderSelected}
						onChange={(e) =>
							(tableStore.orderSelected =
								OrderFilterSortOrder[e.target.value as string])
						}
						title=""
						fields={tableStore.orderFilterSelectOptions}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancelar
				</Button>

				<Button
					disabled={tableStore.fetchFilterLoading}
					onClick={() => {
						tableStore.clearFilter();
						clearFilterInputs();
					}}
					variant="outlined"
					color="primary"
				>
					Limpar filtros
				</Button>
				<Button
					style={{ minWidth: "140px" }}
					onClick={() => {
						tableStore.handleFetchFilter();
						handleClose();
					}}
					disabled={tableStore.fetchFilterLoading}
					variant="outlined"
					color="secondary"
				>
					Filtrar
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default forwardRef(FilterModal);
