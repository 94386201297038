import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
	createStyles({
		card: {
			padding: theme.spacing(2),
			marginBottom: theme.spacing(1),
			marginRight: theme.spacing(1),
			backgroundColor:
				theme.palette.type === "dark"
					? theme.palette.grey[600]
					: theme.palette.grey[200],
			borderRadius: 15,

			maxWidth: "100%",
			width: "100%",
		},
		label: {
			marginBottom: "0.5em",
			textIndent: "1rem",
			fontWeight: "bold",
		},
		field: {
			textIndent: "1rem",
			fontSize: 16,
		},
		content: {
			width: "100%",
		},
		singleLine: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-start",
		},
		multiline: {
			display: "flex",
			flexDirection: "column",
		},
	}),
);
