// MARK: React
import React from "react";
import useStyles from "./styles";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../modules/Strings";

// MARK: Layout
import { BaseLayout } from "../../layouts/BaseLayout";

// MARK: Components
import TextField from "../../components/TextField";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import { PageContainer } from "../../components/PageContainer";
import {
	AppBar,
	Card,
	CardContent,
	CardActions,
	Typography,
	Link,
	Divider,
	Paper,
} from "@material-ui/core";

// MARK: Icons
import { HelpOutline as HelpOutlineIcon} from "@material-ui/icons";
// MARK: Hooks
import { useIsMobile } from "../../hooks/useIsMobile";

// MARK: Stores
import { useGlobalStore } from "../context";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import { useHistory } from "react-router";

export const Login = observer(() => {
	const isMobile = useIsMobile();
	const classes = useStyles();
	const { authStore } = useGlobalStore();
	const history = useHistory();
	const login = Strings.pageLogin;
	useAuthRoute(() => history.push("/dashboard"));

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Typography className={classes.pageTitle} variant="h6">
					{login.pagetitle}
				</Typography>
			</AppBar>
			{authStore.loading ? (
				<CircularProgress />
			) : (
				<Paper className={classes.pageContent} elevation={0}>
					<Card className={classes.card}>
						<CardContent>
							<Typography className={classes.loginTitle} variant="h5">
								{login.title}
							</Typography>
							<TextField
								inputProps={{ className: classes.input }}
								InputLabelProps={{ className: classes.label }}
								{...authStore.formController.field("email")}
								label={Strings.fields.email}
								disabled={authStore.loading}
								type="email"
								name="email"
							/>
							<TextField
								inputProps={{ className: classes.input }}
								InputLabelProps={{ className: classes.label }}
								{...authStore.formController.field("password")}
								label={Strings.fields.password}
								disabled={authStore.loading}
								type="password"
								name="password"
							/>
						</CardContent>
						<CardActions>
							<Button
								variant="outlined"
								className={classes.loginButton}
								onClick={() =>
									authStore.login(() => history.push("/dashboard"))
								}
							>
								{login.buttonLogin}
							</Button>
						</CardActions>
						<Divider variant="middle" />
					</Card>
					<Link className={classes.acessLink} href="#" onClick={() => history.push("./recovery")}>
						{login.recoveryPassword}
					</Link>
					{isMobile ? (
						<>
							<Typography className={classes.mobileFooter}>
								{login.messageFooter}
							</Typography>
							<HelpOutlineIcon className={classes.mobileHelpIcon} />
						</>
					) : (
						<>
							<Typography className={classes.desktopLeftFooter}>
								{login.messageFooter}
							</Typography>
							<Typography className={classes.desktopRightFooter}>
								{login.needHelp}
							</Typography>
							<HelpOutlineIcon className={classes.desktopHelpIcon} />
						</>
					)}
				</Paper>
			)}
			<BaseLayout />
		</>
	);
});
