// Mark: React
import React, { useMemo } from "react";
// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
// Mark: React-router
import { useHistory, useParams } from "react-router-dom";

// Mark: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// Mark: Modules
import Strings from "../../../../modules/Strings";

// Mark: Components
import { Box, Button, Typography } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import { PropTypes } from "mobx-react";
import * as api from "@startapp/laira-admin-api";
import { Formatting } from "../../../../modules/Formatting";

// Mark: interface
interface IParams {
	id: string;
}

// Mark: implementation
const DetailsView: React.FC = () => {
	const { id } = useParams<IParams>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	if (!store.model) {
		return null;
	}

	type BuyerAdders = api.Order["transport"]["buyerAddress"];

	const formatAdders = ({
		city,
		country,
		state,
		zipcode,
		streetNumber,
		street,
	}: BuyerAdders): string =>
		`${street}, Nº ${streetNumber}, ${city}, ${state}, ${country}, CEP: ${zipcode}  `;

	return (
		<CardForm
			title={Strings.orders.details.title}
			actionsFooter={
				store.model.status !== "canceled" ? (
					<Button startIcon={<Cancel />} onClick={store.showDeleteDialog}>
						{Strings.actions.cancel}
					</Button>
				) : (
					<Typography color="error">{Strings.orders.details.cancel}</Typography>
				)
			}
		>
			<Box display="flex">
				<DetailRow
					label={Strings.orders.details.seller}
					value={store.model.seller.name}
				/>
				<DetailRow
					label={Strings.orders.details.status}
					value={api.translateOrderStatus(store.model.status)}
				/>
			</Box>

			<Box display="flex">
				<DetailRow
					label={Strings.orders.details.orderNumber}
					value={store.model.numId}
				/>
				<DetailRow
					label={Strings.orders.details.paymentMethod}
					value={api.translatePaymentMethod(store.model.paymentMethod)}
				/>
			</Box>

			{store.model.orderProducts.map((product) => {
				return (
					<Box display="flex" key={product.product.id}>
						<DetailRow
							label={Strings.orders.details.product}
							key={product.product.id}
							value={product.product.title}
						/>
					</Box>
				);
			})}

			<Box display="flex">
				<DetailRow
					label={Strings.orders.details.destination}
					value={formatAdders(store.model.transport.buyerAddress)}
				/>
				<DetailRow
					label={Strings.orders.details.sellerAddress}
					value={formatAdders(store.model.transport.sellerAddress)}
				/>
			</Box>

			<Box display="flex">
				<DetailRow
					label={Strings.orders.details.observations}
					value={store.model.observations}
				/>
				<DetailRow
					label={Strings.orders.details.totalPayment}
					value={Formatting.currencyForBR(store.model.payment.total)}
				/>
			</Box>
		</CardForm>
	);
};

export default observer(DetailsView);
