// MARK: React
import { action } from "mobx";

// MARK: API
import API from "../../../../modules/API";

// MARK: Strings
import Strings from "../../../../modules/Strings";

// MARK: Store
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.Banner> {
	protected router: IRouteReplacer;
	public mobileImage: FileStore;
	public dekstopImage: FileStore;

	public formController = new FormStore({
		type: "" as API.BannerType,
		title: "",
		slug: "",
		url: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.mobileImage = new FileStore(this.uiStore, "image");
		this.dekstopImage = new FileStore(this.uiStore, "image");
	}

	protected getModel(id: string) {
		return API.getBanner(id);
	}

	protected afterModelFetch(model: API.Banner) {
		this.formController = new FormStore({
			title: model.title || "",
			slug: model.slug || "",
			type: model.type || "",
			url: model.url || "",
		});
		this.mobileImage = new FileStore(this.uiStore, "image", model.mobileImage);
		this.dekstopImage = new FileStore(this.uiStore, "image", model.image);
	}

	@action
	public editBanner = async () => {
		const { slug, title, type, url } = this.formController.getValues();
		const image = this.dekstopImage.getUncertainfiedImage();
		const mobileImage = this.mobileImage.getUncertainfiedImage();

		if (!image || !mobileImage) {
			return this.uiStore.showErrorSnackbar(
				Strings.banners.edit.error("imagem do banner"),
			);
		}

		try {
			if (this.loading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}
			const banners = await API.editBanner(this.id, {
				image,
				mobileImage,
				slug,
				title,
				type,
				url,
			});

			localStorage.setItem("banners", JSON.stringify({ ...banners }));
			this.router.replace("/dashboard/banners");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.banners.edit.success(banners.title),
				"success",
			);
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};
}
