// Mark: Styles
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			margin: theme.spacing(1),
		},

		container: {
			display: "block",
			flexWrap: "wrap",
			padding: "0 auto",
			maxWidth: "100%",
			[theme.breakpoints.down("xs")]: {
				flexDirection: "column",
			},
		},

		box: {
			display: "block",
			maxWidth: 600,
			flexGrow: 1,
		},
		fieldsBox: {
			"display": "flex",
			"flexWrap": "wrap",
			[theme.breakpoints.down("xs")]: {
				justifyContent: "center",
			},

			"& .MuiTextField-root": {
				margin: theme.spacing(0.8),
				maxWidth: 175,
				width: "100%",
				[theme.breakpoints.down("xs")]: {
					maxWidth: 300,
					margin: theme.spacing(1),
				},
			},
		},

		boxBigFields: {
			display: "flex",
			flexWrap: "wrap",
			maxWidth: 600,
			flexGrow: 1,
			[theme.breakpoints.down("xs")]: {
				justifyContent: "center",
			},
		},

		descriptionField: {
			margin: theme.spacing(0.8),
			width: "94%",
			[theme.breakpoints.down("xs")]: {
				maxWidth: 300,
				width: "100%",
			},
		},
		fileCaseField: {
			maxWidth: 600,
		},

		fieldTag: {
			maxWidth: 300,
			width: "100%",
		},

		fieldSelectCat: {
			margin: theme.spacing(0.5),
			maxWidth: 180,
			width: "100%",
			[theme.breakpoints.down("xs")]: {
				maxWidth: 300,
				margin: theme.spacing(1),
			},
		},
	}),
);

export default useStyles;
