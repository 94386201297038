export const Formatting = {
	currency(centsValue: number) {
		return (centsValue / 100).toLocaleString(
			window !== undefined ? window.navigator.language : "pt-BR",
			{
				currency: "BRL",
				style: "currency",
				maximumFractionDigits: 2,
				minimumFractionDigits: 2,
			},
		);
	},

	formatCurrencyBR(currentNumber: number) {
		return new Intl.NumberFormat("pt-br", {
			style: "currency",
			currency: "BRL",
		}).format(currentNumber);
	},

	currencyForBR(centsValue: number) {
		return (centsValue / 100).toLocaleString("pt-BR", {
			currency: "BRL",
			style: "currency",
			maximumFractionDigits: 2,
			minimumFractionDigits: 2,
		});
	},

	toCents (value: string): number {
		return parseInt(value.replaceAll(".", "").replaceAll(",", ""), 10);
	},
};
