import PaginatedListStore from "../../../../stores/PaginatedListStore";
import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import { action, computed, observable } from "mobx";
import { IRouterPusher } from "../../../../interfaces/IRouter";
import { IFields } from "../../../../components/Select";

export default class UserTableStore extends PaginatedListStore<api.User> {
	private uiStore: UIStore;

	@observable public filterParam: string;

	private options: Record<string, any>;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.options = {
			all: null,
			blocked: true,
			notBlocked: false,
		};
		this.filterParam = "all";
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	@action.bound
	public changeFilterParams(filterParams: string) {
		this.filterParam = filterParams;
		this.fetchPage(this.page);
	}

	@action.bound
	protected async getDataItemsPerPage(page: number): Promise<api.User[]> {
		const values = await api.getUsers(page, {
			isBlocked: this.options[this.filterParam],
		});

		if (!values.length) {
			this.items = [];
		}

		const nextPageValues = await api.getUsers(page + 1, {
			isBlocked: this.options[this.filterParam],
		});

		this._isHaveNextPage = !!nextPageValues.length;

		return values;
	}

	@action
	public deleteUser = async (id: string) => {
		try {
			await api.deleteUserPermanently(id);
			window.location.reload();
			this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};

	@action
	public setBlockUserState = async (userId: string, active: boolean) => {
		if (this.loading) {
			return;
		}

		this.setLoading(true);

		try {
			const onConfirm = async () => {
				await api.blockUser(userId, active);
				window.location.reload();
				this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
			};
			this.uiStore.showDialog(
				{
					title: Strings.users.blockDialogUser.title(active),
					message: Strings.users.blockDialogUser.message(active),
				},
				onConfirm,
			);
		} catch (e) {
			this.uiStore.showSnackbar(e);
		} finally {
			this.setLoading(false);
		}
	};

	@action
	public blockUser = async (userId: string) => {
		await this.setBlockUserState(userId, true);
	};

	@action
	public unLockUser = async (userId: string) => {
		await this.setBlockUserState(userId, false);
	};

	@computed
	public get getFilterFields(): IFields[] {
		return [
			{
				name: "todos",
				value: "all",
			},
			{
				name: "Bloqueados",
				value: "blocked",
			},
			{
				name: "não bloqueados",
				value: "notBlocked",
			},
		];
	}
}
