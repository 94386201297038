// MARK: React
import React from "react";

// MARK: router
import { useHistory, useParams } from "react-router";

// MARK: Styles
import { useStyles } from "./styles";

// MARK: MOBX
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../../modules/Strings";

// MARK: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Libs
import { Box, Button, CircularProgress, TextField } from "@material-ui/core";

// MARK: Components
import FileCase from "../../../../components/FileCase";
import CardForm from "../../../../components/CardForm";
import Select from "../../../../components/Select";

const CreateView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();

	return (
		<CardForm
			title={Strings.banners.edit.title}
			actionsFooter={
				(
					<Button
						disabled={store.loading}
						type="submit"
						variant="contained"
						color="default"
						onClick={store.editBanner}
					>
						{store.loading ? (
							<CircularProgress />
						) : (
								Strings.banners.edit.editButton
							)}
					</Button>
				)
			}
		>
			<Box>
				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("title")}
					label={Strings.fields.title}
				/>
				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("url")}
					label={Strings.fields.url}
				/>
				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("slug")}
					label={Strings.fields.slug}
				/>
			</Box>

			<Select
				className={classes.fields}
				{...store.formController.field("type")}
				title="Tipo de banner"
				fields={[
					{ name: "standard", value: "standard" },
					{ name: "Link", value: "link" },
				]}
			/>

			<Box className={classes.fileCases}>
				<FileCase title="Imagem Desktop" {...store.dekstopImage.toCase(false, true)} />
				<FileCase title="Image Mobile" {...store.mobileImage.toCase(false, true)} />
			</Box>
		</CardForm>
	);
};

export default observer(CreateView);
