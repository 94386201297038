import { observable, computed, action } from "mobx";
import API from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import ModelStore from "../../../../stores/ModelStore";
import FormStore from "../../../../stores/FormStore";
import UIStore from "../../../../stores/UIStore";
import { Errors } from "../../../../modules/Errors";

import SelectChipStore from "../../../../components/SelectChips/selectChipStore";
interface IPush {
	goBack: () => void;
}
export default class Store {
	@observable public selectTags: API.Tag[];

	@observable public model: API.Section | null = null;
	@observable public loading: boolean = false;
	@observable public error: string | null = null;

	protected uiStore: UIStore;

	@observable public selectChipTag: SelectChipStore<API.Tag>;

	@observable public selectCategory: API.Category;

	@observable protected id: string;
	@observable protected history: IPush;
	constructor(id: string, uiStore: UIStore, history: IPush) {
		this.id = id;
		this.uiStore = uiStore;
		this.history = history;
		this.fetchModel();
	}

	@action
	private fetchModel = async () => {
		try {
			this.loading = true;
			this.selectTags = await API.getAllTags();
			this.selectChipTag = new SelectChipStore<API.Tag>(
				this.selectTags,
				this.uiStore,
			);
			this.model = await API.getSection(this.id);
			this.afterModelFetch(this.model);
		} catch (e) {
			this.error = Errors.handleError(e);
		} finally {
			this.loading = false;
		}
	};

	public setCategory = (value: string) => {
		this.selectCategory = API.Category[value];
	};

	public formController = new FormStore({
		name: "",
		text: "",
		idStore: "",
	});
	protected afterModelFetch(model: API.Section) {
		this.formController = new FormStore({
			name: model.name || "",
			text: model.filter.text || "",
			idStore: model.filter.storeId || "",
		});
		if (model.filter.category) {
			this.selectCategory = model.filter.category;
		}
		if (model.filter.tags) {
			this.selectChipTag = new SelectChipStore<API.Tag>(
				this.selectTags,
				this.uiStore,
				model.filter.tags,
			);
		}
	}

	@action
	public getTags = async () => {
		return await API.getTags(0);
	};

	@action
	public editSection = async () => {
		const fields = this.formController.getValues();
		const _filter: API.ProductFilter = {
			category: this.selectCategory,
			text: fields.text,
			tags: this.selectChipTag.chosenOptions,
			storeId: fields.idStore.length > 0 ? fields.idStore : null,
			order: null,
		};
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}
			this.loading = true;
			const section = await API.editSection(
				{
					name: fields.name,
					filter: _filter,
				},
				this.id,
			);
			this.history.goBack();
			this.uiStore.showSnackbar(
				Strings.sections.editor.success(section.name),
				"success",
			);
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get stringsOptions() {
		return {
			categories: [
				API.Category.clothes,
				API.Category.accessories,
				API.Category.sport,
				API.Category.toys,
				API.Category.party,
				API.Category.educational,
				API.Category.health,
				API.Category.customized,
			],
		};
	}
}
