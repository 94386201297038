// MARK: MOBX
import { action, computed, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

// MARK: Lib
import * as Yup from "yup";

// MARK: Stores
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;

	@observable public loading = false;
	@observable public error: string | null = null;

	public formController = new FormStore({
		name: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
	}

	@action
	public createTag = async () => {
		const data = this.formController.getValues();
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			this.loading = true;
			await API.createTag({
				name: data.name,
			});

			this.history.goBack();
			this.uiStore.showErrorSnackbar(Strings.register.successTag);
		} catch (e) {
			this.uiStore.showSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get validations(): Yup.ObjectSchema<object | undefined, object> {
		return Yup.object().shape({
			name: Yup.string().required("O nome da tag é obrigatória"),
		});
	}
}
