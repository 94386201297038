import PaginatedListStore from "../../../../stores/PaginatedListStore";
import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import { action } from "mobx";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class TableBannerStore extends PaginatedListStore<api.Banner> {
	private uiStore: UIStore;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.uiStore = uiStore;
	}

	@action
	protected getDataItemsPerPage(page: number): Promise<api.Banner[]> {
		return api.getBanners(page);
	}

	@action
	public deleteBanner = async (id: string) => {
		try {
			await api.deleteBanner(id);
			window.location.reload();
			this.uiStore.showSuccessSnackbar();
		} catch (error) {
			this.uiStore.showErrorSnackbar(Strings.error.default);
		}
	};
}
