import { action, runInAction } from "mobx";

// Modules
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";

// Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

// Stores
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

// Mark: implementation
export default class Store extends ModelStore<API.Order> {
	private router: IRouterPusher;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
	}

	@action
	protected async getModel(id: string) {
		return await API.getOrder(id);
	}

	@action
	private deleteBanner = async (id: string) => {
		try {
			if (this.loading) {
				throw Error(Strings.error.stillLoading);
			}

			await API.cancelOrderWithRefund(id);
			this.uiStore.showSuccessSnackbar();
			this.router.push("/dashboard/orders");
		} catch (e) {
			this.error = Errors.handleError(e);
			this.uiStore.showSnackbar(this.error);
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	@action
	public showDeleteDialog = () => {
		this.uiStore.showDialog(Strings.dialogs.cancelOrder, () =>
			this.deleteBanner(this.id),
		);
	};
}
