// MARK: API
import * as api from "@startapp/laira-admin-api";

// MARK: React
import React, { useCallback, useMemo, useRef, useState } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";
import { action } from "mobx";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Libs
import moment from "moment";

// MARK: Store
import OrderTableStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useHistory } from "react-router-dom";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import Select from "../../../../components/Select";
import { OrderFilterSortOrder } from "@startapp/laira-admin-api";
import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	makeStyles,
	TableCell,
	TableRow,
	TextField,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { Store, Visibility as ViewIcon } from "@material-ui/icons";
import FilterModel, { IExportProps } from "./FilterModel";

const useStyles = makeStyles((theme) => ({
	isHasMargin: {
		marginLeft: theme.spacing(5),
	},
	marginBottom: {
		marginLeft: theme.spacing(5),
		marginBottom: "55px",
	},
}));

function FallbackTable(): JSX.Element {
	return (
		<Box
			marginTop="30px"
			flex="1"
			display="flex"
			alignItems="center"
			justifyContent="center"
		>
			<Typography variant="h5">
				{Strings.components.table.fallbackData}
			</Typography>
		</Box>
	);
}

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const StringOrder = Strings.orders;
	const inputRefBuyer = useRef<HTMLInputElement>(null);
	const inputRefSell = useRef<HTMLInputElement>(null);
	const filterModelActions = useRef<IExportProps>(null);
	const isMobile = useMediaQuery("(max-width:850px)");
	const tableStore = useLocalStore(() => new OrderTableStore(uiStore, history));

	const classes = useStyles();

	const clearFilterInputs = () => {
		if (inputRefBuyer.current && inputRefSell.current) {
			inputRefBuyer.current.value = "";
			inputRefSell.current.value = "";
		}
	};

	const pushToDetails = useCallback(
		(id: string) => history.push(`orders/details/${id}`),
		[history],
	);

	console.log(!!tableStore.items.length);

	const openModal = () => filterModelActions.current?.handleOpen();

	return (
		<PageTableContainer>
			{tableStore._NumberOrders && (
				<Box mb={1}>
					<p>
						{Strings.orders.totalOrders}<span style={{fontWeight: "bold"}}>{tableStore._NumberOrders}</span>
					</p>
				</Box>
			)}
			<FilterModel
				ref={filterModelActions}
				clearFilterInputs={clearFilterInputs}
				tableStore={tableStore}
				inputRefBuyer={inputRefBuyer}
				inputRefSell={inputRefSell}
			/>
			<SimpleTable
				fallback={<FallbackTable />}
				loading={tableStore.loading}
				title={StringOrder.title}
				pagination={tableStore.pagination}
				rows={tableStore.items}
				headers={StringOrder.header}
				isHaveNexPage={tableStore.isHaveNextPage}
				headerToolbar={
					!isMobile ? (
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							flexWrap="warp"
						>
							<TextField
								label="Comprador"
								className={classes.isHasMargin}
								variant="standard"
								placeholder="Procurar por nome"
								onChange={(e) => (tableStore.buyerName = e.target.value)}
								inputRef={inputRefBuyer}
							/>
							<TextField
								inputRef={inputRefSell}
								label="Vendedor"
								className={classes.isHasMargin}
								variant="standard"
								placeholder="Procurar por nome"
								onChange={(e) => (tableStore.sellerName = e.target.value)}
							/>
							<Select
								value={tableStore.orderSelected}
								className={classes.marginBottom}
								onChange={(e) =>
									(tableStore.orderSelected =
										OrderFilterSortOrder[e.target.value as string])
								}
								title="Ordem"
								fields={tableStore.orderFilterSelectOptions}
							/>
							<Button
								style={{ minWidth: "140px" }}
								onClick={tableStore.handleFetchFilter}
								className={classes.isHasMargin}
								disabled={tableStore.fetchFilterLoading}
								variant="outlined"
								color="secondary"
							>
								{tableStore.fetchFilterLoading ? (
									<CircularProgress size={22} color="secondary" />
								) : (
									"Pesquisar"
								)}
							</Button>
							<Button
								disabled={tableStore.fetchFilterLoading}
								onClick={() => {
									tableStore.clearFilter();
									clearFilterInputs();
								}}
								className={classes.isHasMargin}
								variant="outlined"
								color="primary"
							>
								Limpar filtros
							</Button>
						</Box>
					) : (
						<Button
							style={{ minWidth: "140px" }}
							onClick={openModal}
							variant="outlined"
							color="secondary"
						>
							Pesquisar
						</Button>
					)
				}
				renderItem={(item) => (
					<TableRow key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.numId}</TableCell>
						<TableCell align={"center"}>{item.buyer.name}</TableCell>
						<TableCell align={"center"}>{item.seller.name}</TableCell>
						<TableCell align={"center"}>{item.paymentMethod}</TableCell>
						<TableCell align={"center"}>
							{api.translateOrderStatus(item.status)}
						</TableCell>
						<TableCell align={"center"}>
							{moment(item.createdAt).format(Strings.moment.date)}
						</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<ViewIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
