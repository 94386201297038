// MARK: MOBX
import { action, computed, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Formatting } from "../../../../modules/Formatting";

// Mark: Stores
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";
import { Errors } from "../../../../modules/Errors";

interface IPush {
	goBack: () => void;
}

export default class Store {
	@observable public imageService: FileStore;

	@observable public selectCategory: API.Category;

	@observable public descriptionError: boolean = false;

	@observable public loading: boolean = false;

	@observable public selectChipTag: SelectChipStore<API.Tag>;

	@observable public selectTags: API.Tag[];

	@observable protected id: string;

	@observable public model: API.Product | null = null;

	@observable public error: string | null = null;

	public uiStore: UIStore;
	private history: IPush;

	constructor(id: string, uiStore: UIStore, history: IPush) {
		this.id = id;
		this.uiStore = uiStore;
		this.fetchModel();
		this.history = history;
		this.imageService = new FileStore(uiStore, "image");
	}

	public setCategory = (value: string) => {
		this.selectCategory = API.Category[value];
	};

	public formController = new FormStore({
		title: "",
		description: "",
		slug: "",
		productionTime: "",
		price: "",
		width: "",
		height: "",
		lenght: "",
		weight: "",
		diameter: "",
		minAmount: "",
	});

	@action
	public getTags = async () => {
		return await API.getTags(0);
	};

	@action
	private fetchModel = async () => {
		try {
			this.loading = true;
			this.selectTags = await API.getAllTags();
			this.selectChipTag = new SelectChipStore<API.Tag>(
				this.selectTags,
				this.uiStore,
			);
			this.model = await API.getProduct(this.id);
			this.afterModelFetch(this.model);
		} catch (e) {
			this.error = Errors.handleError(e);
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	protected afterModelFetch(model: API.Product) {
		this.formController = new FormStore({
			title: model.title || "",
			description: model.description || "",
			slug: model.slug || "",
			productionTime: model.productionTime || "",
			price: model.price.toString() || "",
			width: model.width.toString() || "",
			height: model.height.toString() || "",
			lenght: model.length.toString() || "",
			weight: model.weight.toString() || "",
			diameter: model.diameter.toString() || "",
			minAmount: model.minAmount.toString() || "",
		});
		this.imageService = new FileStore(this.uiStore, "image", model.images);
		this.selectCategory = model.category;
		this.selectChipTag = new SelectChipStore<API.Tag>(
			this.selectTags,
			this.uiStore,
			model.tags,
		);
	}

	@action
	public editProduct = async () => {
		const fields = this.formController.getValues();
		const imageServiceValue = this.imageService.getUncertainfiedMultipleFiles();
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.mustWaitWhileStillLoading,
				};
			}

			if (fields.description.length < 30) {
				this.descriptionError = true;
			} else {
				this.descriptionError = false;
			}

			await API.editProduct(
				{
					title: fields.title,
					slug: fields.slug,
					description: fields.description,
					productionTime: fields.productionTime,
					length: parseInt(fields.lenght),
					weight: parseInt(fields.weight),
					width: parseInt(fields.width),
					height: parseInt(fields.height),
					price: Formatting.toCents(fields.price),
					category: this.selectCategory,
					tags: this.selectChipTag.chosenOptions,
					images: imageServiceValue,
					diameter: parseInt(fields.diameter),
					minAmount: parseInt(fields.minAmount),
					freeShipping: false,
				},
				this.id,
			);

			this.loading = true;

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.successProduct, "success");
		} catch (err) {
			this.uiStore.showErrorSnackbar(err);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get stringsOptions() {
		return {
			categories: [
				API.Category.clothes,
				API.Category.accessories,
				API.Category.sport,
				API.Category.toys,
				API.Category.party,
				API.Category.educational,
				API.Category.health,
				API.Category.customized,
			],
		};
	}
}
