// MARK: React
import React from "react";

// MARK: router
import { useHistory } from "react-router";

// MARK: styles
import useStyles from "./styles";

// MARK: MOBX
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../../modules/Strings";

// MARK: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Libs
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";

// MARK: Components
import FileCase from "../../../../components/FileCase";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<CardForm
			title={Strings.users.create.title}
			actionsFooter={
				(
					<Button
						disabled={store.loading}
						type="submit"
						variant="contained"
						color="default"
						onClick={store.createUser}
					>
						{store.loading ? (
							<CircularProgress />
						) : (
								Strings.components.table.create
							)}
					</Button>
				)
			}
		>
			<CardContent className={classes.fields}>
				<TextField
					variant="standard"
					type="text"
					{...store.formController.field("name")}
					label={Strings.fields.name}
				/>
				<TextField
					variant="standard"
					type="email"
					{...store.formController.field("email")}
					label={Strings.fields.email}
				/>
				<TextField
					variant="standard"
					type="text"
					{...store.formController.field("nick")}
					label={Strings.fields.nick}
				/>
				<TextField
					variant="standard"
					type="number"
					{...store.formController.field("document")}
					label={Strings.fields.documentType}
				/>
				<TextField
					variant="standard"
					type="password"
					{...store.formController.field("password")}
					label={Strings.fields.password}
				/>
				<TextField
					variant="standard"
					type="text"
					{...store.formController.field("phone")}
					label={Strings.fields.phone}
				/>
				<FileCase
					title={Strings.fields.image}
					{...store.avatarController.toCase()}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
