// Mark: React
import React from "react";
// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
// Mark: React-router
import { useHistory, useParams } from "react-router-dom";

// Mark: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// Mark: Modules
import Strings from "../../../../modules/Strings";

// Mark: Components
import { Box, Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";
import FileCase from "../../../../components/FileCase";

// Mark: interface
interface IParams {
	id: string;
}

// Mark: implementation
const DetailsView: React.FC = () => {
	const { id } = useParams<IParams>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	if (!store.model) {
		return null;
	}

	return (
		<CardForm
			title={Strings.banners.details.title}
			loading={store.loading}
			actionsFooter={
				<Button startIcon={<Delete />} onClick={store.showDeleteDialog}>
					{Strings.actions.delete}
				</Button>
			}
		>
			<Box display="flex">
				<DetailRow label={Strings.fields.title} value={store.model.title} />
				<DetailRow label={Strings.fields.type} value={store.model.type} />
			</Box>

			<DetailRow label={Strings.fields.slug} value={store.model.slug} />
			<Box style={{ maxWidth: 600, margin: 2 }}>
				<FileCase
					{...store.imageController.toCase()}
					disableActions={true}
					title="Imagem"
				/>
				<FileCase
					{...store.imageMobileController.toCase()}
					disableActions={true}
					title="Imagem Mobile"
				/>
			</Box>
		</CardForm>
	);
};

export default observer(DetailsView);
