// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Router
import { Route, Switch } from "react-router-dom";

// MARK: Components
import { MainLayout } from "../../layouts/MainLayout";

// MARK: Pages
import Home from "./Home";
import AdminUsers from "./AdminUsers";
import Users from "./Users";
import UserConfig from "./UserConfig/DetailsView";
import Tags from "./Tags";
import Banners from "./Banners";
import Products from "./Products";
import Sections from "./Sections";
import Orders from "./Orders";
import Faq from "./Faq";

// MARK: Hooks
import { useAuthRoute } from "../../hooks/useAuthRoute";

export const Dashboard = () => {
	useAuthRoute();
	return (
		<MainLayout>
			<Switch>
				<Route exact={true} path="/dashboard" component={Home} />
				<Route path="/dashboard/adminUsers" component={AdminUsers} />
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/tags" component={Tags} />
				<Route path="/dashboard/banners" component={Banners} />
				<Route path="/dashboard/products" component={Products} />
				<Route path="/dashboard/user_config" component={UserConfig} />
				<Route path="/dashboard/sections" component={Sections} />
				<Route path="/dashboard/orders" component={Orders} />
				<Route path="/dashboard/faq" component={Faq} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
