// MARK: React
import React from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Libs
import { Box, Button, useMediaQuery } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

// MARK: Store
import UserDetailStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useParams, useHistory } from "react-router-dom";

// Mark: Components
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";

interface IParams {
	id: string;
}

const DetailsView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const detailStore = useLocalStore(
		() => new UserDetailStore(id, uiStore, history),
	);

	return (
		<CardForm
			title={Strings.users.details.title}
			loading={detailStore.loading}
			actionsFooter={
				<Button
					startIcon={<Delete />}
					onClick={() => detailStore.showDeleteDialog(id)}
				>
					{Strings.actions.delete}
				</Button>
			}
		>
			<Box style={{ display: "flex" }}>
				<DetailRow
					label={Strings.fields.name}
					value={detailStore.model?.name}
				/>

				<DetailRow
					label={Strings.fields.nick}
					value={detailStore.model?.nick}
				/>
			</Box>
			<Box style={{ display: "flex" }}>
				<DetailRow
					label={Strings.fields.documentType}
					value={detailStore.model?.document}
				/>
				<DetailRow
					label={Strings.fields.email}
					value={detailStore.model?.email}
				/>
			</Box>
			<Box style={{ display: "flex" }}>
				<DetailRow
					label={Strings.fields.nick}
					value={detailStore.model?.nick}
				/>
				<DetailRow
					label={Strings.fields.phone}
					value={detailStore.model?.phone}
				/>
			</Box>
			<Box style={{ display: "flex" }}>
				<DetailRow
					label={Strings.fields.document}
					value={detailStore.model?.document}
				/>
			</Box>
		</CardForm>
	);
};

export default observer(DetailsView);
