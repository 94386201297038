// MARK: React
import { action } from "mobx";

// MARK: API
import API from "../../../../modules/API";

// MARK: Strings
import Strings from "../../../../modules/Strings";

// MARK: Store
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.Faq> {
	protected router: IRouteReplacer;

	public formController = new FormStore({
		title: "",
		description: "",
	});

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
	}

	protected getModel(id: string) {
		return API.getFaq(id);
	}

	protected afterModelFetch(model: API.Faq) {
		this.formController = new FormStore({
			title: model.title || "",
			description: model.description || "",
		});
	}

	@action
	public editFaq = async () => {
		const { title, description } = this.formController.getValues();
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}
			const faq = await API.editFaq(this.id, {
				title,
				description,
			});

			localStorage.setItem("faq", JSON.stringify({ ...faq }));
			this.router.replace("/dashboard/banners");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.faq.edit.success,
				"success",
			);
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};
}
