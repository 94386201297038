// MARK: React
import React, { useCallback } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Libs
import { Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	Add as AddIcon,
	Visibility as ViewIcon,
} from "@material-ui/icons";

// MARK: Store
import TableBannerStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useHistory } from "react-router-dom";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new TableBannerStore(uiStore, history),
	);

	const pushToCreate = useCallback(() => history.push(`banners/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`banners/edit/${id}`),
		[history],
	);

	const pushToDetails = useCallback(
		(id: string) => history.push(`banners/details/${id}`),
		[history],
	);

	const handleDeleteTag = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.deleteItem.message,
					title: Strings.dialogs.deleteItem.title,
				},
				() => tableStore.deleteBanner(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore.loading}
				title={Strings.banners.table.title}
				pagination={tableStore.pagination}
				rows={tableStore.items}
				headers={[
					Strings.fields.id,
					Strings.fields.title,
					Strings.fields.type,
					Strings.fields.slug,
					Strings.components.table.detailsAndEditAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.title}</TableCell>
						<TableCell align={"center"}>{item.type}</TableCell>
						<TableCell align={"center"}>{item.slug}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={() => pushToDetails(item.id)}>
								<ViewIcon />
							</IconButton>
							<IconButton onClick={() => handleDeleteTag(item.id)}>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					(
						<Button startIcon={<AddIcon />} onClick={pushToCreate}>
							{Strings.actions.create}
						</Button>
					)
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
