import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface ProviderToken {
    providor: Provider;
    idToken: string;
    accessToken: string | null;
}

export interface ProviderUserInfo {
    providor: Provider;
    rawId: string;
    displayName: string;
    email: string | null;
    photoUrl: string | null;
}

export interface Notification {
    id: string;
    type: NotificationType;
    contentId: string[];
    image: Image | null;
    title: string | null;
    body: string;
    icon: string;
    tag: string | null;
    createdAt: Date;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface File {
    name: string;
    url: string;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: string;
    country: string;
    countryCode: string;
    latLng: LatLng;
}

export interface City {
    id: string;
    name: string;
    state: string;
    uf: string;
}

export interface ImageCrop {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface UncertainFile {
    fileData: UploadFile | null;
    file: File | null;
}

export interface UploadFile {
    bytes: Buffer;
    name: string;
}

export interface NewAdminUser {
    password: string;
    image: UncertainImage | null;
    name: string;
    email: string;
}

export interface EditAdminUser {
    image: UncertainImage | null;
    name: string;
    email: string;
}

export interface AdminUser {
    id: string;
    image: Image | null;
    name: string;
    email: string;
}

export interface AdminUserDetails {
    name: string;
    email: string;
}

export interface BankAccount {
    documentNumber: string;
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string;
    type: BankAccountType;
    legalName: string;
}

export interface EditBankAccount {
    bankName: string;
    bankCode: string;
    agency: string;
    agencyDv: string | null;
    account: string;
    accountDv: string;
    type: BankAccountType;
    legalName: string;
}

export interface Banner {
    id: string;
    image: Image;
    mobileImage: Image;
    type: BannerType;
    title: string;
    slug: string;
    url: string | null;
}

export interface BannerDetails {
    type: BannerType;
    title: string;
    slug: string;
    url: string | null;
}

export interface NewBanner {
    image: UncertainImage;
    mobileImage: UncertainImage;
    type: BannerType;
    title: string;
    slug: string;
    url: string | null;
}

export interface EditBanner {
    image: UncertainImage;
    mobileImage: UncertainImage;
    type: BannerType;
    title: string;
    slug: string;
    url: string | null;
}

export interface Faq {
    id: string;
    title: string;
    description: string;
}

export interface NewFaq {
    title: string;
    description: string;
}

export interface Tag {
    id: string;
    name: string;
}

export interface TagDetails {
    name: string;
}

export interface NewTag {
    name: string;
}

export interface EditTag {
    name: string;
}

export interface Product {
    id: string;
    images: Image[];
    createdAt: Date;
    storeId: string;
    liked: boolean;
    title: string;
    minAmount: number;
    diameter: number;
    description: string;
    price: number;
    productionTime: string;
    tags: Tag[];
    category: Category;
    slug: string;
    height: number;
    length: number;
    width: number;
    weight: number;
    freeShipping: boolean;
}

export interface ProductDetails {
    title: string;
    minAmount: number;
    diameter: number;
    description: string;
    price: number;
    productionTime: string;
    tags: Tag[];
    category: Category;
    slug: string;
    height: number;
    length: number;
    width: number;
    weight: number;
    freeShipping: boolean;
}

export interface ProductFilter {
    storeId: string | null;
    category: Category | null;
    tags: Tag[] | null;
    text: string | null;
    order: ProductOrder | null;
}

export interface NewProduct {
    images: UncertainImage[];
    title: string;
    minAmount: number;
    diameter: number;
    description: string;
    price: number;
    productionTime: string;
    tags: Tag[];
    category: Category;
    slug: string;
    height: number;
    length: number;
    width: number;
    weight: number;
    freeShipping: boolean;
}

export interface EditProduct {
    images: UncertainImage[];
    title: string;
    minAmount: number;
    diameter: number;
    description: string;
    price: number;
    productionTime: string;
    tags: Tag[];
    category: Category;
    slug: string;
    height: number;
    length: number;
    width: number;
    weight: number;
    freeShipping: boolean;
}

export interface NewCard {
    cardNumber: string;
    cvv: string;
    expDate: string;
    cardHolderName: string;
}

export interface Card {
    id: string;
    lastDigits: string;
    exp: string;
    brand: string;
    ownerId: string;
}

export interface ProductCart {
    product: Product;
    amount: number;
}

export interface OrderStatusLit {
    status: OrderStatus;
    changedAt: Date;
}

export interface Store {
    bankAccount: BankAccount | null;
    description: string;
    vendorPolicy: string;
}

export interface StoreDetails {
    description: string;
    vendorPolicy: string;
}

export interface NewStore {
    image: UncertainImage | null;
    password: string;
    name: string;
    email: string;
    nick: string;
    document: string;
    phone: string;
    description: string;
    vendorPolicy: string;
}

export interface NewUser {
    image: UncertainImage | null;
    password: string;
    name: string;
    email: string;
    nick: string;
    document: string;
    phone: string;
}

export interface UserDetails {
    name: string;
    email: string;
    nick: string;
    document: string;
    phone: string;
}

export interface User {
    id: string;
    image: Image | null;
    store: Store | null;
    createdAt: Date;
    blockedAt: Date | null;
    address: Address | null;
    name: string;
    email: string;
    nick: string;
    document: string;
    phone: string;
}

export interface EditUser {
    image: UncertainImage | null;
    name: string;
    email: string;
    nick: string;
    document: string;
    phone: string;
}

export interface UsersFilter {
    isBlocked: boolean | null;
}

export interface Order {
    id: string;
    numId: string;
    observations: string;
    buyer: User;
    seller: User;
    createdAt: Date;
    orderProducts: OrderOrderProducts[];
    paymentMethod: PaymentMethod;
    status: OrderStatus;
    statusList: OrderStatusLit[];
    transport: OrderTransport;
    payment: OrderPayment;
}

export interface OrderOrderProducts {
    product: Product;
    amount: number;
}

export interface OrderTransport {
    trackingCode: string | null;
    sellerAddress: Address;
    buyerAddress: Address;
    total: number;
}

export interface OrderPayment {
    card: Card | null;
    total: number;
}

export interface OrderFilter {
    sellerId: string | null;
    buyerId: string | null;
    filterSortOrder: OrderFilterSortOrder | null;
}

export interface Section {
    id: string;
    name: string;
    filter: ProductFilter;
}

export interface SectionDetails {
    name: string;
    filter: ProductFilter;
}

export interface NewSection {
    name: string;
    filter: ProductFilter;
}

export interface EditSection {
    name: string;
    filter: ProductFilter;
}

export enum Provider {
    google = "google",
    facebook = "facebook",
}

export function translateProvider(enumProvider: Provider): string {
    switch (enumProvider) {
        case Provider.google: {
            return strings ? strings["enum"]["Provider"]["google"] || Provider.google : Provider.google;
        }
        case Provider.facebook: {
            return strings ? strings["enum"]["Provider"]["facebook"] || Provider.facebook : Provider.facebook;
        }
    }
    return "";
}

export function allValuesProvider(): Provider[] {
    return [
        Provider.google,
        Provider.facebook,
    ];
}

export function allTranslatedValuesProvider(): string[] {
    return [
        translateProvider(Provider.google),
        translateProvider(Provider.facebook),
    ];
}

export function allDisplayableValuesProvider(): string[] {
    return allTranslatedValuesProvider().sort();
}

export function valueFromTranslationProvider(translation: string): Provider {
    const index = allTranslatedValuesProvider().indexOf(translation);
    return allValuesProvider()[index] || Provider.google;
}

export enum NotificationType {
    proposal = "proposal",
    job = "job",
    rating = "rating",
    user = "user",
}

export function translateNotificationType(enumNotificationType: NotificationType): string {
    switch (enumNotificationType) {
        case NotificationType.proposal: {
            return strings ? strings["enum"]["NotificationType"]["proposal"] || NotificationType.proposal : NotificationType.proposal;
        }
        case NotificationType.job: {
            return strings ? strings["enum"]["NotificationType"]["job"] || NotificationType.job : NotificationType.job;
        }
        case NotificationType.rating: {
            return strings ? strings["enum"]["NotificationType"]["rating"] || NotificationType.rating : NotificationType.rating;
        }
        case NotificationType.user: {
            return strings ? strings["enum"]["NotificationType"]["user"] || NotificationType.user : NotificationType.user;
        }
    }
    return "";
}

export function allValuesNotificationType(): NotificationType[] {
    return [
        NotificationType.proposal,
        NotificationType.job,
        NotificationType.rating,
        NotificationType.user,
    ];
}

export function allTranslatedValuesNotificationType(): string[] {
    return [
        translateNotificationType(NotificationType.proposal),
        translateNotificationType(NotificationType.job),
        translateNotificationType(NotificationType.rating),
        translateNotificationType(NotificationType.user),
    ];
}

export function allDisplayableValuesNotificationType(): string[] {
    return allTranslatedValuesNotificationType().sort();
}

export function valueFromTranslationNotificationType(translation: string): NotificationType {
    const index = allTranslatedValuesNotificationType().indexOf(translation);
    return allValuesNotificationType()[index] || NotificationType.proposal;
}

export enum Language {
    ptBr = "ptBr",
    enUs = "enUs",
}

export function translateLanguage(enumLanguage: Language): string {
    switch (enumLanguage) {
        case Language.ptBr: {
            return strings ? strings["enum"]["Language"]["ptBr"] || Language.ptBr : Language.ptBr;
        }
        case Language.enUs: {
            return strings ? strings["enum"]["Language"]["enUs"] || Language.enUs : Language.enUs;
        }
    }
    return "";
}

export function allValuesLanguage(): Language[] {
    return [
        Language.ptBr,
        Language.enUs,
    ];
}

export function allTranslatedValuesLanguage(): string[] {
    return [
        translateLanguage(Language.ptBr),
        translateLanguage(Language.enUs),
    ];
}

export function allDisplayableValuesLanguage(): string[] {
    return allTranslatedValuesLanguage().sort();
}

export function valueFromTranslationLanguage(translation: string): Language {
    const index = allTranslatedValuesLanguage().indexOf(translation);
    return allValuesLanguage()[index] || Language.ptBr;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum BannerType {
    standard = "standard",
    link = "link",
}

export function translateBannerType(enumBannerType: BannerType): string {
    switch (enumBannerType) {
        case BannerType.standard: {
            return strings ? strings["enum"]["BannerType"]["standard"] || BannerType.standard : BannerType.standard;
        }
        case BannerType.link: {
            return strings ? strings["enum"]["BannerType"]["link"] || BannerType.link : BannerType.link;
        }
    }
    return "";
}

export function allValuesBannerType(): BannerType[] {
    return [
        BannerType.standard,
        BannerType.link,
    ];
}

export function allTranslatedValuesBannerType(): string[] {
    return [
        translateBannerType(BannerType.standard),
        translateBannerType(BannerType.link),
    ];
}

export function allDisplayableValuesBannerType(): string[] {
    return allTranslatedValuesBannerType().sort();
}

export function valueFromTranslationBannerType(translation: string): BannerType {
    const index = allTranslatedValuesBannerType().indexOf(translation);
    return allValuesBannerType()[index] || BannerType.standard;
}

export enum Category {
    clothes = "clothes",
    accessories = "accessories",
    sport = "sport",
    toys = "toys",
    party = "party",
    educational = "educational",
    health = "health",
    customized = "customized",
}

export function translateCategory(enumCategory: Category): string {
    switch (enumCategory) {
        case Category.clothes: {
            return strings ? strings["enum"]["Category"]["clothes"] || Category.clothes : Category.clothes;
        }
        case Category.accessories: {
            return strings ? strings["enum"]["Category"]["accessories"] || Category.accessories : Category.accessories;
        }
        case Category.sport: {
            return strings ? strings["enum"]["Category"]["sport"] || Category.sport : Category.sport;
        }
        case Category.toys: {
            return strings ? strings["enum"]["Category"]["toys"] || Category.toys : Category.toys;
        }
        case Category.party: {
            return strings ? strings["enum"]["Category"]["party"] || Category.party : Category.party;
        }
        case Category.educational: {
            return strings ? strings["enum"]["Category"]["educational"] || Category.educational : Category.educational;
        }
        case Category.health: {
            return strings ? strings["enum"]["Category"]["health"] || Category.health : Category.health;
        }
        case Category.customized: {
            return strings ? strings["enum"]["Category"]["customized"] || Category.customized : Category.customized;
        }
    }
    return "";
}

export function allValuesCategory(): Category[] {
    return [
        Category.clothes,
        Category.accessories,
        Category.sport,
        Category.toys,
        Category.party,
        Category.educational,
        Category.health,
        Category.customized,
    ];
}

export function allTranslatedValuesCategory(): string[] {
    return [
        translateCategory(Category.clothes),
        translateCategory(Category.accessories),
        translateCategory(Category.sport),
        translateCategory(Category.toys),
        translateCategory(Category.party),
        translateCategory(Category.educational),
        translateCategory(Category.health),
        translateCategory(Category.customized),
    ];
}

export function allDisplayableValuesCategory(): string[] {
    return allTranslatedValuesCategory().sort();
}

export function valueFromTranslationCategory(translation: string): Category {
    const index = allTranslatedValuesCategory().indexOf(translation);
    return allValuesCategory()[index] || Category.clothes;
}

export enum ProductOrder {
    highestPrice = "highestPrice",
    lowerstPrice = "lowerstPrice",
}

export function translateProductOrder(enumProductOrder: ProductOrder): string {
    switch (enumProductOrder) {
        case ProductOrder.highestPrice: {
            return strings ? strings["enum"]["ProductOrder"]["highestPrice"] || ProductOrder.highestPrice : ProductOrder.highestPrice;
        }
        case ProductOrder.lowerstPrice: {
            return strings ? strings["enum"]["ProductOrder"]["lowerstPrice"] || ProductOrder.lowerstPrice : ProductOrder.lowerstPrice;
        }
    }
    return "";
}

export function allValuesProductOrder(): ProductOrder[] {
    return [
        ProductOrder.highestPrice,
        ProductOrder.lowerstPrice,
    ];
}

export function allTranslatedValuesProductOrder(): string[] {
    return [
        translateProductOrder(ProductOrder.highestPrice),
        translateProductOrder(ProductOrder.lowerstPrice),
    ];
}

export function allDisplayableValuesProductOrder(): string[] {
    return allTranslatedValuesProductOrder().sort();
}

export function valueFromTranslationProductOrder(translation: string): ProductOrder {
    const index = allTranslatedValuesProductOrder().indexOf(translation);
    return allValuesProductOrder()[index] || ProductOrder.highestPrice;
}

export enum PaymentMethod {
    creditCard = "creditCard",
    boleto = "boleto",
}

export function translatePaymentMethod(enumPaymentMethod: PaymentMethod): string {
    switch (enumPaymentMethod) {
        case PaymentMethod.creditCard: {
            return strings ? strings["enum"]["PaymentMethod"]["creditCard"] || PaymentMethod.creditCard : PaymentMethod.creditCard;
        }
        case PaymentMethod.boleto: {
            return strings ? strings["enum"]["PaymentMethod"]["boleto"] || PaymentMethod.boleto : PaymentMethod.boleto;
        }
    }
    return "";
}

export function allValuesPaymentMethod(): PaymentMethod[] {
    return [
        PaymentMethod.creditCard,
        PaymentMethod.boleto,
    ];
}

export function allTranslatedValuesPaymentMethod(): string[] {
    return [
        translatePaymentMethod(PaymentMethod.creditCard),
        translatePaymentMethod(PaymentMethod.boleto),
    ];
}

export function allDisplayableValuesPaymentMethod(): string[] {
    return allTranslatedValuesPaymentMethod().sort();
}

export function valueFromTranslationPaymentMethod(translation: string): PaymentMethod {
    const index = allTranslatedValuesPaymentMethod().indexOf(translation);
    return allValuesPaymentMethod()[index] || PaymentMethod.creditCard;
}

export enum OrderStatus {
    pendingPayment = "pendingPayment",
    analysing = "analysing",
    production = "production",
    onWay = "onWay",
    delivered = "delivered",
    canceled = "canceled",
}

export function translateOrderStatus(enumOrderStatus: OrderStatus): string {
    switch (enumOrderStatus) {
        case OrderStatus.pendingPayment: {
            return strings ? strings["enum"]["OrderStatus"]["pendingPayment"] || OrderStatus.pendingPayment : OrderStatus.pendingPayment;
        }
        case OrderStatus.analysing: {
            return strings ? strings["enum"]["OrderStatus"]["analysing"] || OrderStatus.analysing : OrderStatus.analysing;
        }
        case OrderStatus.production: {
            return strings ? strings["enum"]["OrderStatus"]["production"] || OrderStatus.production : OrderStatus.production;
        }
        case OrderStatus.onWay: {
            return strings ? strings["enum"]["OrderStatus"]["onWay"] || OrderStatus.onWay : OrderStatus.onWay;
        }
        case OrderStatus.delivered: {
            return strings ? strings["enum"]["OrderStatus"]["delivered"] || OrderStatus.delivered : OrderStatus.delivered;
        }
        case OrderStatus.canceled: {
            return strings ? strings["enum"]["OrderStatus"]["canceled"] || OrderStatus.canceled : OrderStatus.canceled;
        }
    }
    return "";
}

export function allValuesOrderStatus(): OrderStatus[] {
    return [
        OrderStatus.pendingPayment,
        OrderStatus.analysing,
        OrderStatus.production,
        OrderStatus.onWay,
        OrderStatus.delivered,
        OrderStatus.canceled,
    ];
}

export function allTranslatedValuesOrderStatus(): string[] {
    return [
        translateOrderStatus(OrderStatus.pendingPayment),
        translateOrderStatus(OrderStatus.analysing),
        translateOrderStatus(OrderStatus.production),
        translateOrderStatus(OrderStatus.onWay),
        translateOrderStatus(OrderStatus.delivered),
        translateOrderStatus(OrderStatus.canceled),
    ];
}

export function allDisplayableValuesOrderStatus(): string[] {
    return allTranslatedValuesOrderStatus().sort();
}

export function valueFromTranslationOrderStatus(translation: string): OrderStatus {
    const index = allTranslatedValuesOrderStatus().indexOf(translation);
    return allValuesOrderStatus()[index] || OrderStatus.pendingPayment;
}

export enum OrderFilterSortOrder {
    asc = "asc",
    desc = "desc",
}

export function translateOrderFilterSortOrder(enumOrderFilterSortOrder: OrderFilterSortOrder): string {
    switch (enumOrderFilterSortOrder) {
        case OrderFilterSortOrder.asc: {
            return strings ? strings["enum"]["OrderFilterSortOrder"]["asc"] || OrderFilterSortOrder.asc : OrderFilterSortOrder.asc;
        }
        case OrderFilterSortOrder.desc: {
            return strings ? strings["enum"]["OrderFilterSortOrder"]["desc"] || OrderFilterSortOrder.desc : OrderFilterSortOrder.desc;
        }
    }
    return "";
}

export function allValuesOrderFilterSortOrder(): OrderFilterSortOrder[] {
    return [
        OrderFilterSortOrder.asc,
        OrderFilterSortOrder.desc,
    ];
}

export function allTranslatedValuesOrderFilterSortOrder(): string[] {
    return [
        translateOrderFilterSortOrder(OrderFilterSortOrder.asc),
        translateOrderFilterSortOrder(OrderFilterSortOrder.desc),
    ];
}

export function allDisplayableValuesOrderFilterSortOrder(): string[] {
    return allTranslatedValuesOrderFilterSortOrder().sort();
}

export function valueFromTranslationOrderFilterSortOrder(translation: string): OrderFilterSortOrder {
    const index = allTranslatedValuesOrderFilterSortOrder().indexOf(translation);
    return allValuesOrderFilterSortOrder()[index] || OrderFilterSortOrder.asc;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    BadFormattedResponse = "BadFormattedResponse",
    InvalidDate = "InvalidDate",
    FailedUpload = "FailedUpload",
    MissingEnvVariable = "MissingEnvVariable",
    InternalError = "InternalError",
    FieldAlreadyInUse = "FieldAlreadyInUse",
    WrongPassword = "WrongPassword",
    EmptyArray = "EmptyArray",
    NotProductOwner = "NotProductOwner",
    NotApproved = "NotApproved",
    NotLoggedIn = "NotLoggedIn",
    UserBlocked = "UserBlocked",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    EmailAlreadyInUse = "EmailAlreadyInUse",
    InvalidEmail = "InvalidEmail",
    ExpiredResetPasswordToken = "ExpiredResetPasswordToken",
    LoginError = "LoginError",
    UserDoesntExist = "UserDoesntExist",
    AlreadyRegistered = "AlreadyRegistered",
    CpfAlreadyInUse = "CpfAlreadyInUse",
    NickAlreadyInUse = "NickAlreadyInUse",
    AccessNotAllowed = "AccessNotAllowed",
    ActionNotAllowed = "ActionNotAllowed",
    LawyerNotApproved = "LawyerNotApproved",
    JobAcceptedProposal = "JobAcceptedProposal",
    InvalidCard = "InvalidCard",
    SubscriptionDoesntExist = "SubscriptionDoesntExist",
    SubscriptionExpired = "SubscriptionExpired",
    SubscriptionPaymentRefused = "SubscriptionPaymentRefused",
    SubscriptionCanceled = "SubscriptionCanceled",
    LimitProposalReached = "LimitProposalReached",
    LimitFreeProposalReached = "LimitFreeProposalReached",
    StoreNotFoundInCart = "StoreNotFoundInCart",
    EmptyCart = "EmptyCart",
    StoreNeedAddress = "StoreNeedAddress",
    StoreNeedBankAccount = "StoreNeedBankAccount",
    PaymentNotApproved = "PaymentNotApproved",
    RecipientIdDoesntExist = "RecipientIdDoesntExist",
    TransferValueTooLow = "TransferValueTooLow",
    InsufficientFunds = "InsufficientFunds",
    UserNeedAddress = "UserNeedAddress",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InvalidDate: {
            return strings ? strings["enum"]["ErrorType"]["InvalidDate"] || ErrorType.InvalidDate : ErrorType.InvalidDate;
        }
        case ErrorType.FailedUpload: {
            return strings ? strings["enum"]["ErrorType"]["FailedUpload"] || ErrorType.FailedUpload : ErrorType.FailedUpload;
        }
        case ErrorType.MissingEnvVariable: {
            return strings ? strings["enum"]["ErrorType"]["MissingEnvVariable"] || ErrorType.MissingEnvVariable : ErrorType.MissingEnvVariable;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.FieldAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["FieldAlreadyInUse"] || ErrorType.FieldAlreadyInUse : ErrorType.FieldAlreadyInUse;
        }
        case ErrorType.WrongPassword: {
            return strings ? strings["enum"]["ErrorType"]["WrongPassword"] || ErrorType.WrongPassword : ErrorType.WrongPassword;
        }
        case ErrorType.EmptyArray: {
            return strings ? strings["enum"]["ErrorType"]["EmptyArray"] || ErrorType.EmptyArray : ErrorType.EmptyArray;
        }
        case ErrorType.NotProductOwner: {
            return strings ? strings["enum"]["ErrorType"]["NotProductOwner"] || ErrorType.NotProductOwner : ErrorType.NotProductOwner;
        }
        case ErrorType.NotApproved: {
            return strings ? strings["enum"]["ErrorType"]["NotApproved"] || ErrorType.NotApproved : ErrorType.NotApproved;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.UserBlocked: {
            return strings ? strings["enum"]["ErrorType"]["UserBlocked"] || ErrorType.UserBlocked : ErrorType.UserBlocked;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.EmailAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyInUse"] || ErrorType.EmailAlreadyInUse : ErrorType.EmailAlreadyInUse;
        }
        case ErrorType.InvalidEmail: {
            return strings ? strings["enum"]["ErrorType"]["InvalidEmail"] || ErrorType.InvalidEmail : ErrorType.InvalidEmail;
        }
        case ErrorType.ExpiredResetPasswordToken: {
            return strings ? strings["enum"]["ErrorType"]["ExpiredResetPasswordToken"] || ErrorType.ExpiredResetPasswordToken : ErrorType.ExpiredResetPasswordToken;
        }
        case ErrorType.LoginError: {
            return strings ? strings["enum"]["ErrorType"]["LoginError"] || ErrorType.LoginError : ErrorType.LoginError;
        }
        case ErrorType.UserDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesntExist"] || ErrorType.UserDoesntExist : ErrorType.UserDoesntExist;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.CpfAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["CpfAlreadyInUse"] || ErrorType.CpfAlreadyInUse : ErrorType.CpfAlreadyInUse;
        }
        case ErrorType.NickAlreadyInUse: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyInUse"] || ErrorType.NickAlreadyInUse : ErrorType.NickAlreadyInUse;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.ActionNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["ActionNotAllowed"] || ErrorType.ActionNotAllowed : ErrorType.ActionNotAllowed;
        }
        case ErrorType.LawyerNotApproved: {
            return strings ? strings["enum"]["ErrorType"]["LawyerNotApproved"] || ErrorType.LawyerNotApproved : ErrorType.LawyerNotApproved;
        }
        case ErrorType.JobAcceptedProposal: {
            return strings ? strings["enum"]["ErrorType"]["JobAcceptedProposal"] || ErrorType.JobAcceptedProposal : ErrorType.JobAcceptedProposal;
        }
        case ErrorType.InvalidCard: {
            return strings ? strings["enum"]["ErrorType"]["InvalidCard"] || ErrorType.InvalidCard : ErrorType.InvalidCard;
        }
        case ErrorType.SubscriptionDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["SubscriptionDoesntExist"] || ErrorType.SubscriptionDoesntExist : ErrorType.SubscriptionDoesntExist;
        }
        case ErrorType.SubscriptionExpired: {
            return strings ? strings["enum"]["ErrorType"]["SubscriptionExpired"] || ErrorType.SubscriptionExpired : ErrorType.SubscriptionExpired;
        }
        case ErrorType.SubscriptionPaymentRefused: {
            return strings ? strings["enum"]["ErrorType"]["SubscriptionPaymentRefused"] || ErrorType.SubscriptionPaymentRefused : ErrorType.SubscriptionPaymentRefused;
        }
        case ErrorType.SubscriptionCanceled: {
            return strings ? strings["enum"]["ErrorType"]["SubscriptionCanceled"] || ErrorType.SubscriptionCanceled : ErrorType.SubscriptionCanceled;
        }
        case ErrorType.LimitProposalReached: {
            return strings ? strings["enum"]["ErrorType"]["LimitProposalReached"] || ErrorType.LimitProposalReached : ErrorType.LimitProposalReached;
        }
        case ErrorType.LimitFreeProposalReached: {
            return strings ? strings["enum"]["ErrorType"]["LimitFreeProposalReached"] || ErrorType.LimitFreeProposalReached : ErrorType.LimitFreeProposalReached;
        }
        case ErrorType.StoreNotFoundInCart: {
            return strings ? strings["enum"]["ErrorType"]["StoreNotFoundInCart"] || ErrorType.StoreNotFoundInCart : ErrorType.StoreNotFoundInCart;
        }
        case ErrorType.EmptyCart: {
            return strings ? strings["enum"]["ErrorType"]["EmptyCart"] || ErrorType.EmptyCart : ErrorType.EmptyCart;
        }
        case ErrorType.StoreNeedAddress: {
            return strings ? strings["enum"]["ErrorType"]["StoreNeedAddress"] || ErrorType.StoreNeedAddress : ErrorType.StoreNeedAddress;
        }
        case ErrorType.StoreNeedBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["StoreNeedBankAccount"] || ErrorType.StoreNeedBankAccount : ErrorType.StoreNeedBankAccount;
        }
        case ErrorType.PaymentNotApproved: {
            return strings ? strings["enum"]["ErrorType"]["PaymentNotApproved"] || ErrorType.PaymentNotApproved : ErrorType.PaymentNotApproved;
        }
        case ErrorType.RecipientIdDoesntExist: {
            return strings ? strings["enum"]["ErrorType"]["RecipientIdDoesntExist"] || ErrorType.RecipientIdDoesntExist : ErrorType.RecipientIdDoesntExist;
        }
        case ErrorType.TransferValueTooLow: {
            return strings ? strings["enum"]["ErrorType"]["TransferValueTooLow"] || ErrorType.TransferValueTooLow : ErrorType.TransferValueTooLow;
        }
        case ErrorType.InsufficientFunds: {
            return strings ? strings["enum"]["ErrorType"]["InsufficientFunds"] || ErrorType.InsufficientFunds : ErrorType.InsufficientFunds;
        }
        case ErrorType.UserNeedAddress: {
            return strings ? strings["enum"]["ErrorType"]["UserNeedAddress"] || ErrorType.UserNeedAddress : ErrorType.UserNeedAddress;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.BadFormattedResponse,
        ErrorType.InvalidDate,
        ErrorType.FailedUpload,
        ErrorType.MissingEnvVariable,
        ErrorType.InternalError,
        ErrorType.FieldAlreadyInUse,
        ErrorType.WrongPassword,
        ErrorType.EmptyArray,
        ErrorType.NotProductOwner,
        ErrorType.NotApproved,
        ErrorType.NotLoggedIn,
        ErrorType.UserBlocked,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.EmailAlreadyInUse,
        ErrorType.InvalidEmail,
        ErrorType.ExpiredResetPasswordToken,
        ErrorType.LoginError,
        ErrorType.UserDoesntExist,
        ErrorType.AlreadyRegistered,
        ErrorType.CpfAlreadyInUse,
        ErrorType.NickAlreadyInUse,
        ErrorType.AccessNotAllowed,
        ErrorType.ActionNotAllowed,
        ErrorType.LawyerNotApproved,
        ErrorType.JobAcceptedProposal,
        ErrorType.InvalidCard,
        ErrorType.SubscriptionDoesntExist,
        ErrorType.SubscriptionExpired,
        ErrorType.SubscriptionPaymentRefused,
        ErrorType.SubscriptionCanceled,
        ErrorType.LimitProposalReached,
        ErrorType.LimitFreeProposalReached,
        ErrorType.StoreNotFoundInCart,
        ErrorType.EmptyCart,
        ErrorType.StoreNeedAddress,
        ErrorType.StoreNeedBankAccount,
        ErrorType.PaymentNotApproved,
        ErrorType.RecipientIdDoesntExist,
        ErrorType.TransferValueTooLow,
        ErrorType.InsufficientFunds,
        ErrorType.UserNeedAddress,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InvalidDate),
        translateErrorType(ErrorType.FailedUpload),
        translateErrorType(ErrorType.MissingEnvVariable),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.FieldAlreadyInUse),
        translateErrorType(ErrorType.WrongPassword),
        translateErrorType(ErrorType.EmptyArray),
        translateErrorType(ErrorType.NotProductOwner),
        translateErrorType(ErrorType.NotApproved),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.UserBlocked),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.EmailAlreadyInUse),
        translateErrorType(ErrorType.InvalidEmail),
        translateErrorType(ErrorType.ExpiredResetPasswordToken),
        translateErrorType(ErrorType.LoginError),
        translateErrorType(ErrorType.UserDoesntExist),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.CpfAlreadyInUse),
        translateErrorType(ErrorType.NickAlreadyInUse),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.ActionNotAllowed),
        translateErrorType(ErrorType.LawyerNotApproved),
        translateErrorType(ErrorType.JobAcceptedProposal),
        translateErrorType(ErrorType.InvalidCard),
        translateErrorType(ErrorType.SubscriptionDoesntExist),
        translateErrorType(ErrorType.SubscriptionExpired),
        translateErrorType(ErrorType.SubscriptionPaymentRefused),
        translateErrorType(ErrorType.SubscriptionCanceled),
        translateErrorType(ErrorType.LimitProposalReached),
        translateErrorType(ErrorType.LimitFreeProposalReached),
        translateErrorType(ErrorType.StoreNotFoundInCart),
        translateErrorType(ErrorType.EmptyCart),
        translateErrorType(ErrorType.StoreNeedAddress),
        translateErrorType(ErrorType.StoreNeedBankAccount),
        translateErrorType(ErrorType.PaymentNotApproved),
        translateErrorType(ErrorType.RecipientIdDoesntExist),
        translateErrorType(ErrorType.TransferValueTooLow),
        translateErrorType(ErrorType.InsufficientFunds),
        translateErrorType(ErrorType.UserNeedAddress),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadRawImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadRawImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, imageCrop: ImageCrop | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
        imageCrop: imageCrop === null || imageCrop === undefined ? null : {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function cropImage(src: string, imageCrop: ImageCrop, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        src: src,
        imageCrop: {
            x: imageCrop.x || 0,
            y: imageCrop.y || 0,
            width: imageCrop.width || 0,
            height: imageCrop.height || 0,
        },
    };
    const ret = await makeRequest({name: "cropImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadFile(file: UploadFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            bytes: file.bytes.toString("base64"),
            name: file.name,
        },
    };
    const ret = await makeRequest({name: "uploadFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function uploadUncertainFile(file: UncertainFile, progress?: (progress: number) => void): Promise<File> {
    const args = {
        file: {
            fileData: file.fileData === null || file.fileData === undefined ? null : {
                bytes: file.fileData.bytes.toString("base64"),
                name: file.fileData.name,
            },
            file: file.file === null || file.file === undefined ? null : {
                name: file.file.name,
                url: file.file.url,
            },
        },
    };
    const ret = await makeRequest({name: "uploadUncertainFile", args, progress});
    return {
        name: ret.name,
        url: ret.url,
    };
}

export async function getCurrentAdminUser(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminUser", args: {}, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
    };
    const ret = await makeRequest({name: "getAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        email: ret.email,
    };
}

export async function getAdminUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAdminUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        name: e.name,
        email: e.email,
    }));
}

export async function getExportAdminUsers(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "getExportAdminUsers", args: {}, progress});
    return ret;
}

export async function createAdminUser(newAdminUser: NewAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            password: newAdminUser.password,
            image: newAdminUser.image === null || newAdminUser.image === undefined ? null : {
                bytes: newAdminUser.image.bytes === null || newAdminUser.image.bytes === undefined ? null : newAdminUser.image.bytes.toString("base64"),
                image: newAdminUser.image.image === null || newAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: newAdminUser.image.image.thumb.width || 0,
                        height: newAdminUser.image.image.thumb.height || 0,
                        url: newAdminUser.image.image.thumb.url,
                    },
                    width: newAdminUser.image.image.width || 0,
                    height: newAdminUser.image.image.height || 0,
                    url: newAdminUser.image.image.url,
                },
            },
            name: newAdminUser.name,
            email: newAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "createAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        email: ret.email,
    };
}

export async function editAdminUser(adminUserId: string, editedAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        adminUserId: adminUserId,
        editedAdminUser: {
            image: editedAdminUser.image === null || editedAdminUser.image === undefined ? null : {
                bytes: editedAdminUser.image.bytes === null || editedAdminUser.image.bytes === undefined ? null : editedAdminUser.image.bytes.toString("base64"),
                image: editedAdminUser.image.image === null || editedAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editedAdminUser.image.image.thumb.width || 0,
                        height: editedAdminUser.image.image.thumb.height || 0,
                        url: editedAdminUser.image.image.thumb.url,
                    },
                    width: editedAdminUser.image.image.width || 0,
                    height: editedAdminUser.image.image.height || 0,
                    url: editedAdminUser.image.image.url,
                },
            },
            name: editedAdminUser.name,
            email: editedAdminUser.email,
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        email: ret.email,
    };
}

export async function deleteAdminUser(adminUserId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        adminUserId: adminUserId,
    };
    await makeRequest({name: "deleteAdminUser", args, progress});
    return undefined;
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        name: ret.name,
        email: ret.email,
    };
}

export async function adminLogout(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "adminLogout", args: {}, progress});
    return undefined;
}

export async function sendResetPasswordEmailAdminUser(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendResetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function resetPasswordEmailAdminUser(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPasswordEmailAdminUser", args, progress});
    return undefined;
}

export async function getBanners(pageOffset: number, progress?: (progress: number) => void): Promise<Banner[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getBanners", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        mobileImage: {
            thumb: {
                width: e.mobileImage.thumb.width || 0,
                height: e.mobileImage.thumb.height || 0,
                url: e.mobileImage.thumb.url,
            },
            width: e.mobileImage.width || 0,
            height: e.mobileImage.height || 0,
            url: e.mobileImage.url,
        },
        type: e.type,
        title: e.title,
        slug: e.slug,
        url: e.url === null || e.url === undefined ? null : e.url,
    }));
}

export async function getBanner(bannerId: string, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        bannerId: bannerId,
    };
    const ret = await makeRequest({name: "getBanner", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: {
            thumb: {
                width: ret.mobileImage.thumb.width || 0,
                height: ret.mobileImage.thumb.height || 0,
                url: ret.mobileImage.thumb.url,
            },
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        type: ret.type,
        title: ret.title,
        slug: ret.slug,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
    };
}

export async function createBanner(newBanner: NewBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        newBanner: {
            image: {
                bytes: newBanner.image.bytes === null || newBanner.image.bytes === undefined ? null : newBanner.image.bytes.toString("base64"),
                image: newBanner.image.image === null || newBanner.image.image === undefined ? null : {
                    thumb: {
                        width: newBanner.image.image.thumb.width || 0,
                        height: newBanner.image.image.thumb.height || 0,
                        url: newBanner.image.image.thumb.url,
                    },
                    width: newBanner.image.image.width || 0,
                    height: newBanner.image.image.height || 0,
                    url: newBanner.image.image.url,
                },
            },
            mobileImage: {
                bytes: newBanner.mobileImage.bytes === null || newBanner.mobileImage.bytes === undefined ? null : newBanner.mobileImage.bytes.toString("base64"),
                image: newBanner.mobileImage.image === null || newBanner.mobileImage.image === undefined ? null : {
                    thumb: {
                        width: newBanner.mobileImage.image.thumb.width || 0,
                        height: newBanner.mobileImage.image.thumb.height || 0,
                        url: newBanner.mobileImage.image.thumb.url,
                    },
                    width: newBanner.mobileImage.image.width || 0,
                    height: newBanner.mobileImage.image.height || 0,
                    url: newBanner.mobileImage.image.url,
                },
            },
            type: newBanner.type,
            title: newBanner.title,
            slug: newBanner.slug,
            url: newBanner.url === null || newBanner.url === undefined ? null : newBanner.url,
        },
    };
    const ret = await makeRequest({name: "createBanner", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: {
            thumb: {
                width: ret.mobileImage.thumb.width || 0,
                height: ret.mobileImage.thumb.height || 0,
                url: ret.mobileImage.thumb.url,
            },
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        type: ret.type,
        title: ret.title,
        slug: ret.slug,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
    };
}

export async function editBanner(bannerId: string, editBanner: EditBanner, progress?: (progress: number) => void): Promise<Banner> {
    const args = {
        bannerId: bannerId,
        editBanner: {
            image: {
                bytes: editBanner.image.bytes === null || editBanner.image.bytes === undefined ? null : editBanner.image.bytes.toString("base64"),
                image: editBanner.image.image === null || editBanner.image.image === undefined ? null : {
                    thumb: {
                        width: editBanner.image.image.thumb.width || 0,
                        height: editBanner.image.image.thumb.height || 0,
                        url: editBanner.image.image.thumb.url,
                    },
                    width: editBanner.image.image.width || 0,
                    height: editBanner.image.image.height || 0,
                    url: editBanner.image.image.url,
                },
            },
            mobileImage: {
                bytes: editBanner.mobileImage.bytes === null || editBanner.mobileImage.bytes === undefined ? null : editBanner.mobileImage.bytes.toString("base64"),
                image: editBanner.mobileImage.image === null || editBanner.mobileImage.image === undefined ? null : {
                    thumb: {
                        width: editBanner.mobileImage.image.thumb.width || 0,
                        height: editBanner.mobileImage.image.thumb.height || 0,
                        url: editBanner.mobileImage.image.thumb.url,
                    },
                    width: editBanner.mobileImage.image.width || 0,
                    height: editBanner.mobileImage.image.height || 0,
                    url: editBanner.mobileImage.image.url,
                },
            },
            type: editBanner.type,
            title: editBanner.title,
            slug: editBanner.slug,
            url: editBanner.url === null || editBanner.url === undefined ? null : editBanner.url,
        },
    };
    const ret = await makeRequest({name: "editBanner", args, progress});
    return {
        id: ret.id,
        image: {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        mobileImage: {
            thumb: {
                width: ret.mobileImage.thumb.width || 0,
                height: ret.mobileImage.thumb.height || 0,
                url: ret.mobileImage.thumb.url,
            },
            width: ret.mobileImage.width || 0,
            height: ret.mobileImage.height || 0,
            url: ret.mobileImage.url,
        },
        type: ret.type,
        title: ret.title,
        slug: ret.slug,
        url: ret.url === null || ret.url === undefined ? null : ret.url,
    };
}

export async function deleteBanner(bannerId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        bannerId: bannerId,
    };
    await makeRequest({name: "deleteBanner", args, progress});
    return undefined;
}

export async function getAllFaq(pageOffset: number, progress?: (progress: number) => void): Promise<Faq[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllFaq", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        title: e.title,
        description: e.description,
    }));
}

export async function getFaq(faqId: string, progress?: (progress: number) => void): Promise<Faq> {
    const args = {
        faqId: faqId,
    };
    const ret = await makeRequest({name: "getFaq", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        description: ret.description,
    };
}

export async function createFaq(newFaq: NewFaq, progress?: (progress: number) => void): Promise<Faq> {
    const args = {
        newFaq: {
            title: newFaq.title,
            description: newFaq.description,
        },
    };
    const ret = await makeRequest({name: "createFaq", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        description: ret.description,
    };
}

export async function deleteFaq(faqId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        faqId: faqId,
    };
    await makeRequest({name: "deleteFaq", args, progress});
    return undefined;
}

export async function editFaq(faqId: string, editFaq: NewFaq, progress?: (progress: number) => void): Promise<Faq> {
    const args = {
        faqId: faqId,
        editFaq: {
            title: editFaq.title,
            description: editFaq.description,
        },
    };
    const ret = await makeRequest({name: "editFaq", args, progress});
    return {
        id: ret.id,
        title: ret.title,
        description: ret.description,
    };
}

export async function getAllTags(progress?: (progress: number) => void): Promise<Tag[]> {
    const ret = await makeRequest({name: "getAllTags", args: {}, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function getAutoCompleteTags(search: string, progress?: (progress: number) => void): Promise<Tag[]> {
    const args = {
        search: search,
    };
    const ret = await makeRequest({name: "getAutoCompleteTags", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function deleteProduct(productId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        productId: productId,
    };
    await makeRequest({name: "deleteProduct", args, progress});
    return undefined;
}

export async function editProduct(newProduct: NewProduct, productId: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        newProduct: {
            images: newProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: newProduct.title,
            minAmount: newProduct.minAmount || 0,
            diameter: newProduct.diameter || 0,
            description: newProduct.description,
            price: newProduct.price || 0,
            productionTime: newProduct.productionTime,
            tags: newProduct.tags.map(e => ({
                id: e.id,
                name: e.name,
            })),
            category: newProduct.category,
            slug: newProduct.slug,
            height: newProduct.height || 0,
            length: newProduct.length || 0,
            width: newProduct.width || 0,
            weight: newProduct.weight || 0,
            freeShipping: !!newProduct.freeShipping,
        },
        productId: productId,
    };
    const ret = await makeRequest({name: "editProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        storeId: ret.storeId,
        liked: !!ret.liked,
        title: ret.title,
        minAmount: ret.minAmount || 0,
        diameter: ret.diameter || 0,
        description: ret.description,
        price: ret.price || 0,
        productionTime: ret.productionTime,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        category: ret.category,
        slug: ret.slug,
        height: ret.height || 0,
        length: ret.length || 0,
        width: ret.width || 0,
        weight: ret.weight || 0,
        freeShipping: !!ret.freeShipping,
    };
}

export async function getCurrentUserCards(pageOffset: number, progress?: (progress: number) => void): Promise<Card[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCurrentUserCards", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        lastDigits: e.lastDigits,
        exp: e.exp,
        brand: e.brand,
        ownerId: e.ownerId,
    }));
}

export async function createCard(newCard: NewCard, progress?: (progress: number) => void): Promise<Card> {
    const args = {
        newCard: {
            cardNumber: newCard.cardNumber,
            cvv: newCard.cvv,
            expDate: newCard.expDate,
            cardHolderName: newCard.cardHolderName,
        },
    };
    const ret = await makeRequest({name: "createCard", args, progress});
    return {
        id: ret.id,
        lastDigits: ret.lastDigits,
        exp: ret.exp,
        brand: ret.brand,
        ownerId: ret.ownerId,
    };
}

export async function deleteCard(cardId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        cardId: cardId,
    };
    await makeRequest({name: "deleteCard", args, progress});
    return undefined;
}

export async function createUser(newUser: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUser: {
            image: newUser.image === null || newUser.image === undefined ? null : {
                bytes: newUser.image.bytes === null || newUser.image.bytes === undefined ? null : newUser.image.bytes.toString("base64"),
                image: newUser.image.image === null || newUser.image.image === undefined ? null : {
                    thumb: {
                        width: newUser.image.image.thumb.width || 0,
                        height: newUser.image.image.thumb.height || 0,
                        url: newUser.image.image.thumb.url,
                    },
                    width: newUser.image.image.width || 0,
                    height: newUser.image.image.height || 0,
                    url: newUser.image.image.url,
                },
            },
            password: newUser.password,
            name: newUser.name,
            email: newUser.email,
            nick: newUser.nick,
            document: newUser.document,
            phone: newUser.phone,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        store: ret.store === null || ret.store === undefined ? null : {
            bankAccount: ret.store.bankAccount === null || ret.store.bankAccount === undefined ? null : {
                documentNumber: ret.store.bankAccount.documentNumber,
                bankName: ret.store.bankAccount.bankName,
                bankCode: ret.store.bankAccount.bankCode,
                agency: ret.store.bankAccount.agency,
                agencyDv: ret.store.bankAccount.agencyDv === null || ret.store.bankAccount.agencyDv === undefined ? null : ret.store.bankAccount.agencyDv,
                account: ret.store.bankAccount.account,
                accountDv: ret.store.bankAccount.accountDv,
                type: ret.store.bankAccount.type,
                legalName: ret.store.bankAccount.legalName,
            },
            description: ret.store.description,
            vendorPolicy: ret.store.vendorPolicy,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        name: ret.name,
        email: ret.email,
        nick: ret.nick,
        document: ret.document,
        phone: ret.phone,
    };
}

export async function editUser(userId: string, editedUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        editedUser: {
            image: editedUser.image === null || editedUser.image === undefined ? null : {
                bytes: editedUser.image.bytes === null || editedUser.image.bytes === undefined ? null : editedUser.image.bytes.toString("base64"),
                image: editedUser.image.image === null || editedUser.image.image === undefined ? null : {
                    thumb: {
                        width: editedUser.image.image.thumb.width || 0,
                        height: editedUser.image.image.thumb.height || 0,
                        url: editedUser.image.image.thumb.url,
                    },
                    width: editedUser.image.image.width || 0,
                    height: editedUser.image.image.height || 0,
                    url: editedUser.image.image.url,
                },
            },
            name: editedUser.name,
            email: editedUser.email,
            nick: editedUser.nick,
            document: editedUser.document,
            phone: editedUser.phone,
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        store: ret.store === null || ret.store === undefined ? null : {
            bankAccount: ret.store.bankAccount === null || ret.store.bankAccount === undefined ? null : {
                documentNumber: ret.store.bankAccount.documentNumber,
                bankName: ret.store.bankAccount.bankName,
                bankCode: ret.store.bankAccount.bankCode,
                agency: ret.store.bankAccount.agency,
                agencyDv: ret.store.bankAccount.agencyDv === null || ret.store.bankAccount.agencyDv === undefined ? null : ret.store.bankAccount.agencyDv,
                account: ret.store.bankAccount.account,
                accountDv: ret.store.bankAccount.accountDv,
                type: ret.store.bankAccount.type,
                legalName: ret.store.bankAccount.legalName,
            },
            description: ret.store.description,
            vendorPolicy: ret.store.vendorPolicy,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        name: ret.name,
        email: ret.email,
        nick: ret.nick,
        document: ret.document,
        phone: ret.phone,
    };
}

export async function deleteUser(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUser", args, progress});
    return undefined;
}

export async function deleteUserPermanently(userId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        userId: userId,
    };
    await makeRequest({name: "deleteUserPermanently", args, progress});
    return undefined;
}

export async function getUser(userId: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        store: ret.store === null || ret.store === undefined ? null : {
            bankAccount: ret.store.bankAccount === null || ret.store.bankAccount === undefined ? null : {
                documentNumber: ret.store.bankAccount.documentNumber,
                bankName: ret.store.bankAccount.bankName,
                bankCode: ret.store.bankAccount.bankCode,
                agency: ret.store.bankAccount.agency,
                agencyDv: ret.store.bankAccount.agencyDv === null || ret.store.bankAccount.agencyDv === undefined ? null : ret.store.bankAccount.agencyDv,
                account: ret.store.bankAccount.account,
                accountDv: ret.store.bankAccount.accountDv,
                type: ret.store.bankAccount.type,
                legalName: ret.store.bankAccount.legalName,
            },
            description: ret.store.description,
            vendorPolicy: ret.store.vendorPolicy,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        name: ret.name,
        email: ret.email,
        nick: ret.nick,
        document: ret.document,
        phone: ret.phone,
    };
}

export async function getUsers(pageOffset: number, filter: UsersFilter, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            isBlocked: filter.isBlocked === null || filter.isBlocked === undefined ? null : !!filter.isBlocked,
        },
    };
    const ret = await makeRequest({name: "getUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        store: e.store === null || e.store === undefined ? null : {
            bankAccount: e.store.bankAccount === null || e.store.bankAccount === undefined ? null : {
                documentNumber: e.store.bankAccount.documentNumber,
                bankName: e.store.bankAccount.bankName,
                bankCode: e.store.bankAccount.bankCode,
                agency: e.store.bankAccount.agency,
                agencyDv: e.store.bankAccount.agencyDv === null || e.store.bankAccount.agencyDv === undefined ? null : e.store.bankAccount.agencyDv,
                account: e.store.bankAccount.account,
                accountDv: e.store.bankAccount.accountDv,
                type: e.store.bankAccount.type,
                legalName: e.store.bankAccount.legalName,
            },
            description: e.store.description,
            vendorPolicy: e.store.vendorPolicy,
        },
        createdAt: new Date(e.createdAt + "Z"),
        blockedAt: e.blockedAt === null || e.blockedAt === undefined ? null : new Date(e.blockedAt + "Z"),
        address: e.address === null || e.address === undefined ? null : {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            country: e.address.country,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        name: e.name,
        email: e.email,
        nick: e.nick,
        document: e.document,
        phone: e.phone,
    }));
}

export async function getTotalNumberUsers(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberUsers", args: {}, progress});
    return ret || 0;
}

export async function blockUser(userId: string, isBlocked: boolean, progress?: (progress: number) => void): Promise<User> {
    const args = {
        userId: userId,
        isBlocked: !!isBlocked,
    };
    const ret = await makeRequest({name: "blockUser", args, progress});
    return {
        id: ret.id,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        store: ret.store === null || ret.store === undefined ? null : {
            bankAccount: ret.store.bankAccount === null || ret.store.bankAccount === undefined ? null : {
                documentNumber: ret.store.bankAccount.documentNumber,
                bankName: ret.store.bankAccount.bankName,
                bankCode: ret.store.bankAccount.bankCode,
                agency: ret.store.bankAccount.agency,
                agencyDv: ret.store.bankAccount.agencyDv === null || ret.store.bankAccount.agencyDv === undefined ? null : ret.store.bankAccount.agencyDv,
                account: ret.store.bankAccount.account,
                accountDv: ret.store.bankAccount.accountDv,
                type: ret.store.bankAccount.type,
                legalName: ret.store.bankAccount.legalName,
            },
            description: ret.store.description,
            vendorPolicy: ret.store.vendorPolicy,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        blockedAt: ret.blockedAt === null || ret.blockedAt === undefined ? null : new Date(ret.blockedAt + "Z"),
        address: ret.address === null || ret.address === undefined ? null : {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            country: ret.address.country,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        name: ret.name,
        email: ret.email,
        nick: ret.nick,
        document: ret.document,
        phone: ret.phone,
    };
}

export async function getOrder(orderId: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        orderId: orderId,
    };
    const ret = await makeRequest({name: "getOrder", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        observations: ret.observations,
        buyer: {
            id: ret.buyer.id,
            image: ret.buyer.image === null || ret.buyer.image === undefined ? null : {
                thumb: {
                    width: ret.buyer.image.thumb.width || 0,
                    height: ret.buyer.image.thumb.height || 0,
                    url: ret.buyer.image.thumb.url,
                },
                width: ret.buyer.image.width || 0,
                height: ret.buyer.image.height || 0,
                url: ret.buyer.image.url,
            },
            store: ret.buyer.store === null || ret.buyer.store === undefined ? null : {
                bankAccount: ret.buyer.store.bankAccount === null || ret.buyer.store.bankAccount === undefined ? null : {
                    documentNumber: ret.buyer.store.bankAccount.documentNumber,
                    bankName: ret.buyer.store.bankAccount.bankName,
                    bankCode: ret.buyer.store.bankAccount.bankCode,
                    agency: ret.buyer.store.bankAccount.agency,
                    agencyDv: ret.buyer.store.bankAccount.agencyDv === null || ret.buyer.store.bankAccount.agencyDv === undefined ? null : ret.buyer.store.bankAccount.agencyDv,
                    account: ret.buyer.store.bankAccount.account,
                    accountDv: ret.buyer.store.bankAccount.accountDv,
                    type: ret.buyer.store.bankAccount.type,
                    legalName: ret.buyer.store.bankAccount.legalName,
                },
                description: ret.buyer.store.description,
                vendorPolicy: ret.buyer.store.vendorPolicy,
            },
            createdAt: new Date(ret.buyer.createdAt + "Z"),
            blockedAt: ret.buyer.blockedAt === null || ret.buyer.blockedAt === undefined ? null : new Date(ret.buyer.blockedAt + "Z"),
            address: ret.buyer.address === null || ret.buyer.address === undefined ? null : {
                zipcode: ret.buyer.address.zipcode,
                street: ret.buyer.address.street,
                streetNumber: ret.buyer.address.streetNumber,
                complementary: ret.buyer.address.complementary === null || ret.buyer.address.complementary === undefined ? null : ret.buyer.address.complementary,
                neighborhood: ret.buyer.address.neighborhood,
                city: ret.buyer.address.city,
                state: ret.buyer.address.state,
                country: ret.buyer.address.country,
                countryCode: ret.buyer.address.countryCode,
                latLng: {
                    lat: ret.buyer.address.latLng.lat,
                    lng: ret.buyer.address.latLng.lng,
                },
            },
            name: ret.buyer.name,
            email: ret.buyer.email,
            nick: ret.buyer.nick,
            document: ret.buyer.document,
            phone: ret.buyer.phone,
        },
        seller: {
            id: ret.seller.id,
            image: ret.seller.image === null || ret.seller.image === undefined ? null : {
                thumb: {
                    width: ret.seller.image.thumb.width || 0,
                    height: ret.seller.image.thumb.height || 0,
                    url: ret.seller.image.thumb.url,
                },
                width: ret.seller.image.width || 0,
                height: ret.seller.image.height || 0,
                url: ret.seller.image.url,
            },
            store: ret.seller.store === null || ret.seller.store === undefined ? null : {
                bankAccount: ret.seller.store.bankAccount === null || ret.seller.store.bankAccount === undefined ? null : {
                    documentNumber: ret.seller.store.bankAccount.documentNumber,
                    bankName: ret.seller.store.bankAccount.bankName,
                    bankCode: ret.seller.store.bankAccount.bankCode,
                    agency: ret.seller.store.bankAccount.agency,
                    agencyDv: ret.seller.store.bankAccount.agencyDv === null || ret.seller.store.bankAccount.agencyDv === undefined ? null : ret.seller.store.bankAccount.agencyDv,
                    account: ret.seller.store.bankAccount.account,
                    accountDv: ret.seller.store.bankAccount.accountDv,
                    type: ret.seller.store.bankAccount.type,
                    legalName: ret.seller.store.bankAccount.legalName,
                },
                description: ret.seller.store.description,
                vendorPolicy: ret.seller.store.vendorPolicy,
            },
            createdAt: new Date(ret.seller.createdAt + "Z"),
            blockedAt: ret.seller.blockedAt === null || ret.seller.blockedAt === undefined ? null : new Date(ret.seller.blockedAt + "Z"),
            address: ret.seller.address === null || ret.seller.address === undefined ? null : {
                zipcode: ret.seller.address.zipcode,
                street: ret.seller.address.street,
                streetNumber: ret.seller.address.streetNumber,
                complementary: ret.seller.address.complementary === null || ret.seller.address.complementary === undefined ? null : ret.seller.address.complementary,
                neighborhood: ret.seller.address.neighborhood,
                city: ret.seller.address.city,
                state: ret.seller.address.state,
                country: ret.seller.address.country,
                countryCode: ret.seller.address.countryCode,
                latLng: {
                    lat: ret.seller.address.latLng.lat,
                    lng: ret.seller.address.latLng.lng,
                },
            },
            name: ret.seller.name,
            email: ret.seller.email,
            nick: ret.seller.nick,
            document: ret.seller.document,
            phone: ret.seller.phone,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        orderProducts: ret.orderProducts.map((e: any) => ({
            product: {
                id: e.product.id,
                images: e.product.images.map((e: any) => ({
                    thumb: {
                        width: e.thumb.width || 0,
                        height: e.thumb.height || 0,
                        url: e.thumb.url,
                    },
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                createdAt: new Date(e.product.createdAt + "Z"),
                storeId: e.product.storeId,
                liked: !!e.product.liked,
                title: e.product.title,
                minAmount: e.product.minAmount || 0,
                diameter: e.product.diameter || 0,
                description: e.product.description,
                price: e.product.price || 0,
                productionTime: e.product.productionTime,
                tags: e.product.tags.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                })),
                category: e.product.category,
                slug: e.product.slug,
                height: e.product.height || 0,
                length: e.product.length || 0,
                width: e.product.width || 0,
                weight: e.product.weight || 0,
                freeShipping: !!e.product.freeShipping,
            },
            amount: e.amount || 0,
        })),
        paymentMethod: ret.paymentMethod,
        status: ret.status,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changedAt: new Date(e.changedAt + "Z"),
        })),
        transport: {
            trackingCode: ret.transport.trackingCode === null || ret.transport.trackingCode === undefined ? null : ret.transport.trackingCode,
            sellerAddress: {
                zipcode: ret.transport.sellerAddress.zipcode,
                street: ret.transport.sellerAddress.street,
                streetNumber: ret.transport.sellerAddress.streetNumber,
                complementary: ret.transport.sellerAddress.complementary === null || ret.transport.sellerAddress.complementary === undefined ? null : ret.transport.sellerAddress.complementary,
                neighborhood: ret.transport.sellerAddress.neighborhood,
                city: ret.transport.sellerAddress.city,
                state: ret.transport.sellerAddress.state,
                country: ret.transport.sellerAddress.country,
                countryCode: ret.transport.sellerAddress.countryCode,
                latLng: {
                    lat: ret.transport.sellerAddress.latLng.lat,
                    lng: ret.transport.sellerAddress.latLng.lng,
                },
            },
            buyerAddress: {
                zipcode: ret.transport.buyerAddress.zipcode,
                street: ret.transport.buyerAddress.street,
                streetNumber: ret.transport.buyerAddress.streetNumber,
                complementary: ret.transport.buyerAddress.complementary === null || ret.transport.buyerAddress.complementary === undefined ? null : ret.transport.buyerAddress.complementary,
                neighborhood: ret.transport.buyerAddress.neighborhood,
                city: ret.transport.buyerAddress.city,
                state: ret.transport.buyerAddress.state,
                country: ret.transport.buyerAddress.country,
                countryCode: ret.transport.buyerAddress.countryCode,
                latLng: {
                    lat: ret.transport.buyerAddress.latLng.lat,
                    lng: ret.transport.buyerAddress.latLng.lng,
                },
            },
            total: ret.transport.total || 0,
        },
        payment: {
            card: ret.payment.card === null || ret.payment.card === undefined ? null : {
                id: ret.payment.card.id,
                lastDigits: ret.payment.card.lastDigits,
                exp: ret.payment.card.exp,
                brand: ret.payment.card.brand,
                ownerId: ret.payment.card.ownerId,
            },
            total: ret.payment.total || 0,
        },
    };
}

export async function getOrders(pageOffset: number, filter: OrderFilter, progress?: (progress: number) => void): Promise<Order[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            sellerId: filter.sellerId === null || filter.sellerId === undefined ? null : filter.sellerId,
            buyerId: filter.buyerId === null || filter.buyerId === undefined ? null : filter.buyerId,
            filterSortOrder: filter.filterSortOrder === null || filter.filterSortOrder === undefined ? null : filter.filterSortOrder,
        },
    };
    const ret = await makeRequest({name: "getOrders", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        numId: e.numId,
        observations: e.observations,
        buyer: {
            id: e.buyer.id,
            image: e.buyer.image === null || e.buyer.image === undefined ? null : {
                thumb: {
                    width: e.buyer.image.thumb.width || 0,
                    height: e.buyer.image.thumb.height || 0,
                    url: e.buyer.image.thumb.url,
                },
                width: e.buyer.image.width || 0,
                height: e.buyer.image.height || 0,
                url: e.buyer.image.url,
            },
            store: e.buyer.store === null || e.buyer.store === undefined ? null : {
                bankAccount: e.buyer.store.bankAccount === null || e.buyer.store.bankAccount === undefined ? null : {
                    documentNumber: e.buyer.store.bankAccount.documentNumber,
                    bankName: e.buyer.store.bankAccount.bankName,
                    bankCode: e.buyer.store.bankAccount.bankCode,
                    agency: e.buyer.store.bankAccount.agency,
                    agencyDv: e.buyer.store.bankAccount.agencyDv === null || e.buyer.store.bankAccount.agencyDv === undefined ? null : e.buyer.store.bankAccount.agencyDv,
                    account: e.buyer.store.bankAccount.account,
                    accountDv: e.buyer.store.bankAccount.accountDv,
                    type: e.buyer.store.bankAccount.type,
                    legalName: e.buyer.store.bankAccount.legalName,
                },
                description: e.buyer.store.description,
                vendorPolicy: e.buyer.store.vendorPolicy,
            },
            createdAt: new Date(e.buyer.createdAt + "Z"),
            blockedAt: e.buyer.blockedAt === null || e.buyer.blockedAt === undefined ? null : new Date(e.buyer.blockedAt + "Z"),
            address: e.buyer.address === null || e.buyer.address === undefined ? null : {
                zipcode: e.buyer.address.zipcode,
                street: e.buyer.address.street,
                streetNumber: e.buyer.address.streetNumber,
                complementary: e.buyer.address.complementary === null || e.buyer.address.complementary === undefined ? null : e.buyer.address.complementary,
                neighborhood: e.buyer.address.neighborhood,
                city: e.buyer.address.city,
                state: e.buyer.address.state,
                country: e.buyer.address.country,
                countryCode: e.buyer.address.countryCode,
                latLng: {
                    lat: e.buyer.address.latLng.lat,
                    lng: e.buyer.address.latLng.lng,
                },
            },
            name: e.buyer.name,
            email: e.buyer.email,
            nick: e.buyer.nick,
            document: e.buyer.document,
            phone: e.buyer.phone,
        },
        seller: {
            id: e.seller.id,
            image: e.seller.image === null || e.seller.image === undefined ? null : {
                thumb: {
                    width: e.seller.image.thumb.width || 0,
                    height: e.seller.image.thumb.height || 0,
                    url: e.seller.image.thumb.url,
                },
                width: e.seller.image.width || 0,
                height: e.seller.image.height || 0,
                url: e.seller.image.url,
            },
            store: e.seller.store === null || e.seller.store === undefined ? null : {
                bankAccount: e.seller.store.bankAccount === null || e.seller.store.bankAccount === undefined ? null : {
                    documentNumber: e.seller.store.bankAccount.documentNumber,
                    bankName: e.seller.store.bankAccount.bankName,
                    bankCode: e.seller.store.bankAccount.bankCode,
                    agency: e.seller.store.bankAccount.agency,
                    agencyDv: e.seller.store.bankAccount.agencyDv === null || e.seller.store.bankAccount.agencyDv === undefined ? null : e.seller.store.bankAccount.agencyDv,
                    account: e.seller.store.bankAccount.account,
                    accountDv: e.seller.store.bankAccount.accountDv,
                    type: e.seller.store.bankAccount.type,
                    legalName: e.seller.store.bankAccount.legalName,
                },
                description: e.seller.store.description,
                vendorPolicy: e.seller.store.vendorPolicy,
            },
            createdAt: new Date(e.seller.createdAt + "Z"),
            blockedAt: e.seller.blockedAt === null || e.seller.blockedAt === undefined ? null : new Date(e.seller.blockedAt + "Z"),
            address: e.seller.address === null || e.seller.address === undefined ? null : {
                zipcode: e.seller.address.zipcode,
                street: e.seller.address.street,
                streetNumber: e.seller.address.streetNumber,
                complementary: e.seller.address.complementary === null || e.seller.address.complementary === undefined ? null : e.seller.address.complementary,
                neighborhood: e.seller.address.neighborhood,
                city: e.seller.address.city,
                state: e.seller.address.state,
                country: e.seller.address.country,
                countryCode: e.seller.address.countryCode,
                latLng: {
                    lat: e.seller.address.latLng.lat,
                    lng: e.seller.address.latLng.lng,
                },
            },
            name: e.seller.name,
            email: e.seller.email,
            nick: e.seller.nick,
            document: e.seller.document,
            phone: e.seller.phone,
        },
        createdAt: new Date(e.createdAt + "Z"),
        orderProducts: e.orderProducts.map((e: any) => ({
            product: {
                id: e.product.id,
                images: e.product.images.map((e: any) => ({
                    thumb: {
                        width: e.thumb.width || 0,
                        height: e.thumb.height || 0,
                        url: e.thumb.url,
                    },
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                createdAt: new Date(e.product.createdAt + "Z"),
                storeId: e.product.storeId,
                liked: !!e.product.liked,
                title: e.product.title,
                minAmount: e.product.minAmount || 0,
                diameter: e.product.diameter || 0,
                description: e.product.description,
                price: e.product.price || 0,
                productionTime: e.product.productionTime,
                tags: e.product.tags.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                })),
                category: e.product.category,
                slug: e.product.slug,
                height: e.product.height || 0,
                length: e.product.length || 0,
                width: e.product.width || 0,
                weight: e.product.weight || 0,
                freeShipping: !!e.product.freeShipping,
            },
            amount: e.amount || 0,
        })),
        paymentMethod: e.paymentMethod,
        status: e.status,
        statusList: e.statusList.map((e: any) => ({
            status: e.status,
            changedAt: new Date(e.changedAt + "Z"),
        })),
        transport: {
            trackingCode: e.transport.trackingCode === null || e.transport.trackingCode === undefined ? null : e.transport.trackingCode,
            sellerAddress: {
                zipcode: e.transport.sellerAddress.zipcode,
                street: e.transport.sellerAddress.street,
                streetNumber: e.transport.sellerAddress.streetNumber,
                complementary: e.transport.sellerAddress.complementary === null || e.transport.sellerAddress.complementary === undefined ? null : e.transport.sellerAddress.complementary,
                neighborhood: e.transport.sellerAddress.neighborhood,
                city: e.transport.sellerAddress.city,
                state: e.transport.sellerAddress.state,
                country: e.transport.sellerAddress.country,
                countryCode: e.transport.sellerAddress.countryCode,
                latLng: {
                    lat: e.transport.sellerAddress.latLng.lat,
                    lng: e.transport.sellerAddress.latLng.lng,
                },
            },
            buyerAddress: {
                zipcode: e.transport.buyerAddress.zipcode,
                street: e.transport.buyerAddress.street,
                streetNumber: e.transport.buyerAddress.streetNumber,
                complementary: e.transport.buyerAddress.complementary === null || e.transport.buyerAddress.complementary === undefined ? null : e.transport.buyerAddress.complementary,
                neighborhood: e.transport.buyerAddress.neighborhood,
                city: e.transport.buyerAddress.city,
                state: e.transport.buyerAddress.state,
                country: e.transport.buyerAddress.country,
                countryCode: e.transport.buyerAddress.countryCode,
                latLng: {
                    lat: e.transport.buyerAddress.latLng.lat,
                    lng: e.transport.buyerAddress.latLng.lng,
                },
            },
            total: e.transport.total || 0,
        },
        payment: {
            card: e.payment.card === null || e.payment.card === undefined ? null : {
                id: e.payment.card.id,
                lastDigits: e.payment.card.lastDigits,
                exp: e.payment.card.exp,
                brand: e.payment.card.brand,
                ownerId: e.payment.card.ownerId,
            },
            total: e.payment.total || 0,
        },
    }));
}

export async function getTotalOrdersAmount(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalOrdersAmount", args: {}, progress});
    return ret || 0;
}

export async function getTotalOrdersAmountByUser(sellerId: string | null, buyerId: string | null, progress?: (progress: number) => void): Promise<number> {
    const args = {
        sellerId: sellerId === null || sellerId === undefined ? null : sellerId,
        buyerId: buyerId === null || buyerId === undefined ? null : buyerId,
    };
    const ret = await makeRequest({name: "getTotalOrdersAmountByUser", args, progress});
    return ret || 0;
}

export async function cancelOrderWithRefund(orderId: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        orderId: orderId,
    };
    const ret = await makeRequest({name: "cancelOrderWithRefund", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        observations: ret.observations,
        buyer: {
            id: ret.buyer.id,
            image: ret.buyer.image === null || ret.buyer.image === undefined ? null : {
                thumb: {
                    width: ret.buyer.image.thumb.width || 0,
                    height: ret.buyer.image.thumb.height || 0,
                    url: ret.buyer.image.thumb.url,
                },
                width: ret.buyer.image.width || 0,
                height: ret.buyer.image.height || 0,
                url: ret.buyer.image.url,
            },
            store: ret.buyer.store === null || ret.buyer.store === undefined ? null : {
                bankAccount: ret.buyer.store.bankAccount === null || ret.buyer.store.bankAccount === undefined ? null : {
                    documentNumber: ret.buyer.store.bankAccount.documentNumber,
                    bankName: ret.buyer.store.bankAccount.bankName,
                    bankCode: ret.buyer.store.bankAccount.bankCode,
                    agency: ret.buyer.store.bankAccount.agency,
                    agencyDv: ret.buyer.store.bankAccount.agencyDv === null || ret.buyer.store.bankAccount.agencyDv === undefined ? null : ret.buyer.store.bankAccount.agencyDv,
                    account: ret.buyer.store.bankAccount.account,
                    accountDv: ret.buyer.store.bankAccount.accountDv,
                    type: ret.buyer.store.bankAccount.type,
                    legalName: ret.buyer.store.bankAccount.legalName,
                },
                description: ret.buyer.store.description,
                vendorPolicy: ret.buyer.store.vendorPolicy,
            },
            createdAt: new Date(ret.buyer.createdAt + "Z"),
            blockedAt: ret.buyer.blockedAt === null || ret.buyer.blockedAt === undefined ? null : new Date(ret.buyer.blockedAt + "Z"),
            address: ret.buyer.address === null || ret.buyer.address === undefined ? null : {
                zipcode: ret.buyer.address.zipcode,
                street: ret.buyer.address.street,
                streetNumber: ret.buyer.address.streetNumber,
                complementary: ret.buyer.address.complementary === null || ret.buyer.address.complementary === undefined ? null : ret.buyer.address.complementary,
                neighborhood: ret.buyer.address.neighborhood,
                city: ret.buyer.address.city,
                state: ret.buyer.address.state,
                country: ret.buyer.address.country,
                countryCode: ret.buyer.address.countryCode,
                latLng: {
                    lat: ret.buyer.address.latLng.lat,
                    lng: ret.buyer.address.latLng.lng,
                },
            },
            name: ret.buyer.name,
            email: ret.buyer.email,
            nick: ret.buyer.nick,
            document: ret.buyer.document,
            phone: ret.buyer.phone,
        },
        seller: {
            id: ret.seller.id,
            image: ret.seller.image === null || ret.seller.image === undefined ? null : {
                thumb: {
                    width: ret.seller.image.thumb.width || 0,
                    height: ret.seller.image.thumb.height || 0,
                    url: ret.seller.image.thumb.url,
                },
                width: ret.seller.image.width || 0,
                height: ret.seller.image.height || 0,
                url: ret.seller.image.url,
            },
            store: ret.seller.store === null || ret.seller.store === undefined ? null : {
                bankAccount: ret.seller.store.bankAccount === null || ret.seller.store.bankAccount === undefined ? null : {
                    documentNumber: ret.seller.store.bankAccount.documentNumber,
                    bankName: ret.seller.store.bankAccount.bankName,
                    bankCode: ret.seller.store.bankAccount.bankCode,
                    agency: ret.seller.store.bankAccount.agency,
                    agencyDv: ret.seller.store.bankAccount.agencyDv === null || ret.seller.store.bankAccount.agencyDv === undefined ? null : ret.seller.store.bankAccount.agencyDv,
                    account: ret.seller.store.bankAccount.account,
                    accountDv: ret.seller.store.bankAccount.accountDv,
                    type: ret.seller.store.bankAccount.type,
                    legalName: ret.seller.store.bankAccount.legalName,
                },
                description: ret.seller.store.description,
                vendorPolicy: ret.seller.store.vendorPolicy,
            },
            createdAt: new Date(ret.seller.createdAt + "Z"),
            blockedAt: ret.seller.blockedAt === null || ret.seller.blockedAt === undefined ? null : new Date(ret.seller.blockedAt + "Z"),
            address: ret.seller.address === null || ret.seller.address === undefined ? null : {
                zipcode: ret.seller.address.zipcode,
                street: ret.seller.address.street,
                streetNumber: ret.seller.address.streetNumber,
                complementary: ret.seller.address.complementary === null || ret.seller.address.complementary === undefined ? null : ret.seller.address.complementary,
                neighborhood: ret.seller.address.neighborhood,
                city: ret.seller.address.city,
                state: ret.seller.address.state,
                country: ret.seller.address.country,
                countryCode: ret.seller.address.countryCode,
                latLng: {
                    lat: ret.seller.address.latLng.lat,
                    lng: ret.seller.address.latLng.lng,
                },
            },
            name: ret.seller.name,
            email: ret.seller.email,
            nick: ret.seller.nick,
            document: ret.seller.document,
            phone: ret.seller.phone,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        orderProducts: ret.orderProducts.map((e: any) => ({
            product: {
                id: e.product.id,
                images: e.product.images.map((e: any) => ({
                    thumb: {
                        width: e.thumb.width || 0,
                        height: e.thumb.height || 0,
                        url: e.thumb.url,
                    },
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                createdAt: new Date(e.product.createdAt + "Z"),
                storeId: e.product.storeId,
                liked: !!e.product.liked,
                title: e.product.title,
                minAmount: e.product.minAmount || 0,
                diameter: e.product.diameter || 0,
                description: e.product.description,
                price: e.product.price || 0,
                productionTime: e.product.productionTime,
                tags: e.product.tags.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                })),
                category: e.product.category,
                slug: e.product.slug,
                height: e.product.height || 0,
                length: e.product.length || 0,
                width: e.product.width || 0,
                weight: e.product.weight || 0,
                freeShipping: !!e.product.freeShipping,
            },
            amount: e.amount || 0,
        })),
        paymentMethod: ret.paymentMethod,
        status: ret.status,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changedAt: new Date(e.changedAt + "Z"),
        })),
        transport: {
            trackingCode: ret.transport.trackingCode === null || ret.transport.trackingCode === undefined ? null : ret.transport.trackingCode,
            sellerAddress: {
                zipcode: ret.transport.sellerAddress.zipcode,
                street: ret.transport.sellerAddress.street,
                streetNumber: ret.transport.sellerAddress.streetNumber,
                complementary: ret.transport.sellerAddress.complementary === null || ret.transport.sellerAddress.complementary === undefined ? null : ret.transport.sellerAddress.complementary,
                neighborhood: ret.transport.sellerAddress.neighborhood,
                city: ret.transport.sellerAddress.city,
                state: ret.transport.sellerAddress.state,
                country: ret.transport.sellerAddress.country,
                countryCode: ret.transport.sellerAddress.countryCode,
                latLng: {
                    lat: ret.transport.sellerAddress.latLng.lat,
                    lng: ret.transport.sellerAddress.latLng.lng,
                },
            },
            buyerAddress: {
                zipcode: ret.transport.buyerAddress.zipcode,
                street: ret.transport.buyerAddress.street,
                streetNumber: ret.transport.buyerAddress.streetNumber,
                complementary: ret.transport.buyerAddress.complementary === null || ret.transport.buyerAddress.complementary === undefined ? null : ret.transport.buyerAddress.complementary,
                neighborhood: ret.transport.buyerAddress.neighborhood,
                city: ret.transport.buyerAddress.city,
                state: ret.transport.buyerAddress.state,
                country: ret.transport.buyerAddress.country,
                countryCode: ret.transport.buyerAddress.countryCode,
                latLng: {
                    lat: ret.transport.buyerAddress.latLng.lat,
                    lng: ret.transport.buyerAddress.latLng.lng,
                },
            },
            total: ret.transport.total || 0,
        },
        payment: {
            card: ret.payment.card === null || ret.payment.card === undefined ? null : {
                id: ret.payment.card.id,
                lastDigits: ret.payment.card.lastDigits,
                exp: ret.payment.card.exp,
                brand: ret.payment.card.brand,
                ownerId: ret.payment.card.ownerId,
            },
            total: ret.payment.total || 0,
        },
    };
}

export async function refundOrderCredit(orderId: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        orderId: orderId,
    };
    const ret = await makeRequest({name: "refundOrderCredit", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        observations: ret.observations,
        buyer: {
            id: ret.buyer.id,
            image: ret.buyer.image === null || ret.buyer.image === undefined ? null : {
                thumb: {
                    width: ret.buyer.image.thumb.width || 0,
                    height: ret.buyer.image.thumb.height || 0,
                    url: ret.buyer.image.thumb.url,
                },
                width: ret.buyer.image.width || 0,
                height: ret.buyer.image.height || 0,
                url: ret.buyer.image.url,
            },
            store: ret.buyer.store === null || ret.buyer.store === undefined ? null : {
                bankAccount: ret.buyer.store.bankAccount === null || ret.buyer.store.bankAccount === undefined ? null : {
                    documentNumber: ret.buyer.store.bankAccount.documentNumber,
                    bankName: ret.buyer.store.bankAccount.bankName,
                    bankCode: ret.buyer.store.bankAccount.bankCode,
                    agency: ret.buyer.store.bankAccount.agency,
                    agencyDv: ret.buyer.store.bankAccount.agencyDv === null || ret.buyer.store.bankAccount.agencyDv === undefined ? null : ret.buyer.store.bankAccount.agencyDv,
                    account: ret.buyer.store.bankAccount.account,
                    accountDv: ret.buyer.store.bankAccount.accountDv,
                    type: ret.buyer.store.bankAccount.type,
                    legalName: ret.buyer.store.bankAccount.legalName,
                },
                description: ret.buyer.store.description,
                vendorPolicy: ret.buyer.store.vendorPolicy,
            },
            createdAt: new Date(ret.buyer.createdAt + "Z"),
            blockedAt: ret.buyer.blockedAt === null || ret.buyer.blockedAt === undefined ? null : new Date(ret.buyer.blockedAt + "Z"),
            address: ret.buyer.address === null || ret.buyer.address === undefined ? null : {
                zipcode: ret.buyer.address.zipcode,
                street: ret.buyer.address.street,
                streetNumber: ret.buyer.address.streetNumber,
                complementary: ret.buyer.address.complementary === null || ret.buyer.address.complementary === undefined ? null : ret.buyer.address.complementary,
                neighborhood: ret.buyer.address.neighborhood,
                city: ret.buyer.address.city,
                state: ret.buyer.address.state,
                country: ret.buyer.address.country,
                countryCode: ret.buyer.address.countryCode,
                latLng: {
                    lat: ret.buyer.address.latLng.lat,
                    lng: ret.buyer.address.latLng.lng,
                },
            },
            name: ret.buyer.name,
            email: ret.buyer.email,
            nick: ret.buyer.nick,
            document: ret.buyer.document,
            phone: ret.buyer.phone,
        },
        seller: {
            id: ret.seller.id,
            image: ret.seller.image === null || ret.seller.image === undefined ? null : {
                thumb: {
                    width: ret.seller.image.thumb.width || 0,
                    height: ret.seller.image.thumb.height || 0,
                    url: ret.seller.image.thumb.url,
                },
                width: ret.seller.image.width || 0,
                height: ret.seller.image.height || 0,
                url: ret.seller.image.url,
            },
            store: ret.seller.store === null || ret.seller.store === undefined ? null : {
                bankAccount: ret.seller.store.bankAccount === null || ret.seller.store.bankAccount === undefined ? null : {
                    documentNumber: ret.seller.store.bankAccount.documentNumber,
                    bankName: ret.seller.store.bankAccount.bankName,
                    bankCode: ret.seller.store.bankAccount.bankCode,
                    agency: ret.seller.store.bankAccount.agency,
                    agencyDv: ret.seller.store.bankAccount.agencyDv === null || ret.seller.store.bankAccount.agencyDv === undefined ? null : ret.seller.store.bankAccount.agencyDv,
                    account: ret.seller.store.bankAccount.account,
                    accountDv: ret.seller.store.bankAccount.accountDv,
                    type: ret.seller.store.bankAccount.type,
                    legalName: ret.seller.store.bankAccount.legalName,
                },
                description: ret.seller.store.description,
                vendorPolicy: ret.seller.store.vendorPolicy,
            },
            createdAt: new Date(ret.seller.createdAt + "Z"),
            blockedAt: ret.seller.blockedAt === null || ret.seller.blockedAt === undefined ? null : new Date(ret.seller.blockedAt + "Z"),
            address: ret.seller.address === null || ret.seller.address === undefined ? null : {
                zipcode: ret.seller.address.zipcode,
                street: ret.seller.address.street,
                streetNumber: ret.seller.address.streetNumber,
                complementary: ret.seller.address.complementary === null || ret.seller.address.complementary === undefined ? null : ret.seller.address.complementary,
                neighborhood: ret.seller.address.neighborhood,
                city: ret.seller.address.city,
                state: ret.seller.address.state,
                country: ret.seller.address.country,
                countryCode: ret.seller.address.countryCode,
                latLng: {
                    lat: ret.seller.address.latLng.lat,
                    lng: ret.seller.address.latLng.lng,
                },
            },
            name: ret.seller.name,
            email: ret.seller.email,
            nick: ret.seller.nick,
            document: ret.seller.document,
            phone: ret.seller.phone,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        orderProducts: ret.orderProducts.map((e: any) => ({
            product: {
                id: e.product.id,
                images: e.product.images.map((e: any) => ({
                    thumb: {
                        width: e.thumb.width || 0,
                        height: e.thumb.height || 0,
                        url: e.thumb.url,
                    },
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                createdAt: new Date(e.product.createdAt + "Z"),
                storeId: e.product.storeId,
                liked: !!e.product.liked,
                title: e.product.title,
                minAmount: e.product.minAmount || 0,
                diameter: e.product.diameter || 0,
                description: e.product.description,
                price: e.product.price || 0,
                productionTime: e.product.productionTime,
                tags: e.product.tags.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                })),
                category: e.product.category,
                slug: e.product.slug,
                height: e.product.height || 0,
                length: e.product.length || 0,
                width: e.product.width || 0,
                weight: e.product.weight || 0,
                freeShipping: !!e.product.freeShipping,
            },
            amount: e.amount || 0,
        })),
        paymentMethod: ret.paymentMethod,
        status: ret.status,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changedAt: new Date(e.changedAt + "Z"),
        })),
        transport: {
            trackingCode: ret.transport.trackingCode === null || ret.transport.trackingCode === undefined ? null : ret.transport.trackingCode,
            sellerAddress: {
                zipcode: ret.transport.sellerAddress.zipcode,
                street: ret.transport.sellerAddress.street,
                streetNumber: ret.transport.sellerAddress.streetNumber,
                complementary: ret.transport.sellerAddress.complementary === null || ret.transport.sellerAddress.complementary === undefined ? null : ret.transport.sellerAddress.complementary,
                neighborhood: ret.transport.sellerAddress.neighborhood,
                city: ret.transport.sellerAddress.city,
                state: ret.transport.sellerAddress.state,
                country: ret.transport.sellerAddress.country,
                countryCode: ret.transport.sellerAddress.countryCode,
                latLng: {
                    lat: ret.transport.sellerAddress.latLng.lat,
                    lng: ret.transport.sellerAddress.latLng.lng,
                },
            },
            buyerAddress: {
                zipcode: ret.transport.buyerAddress.zipcode,
                street: ret.transport.buyerAddress.street,
                streetNumber: ret.transport.buyerAddress.streetNumber,
                complementary: ret.transport.buyerAddress.complementary === null || ret.transport.buyerAddress.complementary === undefined ? null : ret.transport.buyerAddress.complementary,
                neighborhood: ret.transport.buyerAddress.neighborhood,
                city: ret.transport.buyerAddress.city,
                state: ret.transport.buyerAddress.state,
                country: ret.transport.buyerAddress.country,
                countryCode: ret.transport.buyerAddress.countryCode,
                latLng: {
                    lat: ret.transport.buyerAddress.latLng.lat,
                    lng: ret.transport.buyerAddress.latLng.lng,
                },
            },
            total: ret.transport.total || 0,
        },
        payment: {
            card: ret.payment.card === null || ret.payment.card === undefined ? null : {
                id: ret.payment.card.id,
                lastDigits: ret.payment.card.lastDigits,
                exp: ret.payment.card.exp,
                brand: ret.payment.card.brand,
                ownerId: ret.payment.card.ownerId,
            },
            total: ret.payment.total || 0,
        },
    };
}

export async function refundOrderBoleto(orderId: string, progress?: (progress: number) => void): Promise<Order> {
    const args = {
        orderId: orderId,
    };
    const ret = await makeRequest({name: "refundOrderBoleto", args, progress});
    return {
        id: ret.id,
        numId: ret.numId,
        observations: ret.observations,
        buyer: {
            id: ret.buyer.id,
            image: ret.buyer.image === null || ret.buyer.image === undefined ? null : {
                thumb: {
                    width: ret.buyer.image.thumb.width || 0,
                    height: ret.buyer.image.thumb.height || 0,
                    url: ret.buyer.image.thumb.url,
                },
                width: ret.buyer.image.width || 0,
                height: ret.buyer.image.height || 0,
                url: ret.buyer.image.url,
            },
            store: ret.buyer.store === null || ret.buyer.store === undefined ? null : {
                bankAccount: ret.buyer.store.bankAccount === null || ret.buyer.store.bankAccount === undefined ? null : {
                    documentNumber: ret.buyer.store.bankAccount.documentNumber,
                    bankName: ret.buyer.store.bankAccount.bankName,
                    bankCode: ret.buyer.store.bankAccount.bankCode,
                    agency: ret.buyer.store.bankAccount.agency,
                    agencyDv: ret.buyer.store.bankAccount.agencyDv === null || ret.buyer.store.bankAccount.agencyDv === undefined ? null : ret.buyer.store.bankAccount.agencyDv,
                    account: ret.buyer.store.bankAccount.account,
                    accountDv: ret.buyer.store.bankAccount.accountDv,
                    type: ret.buyer.store.bankAccount.type,
                    legalName: ret.buyer.store.bankAccount.legalName,
                },
                description: ret.buyer.store.description,
                vendorPolicy: ret.buyer.store.vendorPolicy,
            },
            createdAt: new Date(ret.buyer.createdAt + "Z"),
            blockedAt: ret.buyer.blockedAt === null || ret.buyer.blockedAt === undefined ? null : new Date(ret.buyer.blockedAt + "Z"),
            address: ret.buyer.address === null || ret.buyer.address === undefined ? null : {
                zipcode: ret.buyer.address.zipcode,
                street: ret.buyer.address.street,
                streetNumber: ret.buyer.address.streetNumber,
                complementary: ret.buyer.address.complementary === null || ret.buyer.address.complementary === undefined ? null : ret.buyer.address.complementary,
                neighborhood: ret.buyer.address.neighborhood,
                city: ret.buyer.address.city,
                state: ret.buyer.address.state,
                country: ret.buyer.address.country,
                countryCode: ret.buyer.address.countryCode,
                latLng: {
                    lat: ret.buyer.address.latLng.lat,
                    lng: ret.buyer.address.latLng.lng,
                },
            },
            name: ret.buyer.name,
            email: ret.buyer.email,
            nick: ret.buyer.nick,
            document: ret.buyer.document,
            phone: ret.buyer.phone,
        },
        seller: {
            id: ret.seller.id,
            image: ret.seller.image === null || ret.seller.image === undefined ? null : {
                thumb: {
                    width: ret.seller.image.thumb.width || 0,
                    height: ret.seller.image.thumb.height || 0,
                    url: ret.seller.image.thumb.url,
                },
                width: ret.seller.image.width || 0,
                height: ret.seller.image.height || 0,
                url: ret.seller.image.url,
            },
            store: ret.seller.store === null || ret.seller.store === undefined ? null : {
                bankAccount: ret.seller.store.bankAccount === null || ret.seller.store.bankAccount === undefined ? null : {
                    documentNumber: ret.seller.store.bankAccount.documentNumber,
                    bankName: ret.seller.store.bankAccount.bankName,
                    bankCode: ret.seller.store.bankAccount.bankCode,
                    agency: ret.seller.store.bankAccount.agency,
                    agencyDv: ret.seller.store.bankAccount.agencyDv === null || ret.seller.store.bankAccount.agencyDv === undefined ? null : ret.seller.store.bankAccount.agencyDv,
                    account: ret.seller.store.bankAccount.account,
                    accountDv: ret.seller.store.bankAccount.accountDv,
                    type: ret.seller.store.bankAccount.type,
                    legalName: ret.seller.store.bankAccount.legalName,
                },
                description: ret.seller.store.description,
                vendorPolicy: ret.seller.store.vendorPolicy,
            },
            createdAt: new Date(ret.seller.createdAt + "Z"),
            blockedAt: ret.seller.blockedAt === null || ret.seller.blockedAt === undefined ? null : new Date(ret.seller.blockedAt + "Z"),
            address: ret.seller.address === null || ret.seller.address === undefined ? null : {
                zipcode: ret.seller.address.zipcode,
                street: ret.seller.address.street,
                streetNumber: ret.seller.address.streetNumber,
                complementary: ret.seller.address.complementary === null || ret.seller.address.complementary === undefined ? null : ret.seller.address.complementary,
                neighborhood: ret.seller.address.neighborhood,
                city: ret.seller.address.city,
                state: ret.seller.address.state,
                country: ret.seller.address.country,
                countryCode: ret.seller.address.countryCode,
                latLng: {
                    lat: ret.seller.address.latLng.lat,
                    lng: ret.seller.address.latLng.lng,
                },
            },
            name: ret.seller.name,
            email: ret.seller.email,
            nick: ret.seller.nick,
            document: ret.seller.document,
            phone: ret.seller.phone,
        },
        createdAt: new Date(ret.createdAt + "Z"),
        orderProducts: ret.orderProducts.map((e: any) => ({
            product: {
                id: e.product.id,
                images: e.product.images.map((e: any) => ({
                    thumb: {
                        width: e.thumb.width || 0,
                        height: e.thumb.height || 0,
                        url: e.thumb.url,
                    },
                    width: e.width || 0,
                    height: e.height || 0,
                    url: e.url,
                })),
                createdAt: new Date(e.product.createdAt + "Z"),
                storeId: e.product.storeId,
                liked: !!e.product.liked,
                title: e.product.title,
                minAmount: e.product.minAmount || 0,
                diameter: e.product.diameter || 0,
                description: e.product.description,
                price: e.product.price || 0,
                productionTime: e.product.productionTime,
                tags: e.product.tags.map((e: any) => ({
                    id: e.id,
                    name: e.name,
                })),
                category: e.product.category,
                slug: e.product.slug,
                height: e.product.height || 0,
                length: e.product.length || 0,
                width: e.product.width || 0,
                weight: e.product.weight || 0,
                freeShipping: !!e.product.freeShipping,
            },
            amount: e.amount || 0,
        })),
        paymentMethod: ret.paymentMethod,
        status: ret.status,
        statusList: ret.statusList.map((e: any) => ({
            status: e.status,
            changedAt: new Date(e.changedAt + "Z"),
        })),
        transport: {
            trackingCode: ret.transport.trackingCode === null || ret.transport.trackingCode === undefined ? null : ret.transport.trackingCode,
            sellerAddress: {
                zipcode: ret.transport.sellerAddress.zipcode,
                street: ret.transport.sellerAddress.street,
                streetNumber: ret.transport.sellerAddress.streetNumber,
                complementary: ret.transport.sellerAddress.complementary === null || ret.transport.sellerAddress.complementary === undefined ? null : ret.transport.sellerAddress.complementary,
                neighborhood: ret.transport.sellerAddress.neighborhood,
                city: ret.transport.sellerAddress.city,
                state: ret.transport.sellerAddress.state,
                country: ret.transport.sellerAddress.country,
                countryCode: ret.transport.sellerAddress.countryCode,
                latLng: {
                    lat: ret.transport.sellerAddress.latLng.lat,
                    lng: ret.transport.sellerAddress.latLng.lng,
                },
            },
            buyerAddress: {
                zipcode: ret.transport.buyerAddress.zipcode,
                street: ret.transport.buyerAddress.street,
                streetNumber: ret.transport.buyerAddress.streetNumber,
                complementary: ret.transport.buyerAddress.complementary === null || ret.transport.buyerAddress.complementary === undefined ? null : ret.transport.buyerAddress.complementary,
                neighborhood: ret.transport.buyerAddress.neighborhood,
                city: ret.transport.buyerAddress.city,
                state: ret.transport.buyerAddress.state,
                country: ret.transport.buyerAddress.country,
                countryCode: ret.transport.buyerAddress.countryCode,
                latLng: {
                    lat: ret.transport.buyerAddress.latLng.lat,
                    lng: ret.transport.buyerAddress.latLng.lng,
                },
            },
            total: ret.transport.total || 0,
        },
        payment: {
            card: ret.payment.card === null || ret.payment.card === undefined ? null : {
                id: ret.payment.card.id,
                lastDigits: ret.payment.card.lastDigits,
                exp: ret.payment.card.exp,
                brand: ret.payment.card.brand,
                ownerId: ret.payment.card.ownerId,
            },
            total: ret.payment.total || 0,
        },
    };
}

export async function getProduct(productId: string, progress?: (progress: number) => void): Promise<Product> {
    const args = {
        productId: productId,
    };
    const ret = await makeRequest({name: "getProduct", args, progress});
    return {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        storeId: ret.storeId,
        liked: !!ret.liked,
        title: ret.title,
        minAmount: ret.minAmount || 0,
        diameter: ret.diameter || 0,
        description: ret.description,
        price: ret.price || 0,
        productionTime: ret.productionTime,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        category: ret.category,
        slug: ret.slug,
        height: ret.height || 0,
        length: ret.length || 0,
        width: ret.width || 0,
        weight: ret.weight || 0,
        freeShipping: !!ret.freeShipping,
    };
}

export async function getProducts(pageOffset: number, progress?: (progress: number) => void): Promise<Product[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getProducts", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        images: e.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(e.createdAt + "Z"),
        storeId: e.storeId,
        liked: !!e.liked,
        title: e.title,
        minAmount: e.minAmount || 0,
        diameter: e.diameter || 0,
        description: e.description,
        price: e.price || 0,
        productionTime: e.productionTime,
        tags: e.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        category: e.category,
        slug: e.slug,
        height: e.height || 0,
        length: e.length || 0,
        width: e.width || 0,
        weight: e.weight || 0,
        freeShipping: !!e.freeShipping,
    }));
}

export async function getTotalNumberOfProducts(progress?: (progress: number) => void): Promise<number> {
    const ret = await makeRequest({name: "getTotalNumberOfProducts", args: {}, progress});
    return ret || 0;
}

export async function createProduct(newProduct: NewProduct, storeId: string, progress?: (progress: number) => void): Promise<Product | null> {
    const args = {
        newProduct: {
            images: newProduct.images.map(e => ({
                bytes: e.bytes === null || e.bytes === undefined ? null : e.bytes.toString("base64"),
                image: e.image === null || e.image === undefined ? null : {
                    thumb: {
                        width: e.image.thumb.width || 0,
                        height: e.image.thumb.height || 0,
                        url: e.image.thumb.url,
                    },
                    width: e.image.width || 0,
                    height: e.image.height || 0,
                    url: e.image.url,
                },
            })),
            title: newProduct.title,
            minAmount: newProduct.minAmount || 0,
            diameter: newProduct.diameter || 0,
            description: newProduct.description,
            price: newProduct.price || 0,
            productionTime: newProduct.productionTime,
            tags: newProduct.tags.map(e => ({
                id: e.id,
                name: e.name,
            })),
            category: newProduct.category,
            slug: newProduct.slug,
            height: newProduct.height || 0,
            length: newProduct.length || 0,
            width: newProduct.width || 0,
            weight: newProduct.weight || 0,
            freeShipping: !!newProduct.freeShipping,
        },
        storeId: storeId,
    };
    const ret = await makeRequest({name: "createProduct", args, progress});
    return ret === null || ret === undefined ? null : {
        id: ret.id,
        images: ret.images.map((e: any) => ({
            thumb: {
                width: e.thumb.width || 0,
                height: e.thumb.height || 0,
                url: e.thumb.url,
            },
            width: e.width || 0,
            height: e.height || 0,
            url: e.url,
        })),
        createdAt: new Date(ret.createdAt + "Z"),
        storeId: ret.storeId,
        liked: !!ret.liked,
        title: ret.title,
        minAmount: ret.minAmount || 0,
        diameter: ret.diameter || 0,
        description: ret.description,
        price: ret.price || 0,
        productionTime: ret.productionTime,
        tags: ret.tags.map((e: any) => ({
            id: e.id,
            name: e.name,
        })),
        category: ret.category,
        slug: ret.slug,
        height: ret.height || 0,
        length: ret.length || 0,
        width: ret.width || 0,
        weight: ret.weight || 0,
        freeShipping: !!ret.freeShipping,
    };
}

export async function getSection(productId: string, progress?: (progress: number) => void): Promise<Section> {
    const args = {
        productId: productId,
    };
    const ret = await makeRequest({name: "getSection", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        filter: {
            storeId: ret.filter.storeId === null || ret.filter.storeId === undefined ? null : ret.filter.storeId,
            category: ret.filter.category === null || ret.filter.category === undefined ? null : ret.filter.category,
            tags: ret.filter.tags === null || ret.filter.tags === undefined ? null : ret.filter.tags.map((e: any) => ({
                id: e.id,
                name: e.name,
            })),
            text: ret.filter.text === null || ret.filter.text === undefined ? null : ret.filter.text,
            order: ret.filter.order === null || ret.filter.order === undefined ? null : ret.filter.order,
        },
    };
}

export async function getSections(pageOffset: number, progress?: (progress: number) => void): Promise<Section[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getSections", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        filter: {
            storeId: e.filter.storeId === null || e.filter.storeId === undefined ? null : e.filter.storeId,
            category: e.filter.category === null || e.filter.category === undefined ? null : e.filter.category,
            tags: e.filter.tags === null || e.filter.tags === undefined ? null : e.filter.tags.map((e: any) => ({
                id: e.id,
                name: e.name,
            })),
            text: e.filter.text === null || e.filter.text === undefined ? null : e.filter.text,
            order: e.filter.order === null || e.filter.order === undefined ? null : e.filter.order,
        },
    }));
}

export async function createSection(newSection: NewSection, progress?: (progress: number) => void): Promise<Section> {
    const args = {
        newSection: {
            name: newSection.name,
            filter: {
                storeId: newSection.filter.storeId === null || newSection.filter.storeId === undefined ? null : newSection.filter.storeId,
                category: newSection.filter.category === null || newSection.filter.category === undefined ? null : newSection.filter.category,
                tags: newSection.filter.tags === null || newSection.filter.tags === undefined ? null : newSection.filter.tags.map(e => ({
                    id: e.id,
                    name: e.name,
                })),
                text: newSection.filter.text === null || newSection.filter.text === undefined ? null : newSection.filter.text,
                order: newSection.filter.order === null || newSection.filter.order === undefined ? null : newSection.filter.order,
            },
        },
    };
    const ret = await makeRequest({name: "createSection", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        filter: {
            storeId: ret.filter.storeId === null || ret.filter.storeId === undefined ? null : ret.filter.storeId,
            category: ret.filter.category === null || ret.filter.category === undefined ? null : ret.filter.category,
            tags: ret.filter.tags === null || ret.filter.tags === undefined ? null : ret.filter.tags.map((e: any) => ({
                id: e.id,
                name: e.name,
            })),
            text: ret.filter.text === null || ret.filter.text === undefined ? null : ret.filter.text,
            order: ret.filter.order === null || ret.filter.order === undefined ? null : ret.filter.order,
        },
    };
}

export async function editSection(editSection: EditSection, sectionId: string, progress?: (progress: number) => void): Promise<Section> {
    const args = {
        editSection: {
            name: editSection.name,
            filter: {
                storeId: editSection.filter.storeId === null || editSection.filter.storeId === undefined ? null : editSection.filter.storeId,
                category: editSection.filter.category === null || editSection.filter.category === undefined ? null : editSection.filter.category,
                tags: editSection.filter.tags === null || editSection.filter.tags === undefined ? null : editSection.filter.tags.map(e => ({
                    id: e.id,
                    name: e.name,
                })),
                text: editSection.filter.text === null || editSection.filter.text === undefined ? null : editSection.filter.text,
                order: editSection.filter.order === null || editSection.filter.order === undefined ? null : editSection.filter.order,
            },
        },
        sectionId: sectionId,
    };
    const ret = await makeRequest({name: "editSection", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        filter: {
            storeId: ret.filter.storeId === null || ret.filter.storeId === undefined ? null : ret.filter.storeId,
            category: ret.filter.category === null || ret.filter.category === undefined ? null : ret.filter.category,
            tags: ret.filter.tags === null || ret.filter.tags === undefined ? null : ret.filter.tags.map((e: any) => ({
                id: e.id,
                name: e.name,
            })),
            text: ret.filter.text === null || ret.filter.text === undefined ? null : ret.filter.text,
            order: ret.filter.order === null || ret.filter.order === undefined ? null : ret.filter.order,
        },
    };
}

export async function deleteSection(sectionId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        sectionId: sectionId,
    };
    await makeRequest({name: "deleteSection", args, progress});
    return undefined;
}

export async function getTag(userId: string, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "getTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function getTags(pageOffset: number, progress?: (progress: number) => void): Promise<Tag[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getTags", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
    }));
}

export async function createTag(newTag: NewTag, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        newTag: {
            name: newTag.name,
        },
    };
    const ret = await makeRequest({name: "createTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function editTag(tagId: string, editedTag: EditTag, progress?: (progress: number) => void): Promise<Tag> {
    const args = {
        tagId: tagId,
        editedTag: {
            name: editedTag.name,
        },
    };
    const ret = await makeRequest({name: "editTag", args, progress});
    return {
        id: ret.id,
        name: ret.name,
    };
}

export async function deleteTag(tagId: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        tagId: tagId,
    };
    await makeRequest({name: "deleteTag", args, progress});
    return undefined;
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
