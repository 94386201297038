// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Routes
import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";
import EditView from "./EditView";

interface IProps { }

const Banners: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/faq"
				component={TableView}
			/>
			<Route
				exact={true}
				path="/dashboard/faq/create"
				component={CreateView}
			/>
			<Route
				exact={true}
				path="/dashboard/faq/edit/:id"
				component={EditView}
			/>
		</Switch>
	);
};

export default observer(Banners);
