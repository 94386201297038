// MARK: MOBX
import { action, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";

// MARK: Stores
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;
	public imageController: FileStore;
	public mobileImageController: FileStore;

	@observable public loading = false;
	@observable public error: string | null = null;

	public formController = new FormStore({
		title: "",
		description: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		this.imageController = new FileStore(uiStore, "image");
		this.mobileImageController = new FileStore(uiStore, "image");
	}

	@action
	public createBanner = async () => {
		const fields = this.formController.getValues();

		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			this.loading = true;

			await API.createFaq({
				...fields,
			});

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.successBanner, "success");
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};
}
