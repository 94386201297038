// MARK: mobx
import { action } from "mobx";

// MARK: Modules
import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";

// MARK: Store
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";

interface IPushAble {
	push: (path: string) => void;
}

export default class UserDetailStore extends ModelStore<api.UserDetails> {
	protected history: IPushAble;
	constructor(id: string, uiStore: UIStore, history: IPushAble) {
		super(id, uiStore);
		this.history = history;
	}

	protected getModel(id: string) {
		return api.getUser(id);
	}

	@action
	public showDeleteDialog = (id: string) => {
		this.uiStore.showDialog(
			{
				message: Strings.dialogs.delete.message,
				title: Strings.dialogs.delete.title,
			},
			async () => {
				try {
					await api.deleteUser(id);
					this.uiStore.showSnackbar(Strings.dialogs.operationHasBeenASuccess);
					this.history.push("/dashboard/users");
				} catch (error) {
					this.uiStore.showSnackbar(Strings.error.default);
				}
			},
		);
	};
}
