// Mobx
import { observable, action } from "mobx";

// Tools
import FileUploader, { FileType, IImage } from "./FileUploader";

// Modules
import API from "../../modules/API";
import * as Files from "./Files";

// Stores
import UIStore from "../UIStore";

export default class FileStore {
	@observable public progress: number = 0;
	@observable public loading: boolean = false;
	@observable public fileUploaders: FileUploader[] = [];
	@observable public fileType: FileType;

	private uiStore: UIStore;

	constructor(
		uiStore: UIStore,
		fileType: FileType,
		files?: IImage | Array<IImage | API.File>,
	) {
		this.uiStore = uiStore;
		this.fileType = fileType;
		if (files) {
			Array.isArray(files)
				? (this.fileUploaders = files?.map((file) =>
					FileUploader.createUploaded(file, this.fileType),
				))
				: (this.fileUploaders = [
					FileUploader.createUploaded(files, this.fileType),
				]);
		} else {
			this.fileUploaders = [];
		}
	}

	@action
	public getUncertainfiedFile = () => {
		return this.fileUploaders[0]
			? this.fileUploaders[0].getSendableFile()
			: null;
	};

	@action
	public getUncertainfiedMultipleFiles = () => {
		return this.fileUploaders.map((uploader) => uploader.getSendableImage());
	};

	@action
	public getUncertainfiedImage = () => {
		return this.fileUploaders[0]
			? this.fileUploaders[0].getSendableImage()
			: null;
	};

	@action
	public getUncertainfiedMultipleImage = () => {
		return this.fileUploaders.map((uploader) => uploader.getSendableImage());
	};

	@action
	public retryUpload = async (fileId: string) => {
		try {
			for (const uploader of this.fileUploaders) {
				if (uploader.id === fileId) {
					await uploader.uploadFile();
				}
			}
		} catch (error) {
			this.uiStore.showSnackbar(error.message);
		}
	};

	@action
	public clear = async (fileId: string) => {
		try {
			this.fileUploaders = this.fileUploaders.filter(
				(uploader) => uploader.id !== fileId,
			);
		} catch (error) {
			this.uiStore.showSnackbar(error.message);
		}
	};

	@action
	public openSelectImageDialog = async (
		multiple: boolean = false,
		isHighQuality: boolean = false,
	) => {
		if (!multiple) {
			Files.openInputUploadDialog(

				async (file) => {
					this.fileUploaders[0] = new FileUploader(file);
					await this.fileUploaders[0].uploadImage(isHighQuality);
				},
				(error) => {
					throw new Error(error);
				},
				"image/*",
			);
		} else {
			Files.openMultipleInputDialog(
				async (imageFiles) => {
					this.fileUploaders = this.fileUploaders.concat(
						imageFiles.map((imageFile) => {
							return new FileUploader(imageFile);
						}),
					);

					await Promise.all(
						this.fileUploaders.map(async (uploader) => {
							if (uploader.file) {
								return await uploader.uploadImage(isHighQuality);
							}
						}),
					);
				},
				(errMsg) => {
					throw new Error(errMsg);
				},
				"image/*",
			);
		}
	};

	@action
	public openSelectMultiplesDialogs = async (multiple: boolean = false) => {
		if (!multiple) {
			Files.openInputUploadDialog(
				async (file) => {
					this.fileUploaders[0] = new FileUploader(file);
					await this.fileUploaders[0].uploadFile();
				},
				(error) => {
					throw new Error(error);
				},
				"*",
			);
		} else {
			Files.openMultipleInputDialog(
				async (files) => {
					this.fileUploaders = this.fileUploaders.concat(
						files.map((file) => {
							return new FileUploader(file);
						}),
					);
					await Promise.all(
						this.fileUploaders.map(async (uploader) => {
							if (uploader.file) {
								return await uploader.uploadFile();
							}
						}),
					);
				},
				(errMsg) => this.uiStore.showSnackbar(errMsg),
				"*",
			);
		}
	};

	public getUploader = () => {
		return this.fileUploaders;
	};

	public toCase = (
		multiple: boolean = false,
		isHighQuality: boolean = false,
	) => {
		return {
			onAddItem:
				this.fileType === "file"
					? () => this.openSelectMultiplesDialogs(multiple)
					: () => this.openSelectImageDialog(multiple, isHighQuality),

			onRemoveItem: this.clear,
			onReloadItem: this.retryUpload,
			fileUploaders: this.getUploader(),
		};
	};
}
