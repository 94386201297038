// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Routes
import { Switch, Route } from "react-router-dom";

// MARK: Components
import TableView from "./TableView";
import EditorView from "./EditorView";
import CreateView from "./CreateView";

interface IProps {}

const Products: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route exact={true} path="/dashboard/products" component={TableView} />
			<Route path="/dashboard/products/edit/:id" component={EditorView} />

			<Route path="/dashboard/products/create" component={CreateView} />
		</Switch>
	);
};

export default observer(Products);
