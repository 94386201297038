import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 900,
	},
	fields: {
		maxWidth: 300,
		width: "100%",
		margin: theme.spacing(2),
	},
	fileCases: {
		margin: theme.spacing(2),
		marginTop: theme.spacing(5),
		maxWidth: 600,
	},
}));
