// Mark: React
import React from "react";
// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
// Mark: React-router
import { useHistory, useParams } from "react-router-dom";

// Mark: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// Mark: Modules
import Strings from "../../../../modules/Strings";

// Mark: Components
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { DetailRow } from "../../../../components/DetailRow";
import CardForm from "../../../../components/CardForm";

// Mark: interface
interface IParams {
	id: string;
}

// Mark: implementation
const DetailsView: React.FC = () => {
	const { id } = useParams<IParams>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));

	if (!store.model) {
		return null;
	}

	return (
		<CardForm
			title={Strings.admin.details.title}
			actionsFooter={
				<Button startIcon={<Delete />} onClick={store.showDeleteDialog}>
					{Strings.actions.delete}
				</Button>
			}
		>
			<DetailRow label={Strings.fields.name} value={store.model.name} />
			<DetailRow label={Strings.fields.email} value={store.model.email} />
		</CardForm>
	);
};

export default observer(DetailsView);
