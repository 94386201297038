// MARK: React
import React from "react";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Routes
import { Switch, Route } from "react-router-dom";

import TableView from "./TableView";
import CreateView from "./CreateView";
import EditView from "./EditView";
import DetailsView from "./DetailsView";

interface IProps { }

const Banners: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/banners"
				component={TableView}
			/>
			<Route
				exact={true}
				path="/dashboard/banners/create"
				component={CreateView}
			/>
			<Route
				exact={true}
				path="/dashboard/banners/edit/:id"
				component={EditView}
			/>
			<Route
				exact={true}
				path="/dashboard/banners/details/:id"
				component={DetailsView}
			/>
		</Switch>
	);
};

export default observer(Banners);
