// MARK: React
import React, { useCallback } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Libs
import { TableCell, TableRow, IconButton, Button } from "@material-ui/core";
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	Add as AddIcon,
} from "@material-ui/icons";

// MARK: Store
import TableSectionStore from "./store";
import { useGlobalStore } from "../../../context";

// Mark: Router
import { useHistory } from "react-router";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(
		() => new TableSectionStore(uiStore, history),
	);

	const pushToEdit = useCallback(
		(id: string) => history.push(`/dashboard/sections/edit/${id}`),
		[history],
	);

	const handleDeleteSection = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.deleteItem.message,
					title: Strings.dialogs.deleteItem.title,
				},
				async () => await tableStore.deleteSection(id),
			);
		},
		[uiStore, tableStore],
	);

	const pushToCreate = () => history.push(`sections/create`);

	return (
		<PageTableContainer>
			<SimpleTable
				loading={tableStore.loading}
				title={Strings.sections.table.title}
				pagination={tableStore.pagination}
				rows={tableStore.items}
				headers={[Strings.fields.name, Strings.components.table.editAndDelete]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>
							<IconButton
								onClick={() => {
									pushToEdit(item.id);
								}}
							>
								<EditIcon />
							</IconButton>
							<IconButton
								onClick={() => {
									handleDeleteSection(item.id);
								}}
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					(
						<Button startIcon={<AddIcon />} onClick={pushToCreate}>
							{Strings.actions.create}
						</Button>
					)
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
