// MARK: React
import * as React from "react";

// MARK: Libs
import { observer } from "mobx-react";

// MARK: Components
import { Box, Chip } from "@material-ui/core";

interface IProps<DataItemType> {
	chosenOptions: DataItemType[];
	objectProp?: string;
	removeClient: (index: number) => void;
	enumTranslate?: object;
}

@observer
export default class ChipList<DataItemType> extends React.Component<
	IProps<DataItemType>
> {
	public render() {
		const {
			chosenOptions,
			objectProp,
			removeClient,
			enumTranslate,
		} = this.props;

		const labelChipsCase = (value: DataItemType) => {
			if (typeof value === "string" && enumTranslate) {
				return enumTranslate[value as string];
			} else {
				return value;
			}
		};

		return (
			<Box
				style={{
					display: "flex",
					flexWrap: "wrap",
					maxWidth: "110px",
					padding: 5,
				}}
			>
				{chosenOptions.map((value, index) => (
					<Chip
						key={index}
						variant="outlined"
						label={objectProp ? value[objectProp] : labelChipsCase(value)}
						style={{ margin: 1 }}
						onDelete={() => removeClient(index)}
					/>
				))}
			</Box>
		);
	}
}
