import React from "react";
import useStyles from "./styles";

// MARK: Mobx
import { observer } from "mobx-react-lite";

// MARK: Layout
import { MainLayout } from "../../layouts/MainLayout";
import {
	Card,
	CardContent,
	CardActions,
	Paper,
	AppBar,
} from "@material-ui/core";

// MARK: Components
import Typography from "@material-ui/core/Typography";
import CircularProgress from "../../components/CircularProgress";
import TextField from "../../components/TextField";
import Button from "../../components/Button";

// Resources
import Strings from "../../modules/Strings";

// MARK: Hooks
import { useHistory, useParams } from "react-router";

// MARK: Stores
import { useGlobalStore } from "../context";

export const Recovery = observer(() => {
	const { token } = useParams<{ token: string }>();
	const { authStore } = useGlobalStore();
	const classes = useStyles();
	const history = useHistory();

	React.useEffect(() => {
		authStore.setToken(token);
	}, []);

	return (
		<>
			<AppBar position="static" className={classes.appBar}>
				<Typography className={classes.pageTitle} variant="h6">
					{Strings.pageLogin.pagetitle}
				</Typography>
			</AppBar>
			{authStore.loading ? (
				<CircularProgress />
			) : (
					<>
						{authStore.receivedToken && authStore.receivedToken.length > 0 ? (
							<Paper className={classes.pageContent} elevation={0}>
								<Card className={classes.card}>
									<CardContent>
										<Typography className={classes.recoveryTitle} variant="h5">
											{Strings.recoveryPage.newAccess}
										</Typography>
										<TextField
											inputProps={{ className: classes.input }}
											InputLabelProps={{ className: classes.label }}
											{...authStore.formController.field("newPassword")}
											label={Strings.recoveryPage.newPassword}
											type="password"
											name="newPassword"
											disabled={authStore.loading}
										/>
										<TextField
											inputProps={{ className: classes.input }}
											InputLabelProps={{ className: classes.label }}
											{...authStore.formController.field("confirmNewPassword")}
											label={Strings.recoveryPage.confirmNewPassword}
											type="password"
											name="confirmNewPassword"
											disabled={authStore.loading}
										/>
										<CardActions>
											<Button
												variant="outlined"
												className={classes.loginButton}
												onClick={() => authStore.resetPassword(() => history.push("/dashboard"))}
											>
												{Strings.recoveryPage.newPasswordButton}
											</Button>
										</CardActions>
									</CardContent>
								</Card>
							</Paper>
						) : (
								<Paper className={classes.pageContent} elevation={0}>
									<Card className={classes.card}>
										<CardContent>
											<Typography className={classes.recoveryTitle} variant="h5">
												{Strings.recoveryPage.title}
											</Typography>
											<TextField
												inputProps={{ className: classes.input }}
												InputLabelProps={{ className: classes.label }}
												{...authStore.formController.field("email")}
												label={Strings.fields.email}
												disabled={authStore.loading}
												type="email"
												name="email"
											/>
											<CardActions>
												<Button
													variant="outlined"
													className={classes.loginButton}
													onClick={() => authStore.sendNewPassword(() => history.push("/login"))}
												>
													{Strings.recoveryPage.recoverPasswordButton}
												</Button>
												<Button
													variant="contained"
													className={classes.backButton}
													onClick={() => history.push("./login")}
												>
													{Strings.recoveryPage.backButton}
												</Button>
											</CardActions>
										</CardContent>
									</Card>
								</Paper>
							)}
					</>
				)}
		</>
	);
});
