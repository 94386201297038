import {
	Home as HomeIcon,
	Person as UserIcon,
	SupervisorAccount as AdminIcon,
	LocalOffer as TagIcon,
	ChromeReaderMode as BannerIcon,
	LocalMall as ProductIcon,
	Bookmarks as BookmarksIcon,
	AssignmentLate as AssignmentLateIcon,
	HelpSharp as FaqIcon,
} from "@material-ui/icons";
import Strings from "../modules/Strings";
export function useMainRoutes() {
	return [
		{
			Icon: HomeIcon,
			text: Strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: AdminIcon,
			text: Strings.nav.adminUsers,
			path: "/dashboard/adminUsers",
		},
		{
			Icon: UserIcon,
			text: Strings.nav.users,
			path: "/dashboard/users",
		},
		{
			Icon: TagIcon,
			text: Strings.nav.tags,
			path: "/dashboard/tags",
		},
		{
			Icon: BannerIcon,
			text: Strings.nav.banner,
			path: "/dashboard/banners",
		},
		{
			Icon: ProductIcon,
			text: Strings.nav.products,
			path: "/dashboard/products",
		},
		{
			Icon: BookmarksIcon,
			text: Strings.nav.sections,
			path: "/dashboard/sections",
		},
		{
			Icon: AssignmentLateIcon,
			text: Strings.nav.order,
			path: "/dashboard/orders",
		},
		{
			Icon: FaqIcon,
			text: "faq",
			path: "/dashboard/faq",
		},
	];
}
