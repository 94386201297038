import API from "../../modules/API";
import { observable, action, computed, toJS } from "mobx";
import * as Files from "./Files";

import {
	uploadImage,
	ImageFormat,
	uploadRawImage,
} from "@startapp/laira-admin-api";

type FileUploaderStatus =
	| "failed"
	| "loading"
	| "success"
	| "uploading"
	| "created";

export interface IImage {
	width: number;
	height: number;
	url: string;
	thumb: {
		width: number;
		height: number;
		url: string;
	};
}

export type FileType = "image" | "file";

export default class FileUploader {
	@observable
	public status: FileUploaderStatus = "created";

	@observable
	public FileType: FileType = "file";

	@observable
	public uploadedFile: IImage | API.File;

	@observable
	public id: string;

	@observable
	public name: string;

	@observable
	public onUpload: boolean = false;

	@observable
	public progress: number = 0;

	@observable
	public filePath: string = "";

	@observable
	public file: File;

	@computed
	public get src() {
		if (this.uploadedFile) {
			return this.uploadedFile.url;
		} else {
			return this.filePath;
		}
	}

	@computed
	public get uploadedUncertainFile() {
		return this.uploadedFile
			? { file: this.uploadedFile, bytes: null }
			: null;
	}

	private reasonableUploadPercentage: number = 5;

	public static createUploaded(file: IImage | API.File, fileType: FileType) {
		const uploaded = new FileUploader();

		"width" in file
			? (uploaded.name = uploaded.setUploadedName(file.url))
			: (uploaded.name = uploaded.setUploadedName(file.name));

		uploaded.uploadedFile = file;
		uploaded.status = "success";
		uploaded.filePath = file.url;
		uploaded.id = uploaded.filePath;
		uploaded.FileType = uploaded.setFile(uploaded.name);

		return uploaded;
	}
	constructor(file?: File) {
		if (file) {
			this.file = file;
			this.name = file.name;
			this.FileType = this.setFile(this.name);
			this.filePath = URL.createObjectURL(file);
			this.id = this.filePath;
		}
	}
	@action public setUploadedName = (name: string) => {
		return name.substr(name.lastIndexOf("/") + 1);
	};

	@action
	public setFile = (name: string) => {
		const format = name.substr(name.lastIndexOf(".") + 1);
		if (
			format === ImageFormat.png ||
			format === ImageFormat.jpeg ||
			format === "jpg"
		) {
			return "image";
		} else {
			return "file";
		}
	};

	@action
	public uploadFile = async () => {
		this.status = "loading";
		await Files.craftFileBuffer(this.file, async (buffer) => {
			try {
				this.uploadedFile = await API.uploadFile(
					{
						bytes: buffer,
						name: this.name,
					},
					this.updateProgress,
				);
				this.status = "success";
			} catch (error) {
				this.status = "failed";
				this.progress = 0;
			}
		});
	};

	@action
	public uploadImage = async (
		isHighQuality: boolean = false,
		format?: ImageFormat,
	) => {
		this.status = "loading";
		await Files.craftImageBuffer(
			this.file,
			isHighQuality ? 5000 : 1200,
			async (buffer) => {
				try {
					if (isHighQuality) {
						this.uploadedFile = await uploadRawImage(
							buffer,
							format || ImageFormat.png,
							this.updateProgress,
						);
					} else {
						this.uploadedFile = await uploadImage(
							buffer,
							format || ImageFormat.png,
							null,
							this.updateProgress,
						);
					}
					this.status = "success";
				} catch (error) {
					console.log(error);
					this.status = "failed";
					this.progress = 0;
				}
			},
			isHighQuality,
		);
	};

	@action
	private updateProgress = (progress: number) => {
		if (
			progress > this.reasonableUploadPercentage &&
			this.status !== "failed"
		) {
			this.status = "uploading";
		}
		this.progress = progress;
	};

	@action
	public getSendableFile() {
		return {
			fileData: null,
			file: this.uploadedFile,
		} as API.UncertainFile;
	}

	@action
	public getSendableImage() {
		return {
			bytes: null,
			image: this.uploadedFile || null,
			crop: null,
		} as API.UncertainImage;
	}
}
