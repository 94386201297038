import { action, runInAction } from "mobx";

// Modules
import { Errors } from "../../../../modules/Errors";
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";

// Interfaces
import { IRouterPusher } from "../../../../interfaces/IRouter";

// Stores
import ModelStore from "../../../../stores/ModelStore";
import FileStore from "../../../../stores/FileStore";
import UIStore from "../../../../stores/UIStore";

// Mark: implementation
export default class Store extends ModelStore<API.Banner> {
	private router: IRouterPusher;
	public imageController: FileStore;
	public imageMobileController: FileStore;
	protected uivis = UIStore;

	constructor(id: string, uiStore: UIStore, router: IRouterPusher) {
		super(id, uiStore);
		this.router = router;
		this.imageController = new FileStore(uiStore, "image");
		this.imageMobileController = new FileStore(uiStore, "image");
	}

	@action
	protected async getModel(id: string) {
		return await API.getBanner(id);
	}

	protected afterModelFetch(model: API.Banner) {
		this.imageController = new FileStore(this.uiStore, "image", model.image);
		this.imageMobileController = new FileStore(
			this.uiStore,
			"image",
			model.mobileImage,
		);
	}
	@action
	private deleteBanner = async (id: string) => {
		try {
			if (this.loading) {
				throw Error(Strings.error.stillLoading);
			}

			await API.deleteBanner(id);
			this.uiStore.showSuccessSnackbar();
			this.router.push("/dashboard/adminUsers");
		} catch (e) {
			this.error = Errors.handleError(e);
			this.uiStore.showSnackbar(this.error);
		} finally {
			runInAction(() => (this.loading = false));
		}
	};

	@action
	public showDeleteDialog = () => {
		this.uiStore.showDialog(Strings.dialogs.delete, () =>
			this.deleteBanner(this.id),
		);
	};
}
