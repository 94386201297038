import React, { memo } from "react";
import { InputAdornment } from "@material-ui/core";
import NumberFormat, { NumberFormatProps } from "react-number-format";

interface INumberFormatCustomProps extends NumberFormatProps {
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
	customInput: React.ComponentType<any>;
}

const InputNumberCurrencyFormat: React.FC<INumberFormatCustomProps> = (
	props,
) => {
	const { onChange: externalOnchange, ...other } = props;

	const currencyBrl = (
		cents: number,
		options?: { showSymbol?: boolean; withCents?: boolean },
	) => {
		const withCents =
			options === undefined ||
			options.withCents === undefined ||
			options.withCents;
		const showSymbol =
			options === undefined ||
			options.showSymbol === undefined ||
			options.showSymbol;
		const numeroTokens: string[] = `${
			withCents ? (cents / 100).toFixed(2) : Math.round(cents / 100)
		}`.split(".");
		numeroTokens[0] = `${showSymbol ? "R$ " : ""}${numeroTokens[0]
			.split(/(?=(?:...)*$)/)
			.join(".")}`;
		return numeroTokens.join(",");
	};

	return (
		<NumberFormat
			{...other}
			onChange={(e) => {
				externalOnchange(e);
			}}
			format={(value: string) => currencyBrl(Number(value), { showSymbol: false })}
			InputProps={{
				startAdornment: <InputAdornment position="start">R$</InputAdornment>,
			}}
		/>
	);
};

export default memo(InputNumberCurrencyFormat);
