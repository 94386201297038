import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 900,
	},
	fields: {
		maxWidth: 300,
		width: "100%",
		margin: theme.spacing(2),
	},
	noneOptions: {
		display: "none",
	},
	chipsBox: {
		display: "flex",
		flexWrap: "wrap",
		maxWidth: "300px",
	},
	chip: {
		margin: 4,
	},
	autoComplete: {
		maxWidth: 300,
		width: "100%",
	},
}));
