// MARK: MOBX
import { action, computed, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

// MARK: Stores
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";

import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;

	@observable public loading = false;
	@observable public error: string | null = null;

	@observable public text: string | null;

	@observable public selectChipTag: SelectChipStore<API.Tag>;

	@observable public selectCategory: API.Category;

	public formController = new FormStore({
		name: "",
		text: "",
		idStore: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.fetchModel();
		this.uiStore = uiStore;
		this.history = history;
	}

	public setCategory = (value: string) => {
		this.selectCategory = API.Category[value];
	};
	@action
	private fetchModel = async () => {
		try {
			this.loading = true;
			this.selectChipTag = new SelectChipStore<API.Tag>(
				await API.getTags(0),
				this.uiStore,
			);
		} catch (e) {
			this.error = Errors.handleError(e);
		} finally {
			this.loading = false;
		}
	};

	@action
	public getTags = async () => {
		return await API.getTags(0);
	};

	@action.bound
	public createSection = async () => {
		const fields = this.formController.getValues();

		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			this.loading = true;

			await API.createSection({
				name: fields.name,
				filter: {
					text: fields.text,
					tags: this.selectChipTag.chosenOptions,
					category: this.selectCategory,
					storeId: fields.idStore.length > 0 ? fields.idStore : null,
					order: null,
				},
			});

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.successSection, "success");
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get stringsOptions() {
		return {
			categories: [
				API.Category.clothes,
				API.Category.accessories,
				API.Category.sport,
				API.Category.toys,
				API.Category.party,
				API.Category.educational,
				API.Category.health,
				API.Category.customized,
			],
		};
	}
}
