// MARK: React
import React, { useCallback, useMemo } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";
import api from "../../../../modules/API";

// MARK: Libs
import { Box, Button, IconButton, TableCell, TableRow } from "@material-ui/core";
import {
	Delete as DeleteIcon,
	Edit as EditIcon,
	Add as AddIcon,
} from "@material-ui/icons";

// MARK: Store
import ProductTableStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useHistory } from "react-router-dom";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import { Formatting } from "../../../../modules/Formatting";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();

	const tableStore = useLocalStore(
		() => new ProductTableStore(uiStore, history),
	);

	const pushToCreate = useCallback(() => history.push(`products/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`products/edit/${id}`),
		[history],
	);

	const handleDeleteProduct = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.deleteItem.message,
					title: Strings.dialogs.deleteItem.title,
				},
				() => tableStore.deleteProduct(id),
			);
		},
		[uiStore, tableStore],
	);

	const formatTableValues = useMemo(() => {
		return tableStore.items.map((item) => ({
			...item,
			price: Formatting.currencyForBR(item.price),
			title: item.title.toLocaleLowerCase(),
		}));
	}, [tableStore.items]);

	return (
		<PageTableContainer>
			{tableStore.totalProduct && (
				<Box mb={1}>
					<p>
						{Strings.products.table.quantity}<span style={{fontWeight: "bold"}}>{tableStore.totalProduct}</span>
					</p>
				</Box>
			)}
			<SimpleTable
				loading={tableStore.loading}
				title={Strings.products.table.title}
				isHaveNexPage={tableStore.isHaveNextPage}
				pagination={tableStore.pagination}
				rows={formatTableValues}
				headers={[
					Strings.fields.id,
					Strings.fields.title,
					Strings.fields.price,
					Strings.fields.productionTime,
					Strings.components.table.editAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.title}</TableCell>
						<TableCell align={"center"}>{item.price}</TableCell>
						<TableCell align={"center"}>{item.productionTime}</TableCell>
						<TableCell align={"center"}>
							<IconButton onClick={() => pushToEdit(item.id)}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={() => handleDeleteProduct(item.id)}>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
