import LocalizedStrings from "localized-strings";
import API from "./API";

// MARK: Implementation
const Strings = new LocalizedStrings({
	ptBR: {
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
		},
		fields: {
			archive: "Arquivar",
			document: "Documentação",
			type: "Tipo",
			url: "Url",
			id: "Id",
			slug: "slug",
			image: "Imagem",
			minAmount: "Quantidade miníma",
			diameter: "Diâmetro",
			images: "Imagens",
			phone: "Telefone",
			price: "Preço",
			productionTime: "Tempo de produção",
			whatsapp: "Whatsapp",
			category: "Categoria",
			name: "Nome",
			nick: "Apelido",
			budgetRate: "Média de orçamento",
			documentType: "CNPJ/CPF",
			seeMore: "Veja mais",
			exit: "Sair",
			notRegistered: "Não cadastrado",
			save: "Salvar",
			email: "Email",
			agencyDV: "Dígito da Agência",
			agency: "Agência",
			bank: "Banco",
			account: "Conta",
			accountDV: "Dígito da Conta",
			text: "Texto",
			design: "Design",
			typeProject: "Tipo de projeto",
			message: "Mensagem",
			// tslint:disable-next-line:no-hardcoded-credentials
			password: "Senha",
			recovery: "Recuperar Senha",
			title: "Título",
			description: "Descrição",
			deadlineDate: "Data de Prazo",
			createdAt: "Criado em",
			block: "Data de bloqueio",
			user: "Usuário",
			seller: "Vendedor",
			height: "Altura",
			width: "Largura",
			lenght: "Comprimento",
			weight: "Peso",
			idStore: "ID da Loja",
			question: "Pergunta",
			answer: "Resposta",
		},
		dialogs: {
			deleteItem: {
				title: "Apagar Item",
				message: "Tem certeza que deseja apagar esse item?",
			},
			deleteContact: (name: string) => ({
				title: "Apagar Contato",
				message:
					"Tem certeza que deseja apagar o contato " +
					name +
					" ?, essa operação não pode ser revertida.",
			}),
			delete: {
				title: "Confirmação de exclusão",
				message: "Deseja mesmo excluir este usuário?",
			},
			cancelOrder: {
				title: "Confirmação de cancelamento",
				message: "Tem certeza que deseja cancelar e reembolsar o pedido?",
			},
			operationHasBeenASuccess: "Operação realizada com sucesso.",
		},
		imageCase: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		logoutDialog: {
			title: "Logout",
			message: "Tem certeza que deseja sair?",
		},
		loading: "Carregando",
		login: "Login",
		hiAdmin: "Olá, Administrador(a)",
		recoveryPage: {
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
		},
		components: {
			table: {
				noMoreResults: "Sem resultados",
				create: "Criar",
				delete: "Deletar",
				details: "Detalhes",
				edit: "Editar",
				export: "Exportar",
				accept: "Aceitar",
				reject: "Reject",
				fallbackData: "Não há dados correspondentes",
				editAndDelete: "Editar / Deletar",
				detailsAndDelete: "Detalhes / Deletar",
				detailsAndEdit: "Detalhes / Editar",
				blockAndEditAndDelete: "Editar / Bloquear / Deletar",
				detailsAndblockAndEditAndDelete:
					"Vizualizar / Editar / Bloquear / Deletar",
				detailsAndEditAndDelete: "Editar / Detalhes / Deletar",
				AcceptAndReject: "Aceitar / Recusar",
				AcceptAndRejectaandDetails: "Detalhes / Aceitar / Recusar ",
				Active: "Ativo",
				deletedSuccessfully: "Deletado com sucesso",

				title: {
					isActive: "Ativo atualmente, clique para desativar.",
					isNotActive: "Desativado atualmente, clique para ativar.",
					selectedColumns: "Colunas Selecionadas",
					availableColumns: "Colunas Disponíveis",
				},

				titleIcon: {
					openFilter: "Abrir Opções de Filtragem",
					exportCSV: "Exportar CSV",
					editTable: "Editar Tabela",
				},
			},
			datePicker: {
				format: "dd/MM/yyyy",
				invalidDate: "Data inválida",
			},
		},
		languages: {
			title: "Idiomas",
			ptBr: "Português",
			enUs: "Inglês",
		},
		admin: {
			table: {
				title: "Tabela de administradores",
			},
			details: {
				title: "Detalhes do administrador",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar administrador",
				createButton: "Criar",
			},
			edit: {
				title: "editar administrador",
			},
		},
		users: {
			table: {
				title: "Tabela de usuários",
				userActivate: "Usuário ativo",
				TypeUser: {
					title: "Tipo de Usuário",
					seller: "Vendedor",
					customer: "Cliente",
				},
			},
			details: {
				title: "Detalhes do usuário",
				header: { name: "Nome", email: "Email" },
			},
			create: {
				title: "Criar usuário",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => "Edição do Usuário " + userName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
			blockDialogUser: {
				title: (blockUser: boolean) =>
					`${blockUser ? "Bloquear" : "Desbloquear"} usuário`,
				message: (blockUser: boolean) =>
					`Tem certeza que deseja ${
						blockUser ? "bloquear" : "desbloquear"
					} este usuário?`,
			},
		},
		tags: {
			table: {
				title: "Tabela de tags",
			},
			create: {
				title: "Criar tags",
				createButton: "Criar",
			},
			edit: {
				title: (userName?: string) => "Edição da Tag " + userName,
				onClick: "A tag foi editado",
				saveChanges: "Salvar",
				success: (userName: string) => `${userName} modificado com sucesso!`,
			},
		},
		banners: {
			table: {
				title: "Tabela de banners",
			},
			create: {
				title: "Criar banner",
			},

			edit: {
				title: "Editar banner",
				success: (value?: string) => `Banner ${value} editado com sucesso !`,
				error: (value: string) => `O campo ${value} é obrigatório`,
				editButton: "Editar",
				create: {
					title: "Criar banner",
				},
			},
			details: {
				title: "Detalhes do banner",
			},
		},
		faq: {
			table: {
				title: "Tabela de Perguntas",
			},
			create: {
				title: "Criar Pergunta",
			},
			edit: {
				title: "Editar Faq",
				success: "Faq editado com sucesso !",
				error: (value: string) => `O campo ${value} é obrigatório`,
				editButton: "Editar",
				create: {
					title: "Criar faq",
				},
			},
		},
		orders: {
			details: {
				title: "Detalhes do pedido",
				seller: "Vendedor",
				cancel: "Cancelado!",
				orderNumber: "Número do pedido",
				product: "Produto",
				observations: "Observações",
				paymentMethod: "Método de pagamento",
				status: "Status do pedido",
				destination: "Destinatário",
				sellerAddress: "Remetente",
				totalPayment: "Total",
			},
			totalOrders: "Total de pedidos: ",
			title: "Tabela de Pedidos",
			header: [
				"ID",
				"Número do pedido",
				"Comprador",
				"Vendedor",
				"Forma de Pagamento",
				"Status",
				"Data do Pedido",
				"Vizualizar",
			],
		},
		products: {
			table: {
				title: "Tabela de produtos",
				quantity: "Total de produtos cadastrados: ",
			},
			helperText: {
				description: "Mínimo 30 caracteres",
				lenght: "Mínimo 15 cm",
				height: "Mínimo 1 cm",
				width: "Mínimo 10 cm",
				minAmount: "Mínimo 1",
			},
			create: {
				createProduct: "Criar Produto",
				editProduct: "Editar Produto",
				createButton: "Criar",
				categoriesField: "Categorias",
				categories: {
					clothes: "Roupas",
					accessories: "Acessórios",
					sport: "Esportes",
					toys: "Brinquedos",
					party: "Decoração",
					educational: "Educacional",
					health: "Saúde",
					customized: "Outros",
				},
			},
		},
		sections: {
			table: {
				title: "Tabela de seções",
			},
			editor: {
				title: "Editar Seção",
				onClick: "A tag foi editado",
				saveChanges: "Salvar",
				success: (name: string) => `${name} modificado com sucesso!`,
			},
			create: {
				title: "Criar seção",
				name: "Nome",
				text: "Texto",
				idStore: "idStore",
				categoriesField: "Categorias",
				orderField: "Pedidos",
				tagsField: "Tags",
				orders: {
					newer: "Recentes",
					older: "Antigos",
					popular: "Popular",
					mostSold: "Mais vendido",
				},
				categories: {
					clothes: "Roupas",
					accessories: "Acessórios",
					sport: "Esportes",
					toys: "Brinquedos",
					party: "Decoração",
					educational: "Educacional",
					health: "Saúde",
					customized: "Outros",
				},
			},
		},
		cancel: "Cancelar",
		confirm: "Confirmar",
		ok: "Ok",
		logo: {
			alt: "Logo Alt Aqui!",
			squared: "/logo_squared.png",
			horizontalWhite: "/logo_horizontal_white.png",
		},
		error: {
			needLongerText: (numOfChars: number) =>
				"Precisa ser maior que " + numOfChars + " caracteres.",
			invalidEmail: "Email inválido",
			invalidPhone: "Telefone inválido",
			stillLoading: "Ainda Carregando, aguarde.",
			nameIsEmpty: "O nome está vazio",
			default: "Houve um erro, tente novamente mais tarde.",
			audioError: "Para cadastrar um treino insira um audio.",
			emptyDate: "Houve um erro, data incorreta",
			mustWaitWhileStillLoading: "Ainda em carregamento, aguarde um momento.",
			missingImage: "É necessario anexar a imagem",
			missingClient: "É Necessário Selecionar Um Cliente!",
			missingPhase: "É Necessário Selecionar A Fase Do Projeto",
			aleredySelected: "Já Selecionado!",
			clientNotFound: (id?: string) =>
				`Cliente ${id && "[" + id + "] "}não encontrado.`,
		},
		nav: {
			title: "Necessial",
			home: "Inicio",
			tags: "Tags",
			banner: "Banners",
			users: "Usuários",
			adminUsers: "Usuários Admin",
			products: "Produtos",
			sections: "Seções",
			logout: "Sair",
			configure: "Configurações",
			about: "Sobre",
			disconected: "Você está descontectado da internet",
			order: "Pedidos",
		},
		pageLogin: {
			pagetitle: "Necessial",
			title: "Acesso",
			buttonLogin: "Entrar",
			noAccess: "Ainda não possui acesso?",
			requestAccess: "Solicite Aqui",
			recoveryPassword: "Esqueceu a senha?",
			messageFooter: "Desenvolvido de ponta a ponta pela Startapp",
			needHelp: "Precisa de ajuda?",
		},
		budget: {
			budgetTableTitle: "Tabela de orçamentos",
			budgetTableHeader: [
				"Nome",
				"email",
				"Design",
				"Tipo de projeto",
				"Visualizar",
			],
		},
		budgetDetails: {
			title: "Detalhes do orçamento",
			messageEmail: "Envie uma mensagem para",
			textButton: "Enviar",
		},
		home: {
			welcome: "Bem vindo ao dashboard da Necessial",
		},
		buttons: {
			edit: "Editar",
			save: "Salvar",
			filter: "Filtrar",
			close: "Fechar",
			search: "Procurar",
		},
		filter: {
			title: "Filtro",
			dateStart: "Inicio",
			dateEnd: "Fim",
			addFilter: "Adicionar um filtro",
			statusFilterTitle: "Status",
			driversFilterTitle: "Filtrar Motoristas",
			nameFilterTitle: "Nome",
			emailFilterTitle: "Email",
			phoneFilterTitle: "Telefone",
			cpfFilterTitle: "CPF",
			textField: {
				apply: "Aplicar",
				add: "Adicionar",
				clear: "Limpar",
			},
			autosuggest: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			components: {
				filterText: {
					addText: "Adicionar",
				},
			},
			enumPicker: {
				apply: "Aplicar",
				add: "Adicionar",
			},
			badges: {
				statusActive: (value: boolean | null) =>
					`${value === false ? "Status: Inativo" : "Status: Ativo"}`,
				driverActive: (value: boolean | null) =>
					`${value === false ? "Motorista: Inativo" : "Motorista: Ativo"}`,
				name: (text: string) => `Nome: ${text}`,
				phone: (text: string) => `Telefone: ${text}`,
				cpf: (text: string) => `CPF: ${text}`,
				email: (text: string) => `Email: ${text}`,
			},
			filter: "Filtrar",
		},
		register: {
			title: "Seus Dados",
			documentNumber: "CPF",
			phone: "Telefone",
			permissions: "Permissões",
			payment: {
				title: "Pagamento",
				cardNumber: "Número do Cartão",
				holderName: "Nome do Titular",
				cvv: "Código de Segurança",
				expirationDate: "Data de Validade",
				plans: "Planos",
			},
			company: "Sua Empresa",
			create: "Criar Conta",
			back: "Voltar",
			next: "Continuar",
			finish: "Concluir",
			finishCredit: "Concluir Crédito",
			resumeCredit: "Retomar Crédito",
			creditCompletedSucess: "Crédito concluído com sucesso",
			creditLogSucess: "Aviso concluído com sucesso",
			creditResumeSucess: "Crédito retomado com sucesso",
			createCredit: "Criar crédito",
			registered: "Conta criada com sucesso",
			success: "Administrador criado com sucesso",
			successUser: "Usúario criado com sucesso",
			successTag: "Tag criada com sucesso",
			successBanner: "Banner cadastrado com sucesso",
			successCredit: "Crédito criado com sucesso",
			successSection: "Seção cadastrada com sucesso",
			successProduct: "Produto cadastrado com sucesso",
			edited: "Administrador editado com sucesso",
			editedUser: "Usúario editado com suscesso",
		},
		adminUsers: {
			title: "Administradores",
			editor: {
				getTitle: (onEditMode = true) =>
					onEditMode ? "Editar Administrador" : "Cadastrar Administrador",
			},
			edit: {
				title: (adminUserName?: string) =>
					"Edição do Administrador " + adminUserName,
				onClick: "Usuário foi editado",
				saveChanges: "Salvar",
				success: (adminUserName: string) =>
					`${adminUserName} modificado com sucesso!`,
			},
			table: {
				header: {
					name: "Nome",
					phone: "Telefone",
					email: "Email",
					editDelete: "Editar / Deletar",
				},
				tableIsEmpty: "Não foi possivel encontrar mais administradores",
			},
			deleteDialogAdmin: {
				title: "Deletar Administrador",
				message: "Tem certeza que deseja deletar este administrador?",
			},
			csvHeaders: [
				{ label: "Id", key: "id" },
				{ label: "Nome", key: "name" },
				{ label: "E-mail", key: "email" },
			],
		},
		enum: {
			Language: {
				ptBr: "Português",
			},
			OrderStatus: {
				pendingPayment: "Esperando pagamento",
				analysing: "Analisando",
				production: "produção",
				onWay: "A caminho",
				delivered: "Entregue",
				canceled: "Cancelado",
			},
			PaymentMethod: {
				creditCard: "creditCard",
				boleto: "boleto",
			},
		},
	},
});

API.setStrings(Strings);
export default Strings;
