// MARK: React
import React, { useCallback, useState } from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Libs
import moment from "moment";

// MARK: Store
import UserTableStore from "./store";
import { useGlobalStore } from "../../../context";

// MARK: Route
import { useHistory } from "react-router-dom";
import {
	Button,
	IconButton,
	TableCell,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import {
	Block,
	Edit,
	Delete,
	Add as AddIcon,
	Visibility as ViewIcon,
	LockOpen as LockOpenIcon,
} from "@material-ui/icons";

// MARK: Components
import PageTableContainer from "../../../../components/PageTableContainer";
import SimpleTable from "../../../../components/SimpleTable";
import Select from "../../../../components/Select";

const TableView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const tableStore = useLocalStore(() => new UserTableStore(uiStore, history));

	const pushToCreate = useCallback(() => history.push(`users/create`), [
		history,
	]);

	const pushToEdit = useCallback(
		(id: string) => history.push(`users/edit/${id}`),
		[history],
	);

	const pushToDetails = useCallback(
		(id: string) => history.push(`users/details/${id}`),
		[history],
	);

	const handleDeleteUser = useCallback(
		(id: string) => {
			uiStore.showDialog(
				{
					message: Strings.dialogs.delete.message,
					title: Strings.dialogs.delete.title,
				},
				() => tableStore.deleteUser(id),
			);
		},
		[uiStore, tableStore],
	);

	return (
		<PageTableContainer>
			<SimpleTable
				isHaveNexPage={tableStore.isHaveNextPage}
				loading={tableStore.loading}
				title={Strings.users.table.title}
				pagination={tableStore.pagination}
				headerToolbar={
					<Select
						width={200}
						title="Filtrar"
						fields={tableStore.getFilterFields}
						value={tableStore.filterParam}
						onChange={(e) =>
							tableStore.changeFilterParams(e.target.value as string)
						}
					/>
				}
				rows={tableStore.items}
				headers={[
					Strings.fields.id,
					Strings.fields.name,
					Strings.fields.email,
					Strings.fields.nick,
					Strings.users.table.TypeUser.title,
					Strings.fields.createdAt,
					Strings.fields.block,
					Strings.components.table.detailsAndblockAndEditAndDelete,
				]}
				renderItem={(item) => (
					<TableRow hover={true} key={item.id}>
						<TableCell align={"center"}>{item.id}</TableCell>
						<TableCell align={"center"}>{item.name}</TableCell>
						<TableCell align={"center"}>{item.email}</TableCell>
						<TableCell align={"center"}>{item.nick}</TableCell>
						<TableCell align={"center"}>
							{item.store
								? Strings.users.table.TypeUser.seller
								: Strings.users.table.TypeUser.customer}
						</TableCell>
						<TableCell align={"center"}>
							{moment(item.createdAt).format(Strings.moment.date)}
						</TableCell>
						<TableCell align={"center"}>
							{item.blockedAt
								? moment(item.blockedAt).format(Strings.moment.date)
								: Strings.users.table.userActivate}
						</TableCell>
						<TableCell align={"center"}>
							<Tooltip title="Visualizar">
								<IconButton onClick={() => pushToDetails(item.id)}>
									<ViewIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Editar">
								<IconButton onClick={() => pushToEdit(item.id)}>
									<Edit />
								</IconButton>
							</Tooltip>
							<Tooltip title={item.blockedAt ? "Desbloquear" : "Bloquear"}>
								<IconButton
									onClick={() =>
										item.blockedAt
											? tableStore.unLockUser(item.id)
											: tableStore.blockUser(item.id)
									}
								>
									{item.blockedAt ? <LockOpenIcon /> : <Block />}
								</IconButton>
							</Tooltip>
							<Tooltip title="Excluir">
								<IconButton onClick={() => handleDeleteUser(item.id)}>
									<Delete />
								</IconButton>
							</Tooltip>
						</TableCell>
					</TableRow>
				)}
				bottomToolbar={
					<Button startIcon={<AddIcon />} onClick={pushToCreate}>
						{Strings.actions.create}
					</Button>
				}
			/>
		</PageTableContainer>
	);
};

export default observer(TableView);
