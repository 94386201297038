import React from "react";
import "./style.scss";

// MARK: Mobx
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../modules/Strings";

// MARK: Components
import { DetailRow } from "../../../components/DetailRow";
import { PageContainer } from "../../../components/PageContainer";

// MARK: Material
import { Box } from "@material-ui/core";

const Home = observer(() => {

	return (
		<PageContainer>
			<Box className="welcomeContainer" display="flex" flexDirection="column">
				{Strings.home.welcome}
			</Box>
		</PageContainer>
	);
});

export default Home;
