import PaginatedListStore from "../../../../stores/PaginatedListStore";
import api from "../../../../modules/API";
import Strings from "../../../../modules/Strings";
import UIStore from "../../../../stores/UIStore";
import { action } from "mobx";
import AuthStore from "../../../../stores/AuthStore";
import { IRouterPusher } from "../../../../interfaces/IRouter";

export default class ProductTableStore extends PaginatedListStore<api.Product> {
	private authStore: AuthStore;
	private uiStore: UIStore;
	public totalProduct: number;

	constructor(uiStore: UIStore, router: IRouterPusher) {
		super(router);
		this.fetchPage(0);
		this.authStore = new AuthStore(uiStore);
		this.uiStore = uiStore;
		this.getNumberTotalOfProducts();
	}

	@action
	protected getDataItemsPerPage(page: number): Promise<api.Product[]> {
		return api.getProducts(page);
	}

	@action
	protected async getNumberTotalOfProducts() {
		const result = await api.getTotalNumberOfProducts();
		this.totalProduct = result;
	}

	@action
	public deleteProduct = async (id: string) => {
		try {
			await api.deleteProduct(id);
			window.location.reload();
			this.uiStore.showSnackbar(
				Strings.dialogs.operationHasBeenASuccess,
				"success",
			);
		} catch (error) {
			this.uiStore.showSnackbar(Strings.error.default);
		}
	};
}
