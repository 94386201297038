// MARK: React
import { action, observable } from "mobx";

// MARK: API
import API from "../../../../modules/API";

// MARK: Strings
import Strings from "../../../../modules/Strings";

// MARK: Store
import ModelStore from "../../../../stores/ModelStore";
import UIStore from "../../../../stores/UIStore";
import AuthStore from "../../../../stores/AuthStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";

interface IRouteReplacer {
	replace: (path: string) => void;
	go: (entrie: number) => void;
}

export default class Store extends ModelStore<API.User> {
	protected router: IRouteReplacer;
	public userImageService: FileStore;

	constructor(id: string, uiStore: UIStore, router: IRouteReplacer) {
		super(id, uiStore);
		this.router = router;
		this.userImageService = new FileStore(uiStore, "image");
	}

	public formController = new FormStore({
		email: "",
		name: "",
		nick: "",
		document: "",
		id: "",
		phone: "",
	});

	protected getModel(id: string) {
		return API.getUser(id);
	}

	protected afterModelFetch(model: API.User) {
		if (model.image) {
			this.userImageService = new FileStore(this.uiStore, "image", model.image);
		}

		this.formController = new FormStore({
			name: model.name || "",
			email: model.email || "",
			nick: model.nick || "",
			document: model.document || "",
			id: model.id || "",
			phone: model.phone || "",
		});
	}

	@action
	public editorUser = async (succesMessage: string) => {
		const data = this.formController.getValues();

		try {
			if (this.loading) {
				throw {
					type: API.ErrorType,
					message: Strings.error.stillLoading,
				};
			}
			const user = await API.editUser(this.id, {
				name: data.name,
				document: data.document,
				email: data.email,
				nick: data.nick,
				image: this.userImageService.getUncertainfiedImage(),
				phone: data.phone,
			});

			localStorage.setItem("user", JSON.stringify({ ...user }));
			this.router.replace("/dashboard/users");
			this.router.go(0);
			this.uiStore.showSnackbar(
				Strings.users.edit.success(user.name),
				"success",
			);
		} catch (e) {
			this.uiStore.showErrorSnackbar(e);
		} finally {
			this.loading = false;
		}
	};
}
