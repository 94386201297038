// MARK: React
import * as React from "react";

// MARK: MOBX
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Router
import { useParams, useHistory } from "react-router-dom";

// MARK: Resources
import Strings from "../../../../modules/Strings";

// MARK: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Styles
import { useStyles } from "./styles";

// MARK: Libs
import {
	Button,
	TextField,
	CircularProgress,
	Box,
	FormControl,
	Select,
	InputLabel,
} from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";
import SelectChips from "../../../../components/SelectChips";

interface IParams {
	id: string;
}

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();
	const sectionStrings = Strings.sections.create;

	const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.setCategory(event.target.value);
	};

	return (
		store.model && (
			<CardForm
				title={Strings.sections.editor.title}
				loading={store.loading}
				actionsFooter={
					(
						<Button
							disabled={store.loading}
							type="submit"
							variant="contained"
							color="default"
							onClick={store.editSection}
						>
							{store.loading ? (
								<CircularProgress />
							) : (
									Strings.tags.edit.saveChanges
								)}
						</Button>
					)
				}
			>
				<Box>
					<TextField
						className={classes.fields}
						{...store.formController.field("name")}
						label={Strings.fields.name}
					/>
					<TextField
						className={classes.fields}
						{...store.formController.field("text")}
						label={Strings.fields.text}
					/>
					<TextField
						className={classes.fields}
						variant="standard"
						type="text"
						{...store.formController.field("idStore")}
						label={sectionStrings.idStore}
					/>

					<FormControl className={classes.fields}>
						<InputLabel>{sectionStrings.categoriesField}</InputLabel>
						<Select
							native={true}
							value={store.selectCategory}
							onChange={handleCategory}
						>
							<option aria-label="None" value="" aria-selected={true} />
							{store.stringsOptions.categories.map((value, index) => {
								return (
									<option key={index} aria-selected={true} value={value}>
										{sectionStrings.categories[value]}
									</option>
								);
							})}
						</Select>
					</FormControl>

					{store.selectChipTag && (
						<FormControl className={classes.fields}>
							<SelectChips
								label={sectionStrings.tagsField}
								{...store.selectChipTag.toCase()}
								objectProp="name"
							/>
						</FormControl>
					)}
				</Box>
			</CardForm>
		)
	);
};

export default observer(EditorView);
