// MARK: MOBX
import { action, computed, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Errors } from "../../../../modules/Errors";

// MARK: Lib
import * as Yup from "yup";

// MARK: Stores
import UIStore from "../../../../stores/UIStore";
import FileStore from "../../../../stores/FileStore";
import FormStore from "../../../../stores/FormStore";

interface IPush {
	goBack: () => void;
}

export default class Store {
	private uiStore: UIStore;
	private history: IPush;
	public avatarController: FileStore;

	@observable public loading = false;
	@observable public error: API.ErrorType | null = null;

	public formController = new FormStore({
		email: "",
		name: "",
		password: "",
		nick: "",
		document: "",
		phone: "",
	});

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		this.avatarController = new FileStore(uiStore, "image");
	}

	@action
	public createUser = async () => {
		const data = this.formController.getValues();
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.default,
				};
			}

			this.loading = true;
			await API.createUser({
				name: data.name,
				document: data.document,
				email: data.email,
				password: data.password,
				nick: data.nick,
				phone: data.phone,
				image: this.avatarController.getUncertainfiedImage(),
			});

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.successUser, "success");
		} catch (e) {
			this.uiStore.showSnackbar(e.message);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get validations(): Yup.ObjectSchema<object | undefined, object> {
		return Yup.object().shape({
			name: Yup.string().required("O nome é obrigatório"),
			document: Yup.string().required("O CPF ou CNPJ é obrigatório"),
			nick: Yup.string().required("O apelido é obrigatório"),
			email: Yup.string()
				.email("Informe um email valido")
				.required("O email é obrigatório"),
			password: Yup.string().required("A senha é obrigatória"),
		});
	}
}
