/* tslint:disable */

// MARK: React
import React from "react";

// MARK: Mobx
import { useLocalStore, observer } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Store
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Styles
import useStyles from "./styles";

// MARK: Route
import { useHistory, useParams } from "react-router-dom";

// MARK: Libs
import {
	Button,
	CircularProgress,
	TextField,
	CardContent,
	FormControl,
	InputLabel,
	Select,
	Box,
	InputAdornment,
} from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";
import SelectChips from "../../../../components/SelectChips";
import FileCase from "../../../../components/FileCase";
import InputNumberCurrencyFormat from "../../../../components/InputNumberCurrencyFormat";

const EditorView: React.FC = () => {
	const { id } = useParams<{ id: string }>();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();
	const StringProduct = Strings.products;

	const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.setCategory(event.target.value);
	};

	return (
		<CardForm
			title={StringProduct.create.editProduct}
			loading={store.loading}
			actionsFooter={
				<Button
					type="submit"
					variant="contained"
					color="default"
					disabled={store.loading}
					onClick={store.editProduct}
				>
					{store.loading ? <CircularProgress /> : Strings.actions.save}
				</Button>
			}
		>
			<CardContent className={classes.container}>
				<Box className={classes.box}>
					<TextField
						{...store.formController.field("title")}
						label={Strings.fields.name}
						fullWidth
					/>
					<Box className={classes.fieldsBox}>

						<TextField
							{...store.formController.field("slug")}
							label={Strings.fields.slug}
						/>
						<TextField
							{...store.formController.field("productionTime")}
							label={Strings.fields.productionTime}
						/>
					</Box>
					<Box className={classes.fieldsBox}>
						<TextField
							{...store.formController.field("height")}
							label={Strings.fields.height}
							helperText={StringProduct.helperText.height}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">cm</InputAdornment>
								),
							}}
						/>
						<TextField
							{...store.formController.field("lenght")}
							label={Strings.fields.lenght}
							helperText={StringProduct.helperText.lenght}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">cm</InputAdornment>
								),
							}}
						/>
						<Box marginTop="20px">
							<InputNumberCurrencyFormat
								{...store.formController.field("price")}
								name="price"
								placeholder="Preço"
								customInput={TextField}
							/>
						</Box>
					</Box>
					<Box className={classes.fieldsBox}>
						<TextField
							{...store.formController.field("width")}
							helperText={StringProduct.helperText.width}
							label={Strings.fields.width}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">cm</InputAdornment>
								),
							}}
						/>
						<TextField
							{...store.formController.field("weight")}
							label={Strings.fields.weight}
							InputProps={{
								endAdornment: <InputAdornment position="end">g</InputAdornment>,
							}}
						/>
						<TextField
							{...store.formController.field("diameter")}
							label={Strings.fields.diameter}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">cm</InputAdornment>
								),
							}}
						/>
						<TextField
							{...store.formController.field("minAmount")}
							label={Strings.fields.minAmount}
							helperText={StringProduct.helperText.minAmount}
						/>

						<FormControl className={classes.fieldSelectCat}>
							<InputLabel>
								{store.selectCategory
									? null
									: StringProduct.create.categoriesField}
							</InputLabel>
							<Select
								native={true}
								value={store.selectCategory}
								onChange={handleCategory}
							>
								<option aria-label="None" value="" aria-selected={true} />
								{store.stringsOptions.categories.map((value, index) => {
									return (
										<option key={index} aria-selected={true} value={value}>
											{StringProduct.create.categories[value]}
										</option>
									);
								})}
							</Select>
						</FormControl>
					</Box>
				</Box>
				<Box className={classes.boxBigFields}>
					<TextField
						{...store.formController.field("description")}
						label={Strings.fields.description}
						multiline={true}
						rowsMax={8}
						helperText={StringProduct.helperText.description}
						error={store.descriptionError}
						className={classes.descriptionField}
					/>
				</Box>
				<Box className={classes.boxBigFields}>
					{store.selectChipTag && (
						<FormControl className={classes.fieldTag}>
							<SelectChips
								label="Tags"
								{...store.selectChipTag.toCase()}
								objectProp={"name"}
							/>
						</FormControl>
					)}
				</Box>
				<Box className={classes.fileCaseField}>
					<FileCase {...store.imageService.toCase(true)} />
				</Box>
			</CardContent>
		</CardForm>
	);
};

export default observer(EditorView);
