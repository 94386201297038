// MARK: React
import React from "react";

// MARK: router
import { useHistory } from "react-router";

// MARK: styles
import useStyles from "./styles";

// MARK: MOBX
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Resources
import Strings from "../../../../modules/Strings";

// MARK: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Libs
import {
	Button,
	CardContent,
	CircularProgress,
	TextField,
} from "@material-ui/core";

// MARK: Components
import FileCase from "../../../../components/FileCase";
import CardForm from "../../../../components/CardForm";

const CreateView: React.FC = () => {
	const classes = useStyles();
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));

	return (
		<CardForm
			title={Strings.tags.create.title}
			actionsFooter={(
				<Button
					disabled={store.loading}
					type="submit"
					variant="contained"
					color="default"
					onClick={store.createTag}
				>
					{store.loading ? (
						<CircularProgress />
					) : (
							Strings.tags.create.createButton
						)}
				</Button>
			)}
		>
			<CardContent className={classes.fields}>
				<TextField
					variant="standard"
					type="text"
					{...store.formController.field("name")}
					label={Strings.fields.name}
				/>
			</CardContent>
		</CardForm>
	);
};

export default observer(CreateView);
