import React from "react";
import { observer } from "mobx-react-lite";

import { Switch, Route } from "react-router-dom";

// MARK: Components
import TableView from "./TableView";
import DetailsView from "./DetailsView";

interface IProps {}

const Orders: React.FC<IProps> = () => {
	return (
		<Switch>
			<Route
				exact={true}
				path="/dashboard/orders/details/:id"
				component={DetailsView}
			/>
			<Route exact={true} path="/dashboard/orders" component={TableView} />
		</Switch>
	);
};

export default observer(Orders);
