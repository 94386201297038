// MARK: React
import { observable, action } from "mobx";

// MARK: Modules
import Strings from "../../modules/Strings";

// MARK: Stores
import UIStore from "../../stores/UIStore";

export default class SelectChipStore<itemType> {
	@observable public selectOptions: itemType[];
	@observable public chosenOptions: itemType[] = [];
	protected uiStore: UIStore;

	constructor(options: itemType[], uiStore: UIStore, oldClients?: itemType[]) {
		this.selectOptions = options;
		this.uiStore = uiStore;
		oldClients ? (this.chosenOptions = oldClients) : (this.chosenOptions = []);
	}

	@action
	public setOption = (value: itemType) => {
		if (!this.chosenOptions.includes(value) && value) {
			this.chosenOptions.push(value);
		} else {
			this.uiStore.showSnackbar(Strings.error.aleredySelected, "info");
		}
	};

	@action
	public clear = async (index: number) => {
		this.chosenOptions.splice(index, 1);
	};

	@action
	public toCase = () => {
		return {
			selectOptions: this.selectOptions,
			chosenOptions: this.chosenOptions,
			addOption: this.setOption,
			removeClient: this.clear,
		};
	};
}
