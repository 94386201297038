// MARK: React
import React from "react";

// MARK: router
import { useHistory } from "react-router";

// MARK: Styles
import { useStyles } from "./styles";

// MARK: MOBX
import { observer, useLocalStore } from "mobx-react-lite";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// MARK: Stores
import { useGlobalStore } from "../../../context";
import Store from "./store";

// MARK: Libs
import {
	Box,
	Button,
	CircularProgress,
	TextField,
	FormControl,
	InputLabel,
	Select,
} from "@material-ui/core";

// MARK: Components
import CardForm from "../../../../components/CardForm";
import SelectChips from "../../../../components/SelectChips";

const CreateView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const history = useHistory();
	const store = useLocalStore(() => new Store(uiStore, history));
	const classes = useStyles();
	const sectionStrings = Strings.sections.create;
	const handleCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
		store.setCategory(event.target.value);
	};

	return (
		<CardForm
			title={sectionStrings.title}
			actionsFooter={
				(
					<Button
						disabled={store.loading}
						type="submit"
						variant="contained"
						color="default"
						onClick={store.createSection}
					>
						{store.loading ? (
							<CircularProgress />
						) : (
								Strings.tags.create.createButton
							)}
					</Button>
				)
			}
		>
			<Box>
				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("name")}
					label={sectionStrings.name}
				/>
				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("text")}
					label={sectionStrings.text}
				/>

				<TextField
					className={classes.fields}
					variant="standard"
					type="text"
					{...store.formController.field("idStore")}
					label={sectionStrings.idStore}
				/>

				<FormControl className={classes.fields}>
					<InputLabel>{sectionStrings.categoriesField}</InputLabel>
					<Select
						native={true}
						value={store.selectCategory}
						onChange={handleCategory}
					>
						<option aria-label="None" value="" aria-selected={true} />
						{store.stringsOptions.categories.map((value, index) => {
							return (
								<option key={index} aria-selected={true} value={value}>
									{sectionStrings.categories[value]}
								</option>
							);
						})}
					</Select>
				</FormControl>
				{store.selectChipTag && (
					<FormControl className={classes.fields}>
						<SelectChips
							label="Tags"
							{...store.selectChipTag.toCase()}
							objectProp={"name"}
						/>
					</FormControl>
				)}
			</Box>
		</CardForm>
	);
};

export default observer(CreateView);
