import React from "react";

// MARK: Modules
import Strings from "../../../../modules/Strings";

// Mark: Component
import CardForm from "../../../../components/CardForm";
import { PageContainer } from "../../../../components/PageContainer";
import { CardContent } from "@material-ui/core";
import FileCase from "../../../../components/FileCase";

// Mark: Lib
import { Button, TextField } from "@material-ui/core";

// Mark: Router
import { useHistory, useParams } from "react-router";

// Mark: Mobx
import { observer, useLocalStore } from "mobx-react-lite";
import { useGlobalStore } from "../../../context";

// Mark: Store
import Store from "./store";
import useStyles from "./styles";

const EditorView: React.FC = () => {
	const { uiStore } = useGlobalStore();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const store = useLocalStore(() => new Store(id, uiStore, history));
	const classes = useStyles();

	return (
		<PageContainer>
			<CardForm
				title={Strings.tags.edit.title(store.model?.name)}
				loading={store.loading}
				actionsFooter={(
					<Button
						variant="contained"
						onClick={() => {
							store.editorTag(Strings.tags.edit.onClick);
						}}
					>
						{Strings.tags.edit.saveChanges}
					</Button>
				)}
			>
				<CardContent className={classes.fields}>
					<TextField
						variant="standard"
						type="text"
						{...store.formController.field("name")}
						label={Strings.fields.name}
					/>
				</CardContent>
			</CardForm>
		</PageContainer>
	);
};

export default observer(EditorView);
