// MARK: MOBX
import { action, computed, observable } from "mobx";

// MARK: Resources
import Strings from "../../../../modules/Strings";
import API from "../../../../modules/API";
import { Formatting } from "../../../../modules/Formatting";

// MARK: Stores
import UIStore from "../../../../stores/UIStore";
import FormStore from "../../../../stores/FormStore";
import FileStore from "../../../../stores/FileStore";
import SelectChipStore from "../../../../components/SelectChips/selectChipStore";

interface IPush {
	goBack: () => void;
}

export default class Store {
	@observable public imageService: FileStore;

	@observable public loading = false;

	@observable public selectCategory: API.Category;

	@observable public descriptionError: boolean = false;

	@observable public selectChipTag: SelectChipStore<API.Tag>;
	private uiStore: UIStore;
	private history: IPush;

	constructor(uiStore: UIStore, history: IPush) {
		this.uiStore = uiStore;
		this.history = history;
		this.getTags();
		this.imageService = new FileStore(uiStore, "image");
	}

	public setCategory = (value: string) => {
		this.selectCategory = API.Category[value];
	};

	@action
	private getTags = async () => {
		this.selectChipTag = new SelectChipStore<API.Tag>(
			await API.getTags(0),
			this.uiStore,
		);
	};

	public formController = new FormStore({
		title: "",
		description: "",
		slug: "",
		productionTime: "",
		width: "",
		height: "",
		lenght: "",
		weight: "",
		idStore: "",
		diameter: "",
		minAmount: "",
		price: "",
	});

	@action
	public createProduct = async () => {
		const fields = this.formController.getValues();
		const imageServiceValue = this.imageService.getUncertainfiedMultipleFiles();
		try {
			if (this.loading) {
				throw {
					type: API.ErrorType.Fatal,
					message: Strings.error.mustWaitWhileStillLoading,
				};
			}

			if (fields.description.length < 30) {
				this.descriptionError = true;
			} else {
				this.descriptionError = false;
			}

			this.loading = true;
			await API.createProduct(
				{
					title: fields.title,
					slug: fields.slug,
					description: fields.description,
					productionTime: fields.productionTime,
					length: parseInt(fields.lenght),
					weight: parseInt(fields.weight),
					width: parseInt(fields.width),
					height: parseInt(fields.height),
					price: Formatting.toCents(fields.price),
					category: this.selectCategory,
					tags: this.selectChipTag.chosenOptions,
					diameter: parseInt(fields.diameter),
					minAmount: parseInt(fields.minAmount),
					images: imageServiceValue,
					freeShipping: false,
				},
				fields.idStore,
			);

			this.history.goBack();
			this.uiStore.showSnackbar(Strings.register.successProduct, "success");
		} catch (err) {
			this.uiStore.showErrorSnackbar(err);
		} finally {
			this.loading = false;
		}
	};

	@computed
	public get stringsOptions() {
		return {
			categories: [
				API.Category.clothes,
				API.Category.accessories,
				API.Category.sport,
				API.Category.toys,
				API.Category.party,
				API.Category.educational,
				API.Category.health,
				API.Category.customized,
			],
		};
	}
}
